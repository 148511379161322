import { Client, ClientRole } from '../redux/reducers/clients'
import { fetchRequest } from '../services/http.service'

export const GET_CLIENTS_URL = '/client/'
export const GET_CLIENT_URL = '/client/new'
export const POST_CLIENT_URL = '/client/create'
export const UPDATE_CLIENT_URL = '/client/update'
export const DEACTIVATE_CLIENT_URL = `/client/deactivate`
export const GET_CLIENT_BY_PARAM_URL = 'client/?instrument='
export const GET_CLIENT_ROLES_LIST_URL = '/roles'

export const getClientsRequest = () => fetchRequest.get<Client[]>(GET_CLIENTS_URL)

export const getClientRequest = (clientId: number) =>
  fetchRequest.get<Client>(GET_CLIENT_URL + `/${clientId}`, clientId)

export const postClientRequest = (client: Client) => fetchRequest.post<Client>(POST_CLIENT_URL, client)

export const updateClientRequest = (client: Client) => fetchRequest.put<Client>(UPDATE_CLIENT_URL, client)

export const deactivateClientRequest = (client: Client) =>
  fetchRequest.put<Client>(DEACTIVATE_CLIENT_URL + `/${client.clientId}`, client)

export const getClientsByParamRequest = (param: any) => fetchRequest.get<Client[]>(GET_CLIENT_BY_PARAM_URL + `${param}`)

export const getClientRolesRequest = () => fetchRequest.get<ClientRole[]>(GET_CLIENT_ROLES_LIST_URL)
