import makeStyles from '@mui/styles/makeStyles'
import React, { ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'

import { LeftNavBarButton } from '../layouts/BasicLayout'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonMargin: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}))

interface Props {
  leftNavBarButtons?: LeftNavBarButton[]
}

export const LeftNavBar: React.FC<Props> = ({ leftNavBarButtons = [] }) => {
  const classes = useStyles()
  const history = useHistory()

  const onNavButtonClick = (path: string) => () => {
    history.push(path)
  }

  return (
    <div className={classes.root}>
      {leftNavBarButtons?.map(item => {
        return (
          <div title={item.tooltip}>
            <IconButton className={classes.buttonMargin} onClick={onNavButtonClick(item.link)}>
              {item.icon}
            </IconButton>
          </div>
        )
      })}

      {/*  <ColoredIconButton className={classes.buttonMargin}
                               icon={<SettingsIcon onClick={onNavButtonClick(Pages.OrderList)}/>}/>
                <ColoredIconButton className={classes.buttonMargin} icon={<PhoneAndroidIcon />} />
      <ColoredIconButton className={classes.buttonMargin} icon={<SaveAltIcon />} />
      <ColoredIconButton className={classes.buttonMargin} icon={<MoreVertIcon />} />*/}
    </div>
  )
}
