import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect } from 'react'
import { useClientApi } from '../../hooks/useClientApi'
import { useClientFilters } from '../../hooks/useClientFilters'
import { ClientTile } from './ClientTile'
import { useSelector } from '../../redux/hooks/useSelector'
import { LoadingIndicator } from '../common/LoadingIndicator'

const useStyles = makeStyles(theme => ({
  clientTable: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'left',
    gap: theme.spacing(2),
  },
}))

export const ClientDisplay = () => {
  const classes = useStyles()
  const clients = useClientFilters()
  const { getClients } = useClientApi()
  const loading = useSelector(state => state.clients.loading)

  useEffect(() => {
    getClients()
  }, [])

  useEffect(() => {}, [clients])

  return (
    <div className={classes.clientTable}>
      {loading && <LoadingIndicator />}

      {!loading && (
        <div className={classes.clientTable}>
          {clients.map((client, index) => (
            <ClientTile client={client} key={index} />
          ))}
        </div>
      )}
    </div>
  )
}
