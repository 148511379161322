import { useTheme } from '@mui/styles'
import { useState, useEffect } from 'react'

interface Breakpoints {
  [key: string]: number
}

function useMobileWidth() {
  const theme = useTheme()
  const [isMdOrLarger, setIsMdOrLarger] = useState<boolean>(getCurrentBreakpoint(theme.breakpoints.values) !== null)

  function getCurrentBreakpoint(breakpoints: Breakpoints) {
    const windowWidth = window.innerWidth
    const breakpointsArray = Object.keys(breakpoints).reverse()
    for (let breakpoint of breakpointsArray) {
      if (
        windowWidth >= breakpoints[breakpoint] &&
        (breakpoint === 'md' || breakpoint === 'lg' || breakpoint === 'xl')
      ) {
        return breakpoint
      }
    }
    return null
  }

  useEffect(() => {
    function handleResize() {
      setIsMdOrLarger(getCurrentBreakpoint(theme.breakpoints.values) !== null)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return !isMdOrLarger
}

export default useMobileWidth
