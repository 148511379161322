import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getPortfolioRequest } from '../api/portfolioApi'
import { getPortfolioAction } from '../redux/actions/portfolioActions'

export const usePortfolioApi = () => {
  const dispatch = useDispatch()

  const getPortfolio = useCallback(async (id: Number | null) => {
    await getPortfolioRequest(id)
      .then(response => {
        dispatch(getPortfolioAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getPortfolio,
  }
}
