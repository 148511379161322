import { ReduxAction } from '../../types/common'
import { SET_ERRORS, GET_ROLES } from '../actionTypes'

export interface Role {
  name: string | null
}

interface RoleState {
  roles: Role[]
}

const INITIAL_STATE = {
  roles: [],
}

export const roles = (state: RoleState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return state
  }
}
