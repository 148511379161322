export const ORDER = 'ORDER'
export const INSTRUMENT_NAME = 'INSTRUMENT_NAME'
export const MARKET = 'MARKET'
export const CURRENCY = 'CURRENCY'
export const TICKER = 'TICKER'
export const RISK_VALUE = 'RISK_VALUE'
export const HORIZONT_VALUE = 'HORIZONT_VALUE'
export const CATEGORY = 'CATEGORY'
export const EXPERIENCE = 'EXPERIENCE'
export const PREFERENCE = 'PREFERENCE'
export const LOSS_ABILITY = 'LOSS_ABILITY'
export const PORTFOLIO_OVERVIEW = 'PORTFOLIO_OVERVIEW'
export const NAME = 'NAME'
export const ISIN = 'ISIN'
export const PRICE = 'PRICE'
export const MOVEMENT = 'MOVEMENT'
export const DATA_FROM = 'DATA_FROM'
export const ORDER_STATE = 'ORDER_STATE'
export const ORDER_DIRECTION = 'ORDER_DIRECTION'
export const ORDER_AMOUNT = 'ORDER_AMOUNT'
export const ORDER_QUANTITY = 'ORDER_QUANTITY'
export const HORIZONTE = 'HORIZONTE'
export const RISK = 'RISK'
export const REQUIERED = 'REQUIERED'
