import { makeStyles } from '@mui/styles'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { IconButton } from '@mui/material'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Security } from '../../redux/reducers/securities'
import { Label } from '../../redux/reducers/label'
import { Position } from '../../redux/reducers/portfolio'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import LabelIcon from '@mui/icons-material/Label'

import MultiColorLabel from '../../assets/label2.svg'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import { CHOICE_LABEL_DIALOG_ID, ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { useHistory } from 'react-router-dom'
import { Pages } from '../../router/router'
import { newThemeObject } from '../../newTheme'
import { MultilineChart } from '@mui/icons-material'
import { TransparentButton } from '../common/TransparentButton'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'right',
  },
  button: {
    backgroundColor: newThemeObject.palette.main.blackAlpha,
  },
  multiLabel: {
    height: 12,
    alignSelf: 'center',
  },
  multiLabelButton: {
    height: '2rem',
    width: '2rem',
    minWidth: '1rem',
    borderRadius: '50%',
    padding: 0,
  },
}))

interface OrderButtonProps {
  securities: Security[]
  labels: Label[]
  position: Position
  setLabelSecurity: (labelSecurity: any) => void
  isValidSecurity: (securityId: number) => boolean
  clientId: string
}

const OrderButtons: React.FC<OrderButtonProps> = ({
  securities,
  labels,
  position,
  setLabelSecurity,
  isValidSecurity,
  clientId,
}) => {
  const { getSecurity } = useInstrumentApi()
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  const getColors = (securityId: number) => {
    let tmp = []
    if (labels) {
      for (let i = 0; i < labels.length; i++) {
        if (labels[i].securities) {
          for (let j = 0; j < labels[i].securities.length; j++) {
            if (labels[i].securities[j].securityId === securityId) {
              tmp.push(labels[i].color)
              break
            }
          }
        }
      }
    }
    return tmp
  }

  return (
    <div onClick={handleClick} className={classes.wrapper}>
      <IconButton
        className={classes.button}
        size="small"
        onClick={() => {
          if (isValidSecurity(position.instrument.instrumentId)) {
            getSecurity(position.instrument.instrumentId)
            dispatch(openDialog(ORDER_INPUT_DIALOG_ID, { direction: 'OD_SELL' }))
          }
        }}
      >
        <RemoveRoundedIcon />
      </IconButton>
      <IconButton
        className={classes.button}
        size="small"
        onClick={() => {
          if (isValidSecurity(position.instrument.instrumentId)) {
            getSecurity(position.instrument.instrumentId)
            dispatch(openDialog(ORDER_INPUT_DIALOG_ID, { direction: 'OD_BUY' }))
          }
        }}
      >
        <AddRoundedIcon />
      </IconButton>
      <IconButton
        className={classes.button}
        size="small"
        onClick={() => {
          history.push(`${Pages.ClientInstrumentOrderList}/${clientId}/${position.instrument.name.split('/')[0]}`)
        }}
      >
        <SearchRoundedIcon />
      </IconButton>
      {getColors(position.instrument.instrumentId) && getColors(position.instrument.instrumentId).length === 0 && (
        <IconButton
          size="small"
          onClick={() => {
            setLabelSecurity({
              securityId: position.instrument.instrumentId,
              securityName: position.instrument.name,
            })
            dispatch(openDialog(CHOICE_LABEL_DIALOG_ID))
          }}
        >
          <LabelOutlinedIcon />
        </IconButton>
      )}
      {getColors(position.instrument.instrumentId) && getColors(position.instrument.instrumentId).length === 1 && (
        <IconButton
          size="small"
          onClick={() => {
            setLabelSecurity({
              securityId: position.instrument.instrumentId,
              securityName: position.instrument.name,
            })
            dispatch(openDialog(CHOICE_LABEL_DIALOG_ID))
          }}
        >
          <LabelIcon style={{ color: getColors(position.instrument.instrumentId)[0] }} />
        </IconButton>
      )}
      {getColors(position.instrument.instrumentId) && getColors(position.instrument.instrumentId).length > 1 && (
        <TransparentButton
          className={classes.multiLabelButton}
          onClick={() => {
            setLabelSecurity({
              securityId: position.instrument.instrumentId,
              securityName: position.instrument.name,
            })
            dispatch(openDialog(CHOICE_LABEL_DIALOG_ID))
          }}
        >
          <img src={MultiColorLabel} alt="Multi tag" className={classes.multiLabel} />
        </TransparentButton>
      )}
    </div>
  )
}

export default OrderButtons
