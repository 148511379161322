import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { Client } from '../../redux/reducers/clients'
import { useClientFileApi } from '../../hooks/useClientFileApi'
import { useSelector } from '../../redux/hooks/useSelector'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import { newThemeObject } from '../../newTheme'
import classNames from 'classnames'
import { DropZone } from '../common/DropZone'
import { fileToBase64 } from '../../utils/fileToBase64'
import { ClientFile } from '../../redux/reducers/clientFile'
import { REQUEST_POST_CLIENT_FILE } from '../../redux/actionTypes'
import { LoadingIndicator } from '../common/LoadingIndicator'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import SaveIcon from '@mui/icons-material/Save';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Tooltip } from '@mui/material'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    // minHeight: 650,
  },
  tableHead: {
    ...newThemeObject.table.tableHead,
  },
  tableHeadChildrenText: {
    ...newThemeObject.table.tableHeadChildrenText,
  },
  tableHeadChildren: {
    ...newThemeObject.table.tableHeadChildren,
  },
  tableHeadChildrenFirst: {
    ...newThemeObject.table.tableHeadChildrenFirst,
  },
  tableHeadChildrenLast: {
    ...newThemeObject.table.tableHeadChildrenLast,
  },
  tableContent: {
    fontSize: 30,
    padding: '0px 16px',
  },
  tableContainer: {
    ...newThemeObject.table.tableContainer,
    marginTop: '20px',
    borderRadius: newThemeObject.roundness.basic,
  },
  barContainer: {
    marginTop: '20px',
    ...newThemeObject.table.tableContainer,
    color: newThemeObject.palette.main.primary,
    backgroundColor: newThemeObject.palette.highlight.bg,
  },
  tableContentText: {
    ...newThemeObject.table.tableContentText,
  },
  tableBody: {
    ...newThemeObject.table.tableBody,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    borderRadius: newThemeObject.roundness.basic,
  },
  border: {
    border: `2px dashed ${theme.palette.grey[500]}`,
  },

  dropContainer: {
    // height: '50%',
    width: '100%',
  },
}))

interface Props {
  client: Client
  setReloadClient: any
}

export const DocumentTable: React.FC<Props> = ({ client, setReloadClient }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { getClientFiles, saveClientFiles } = useClientFileApi();
  const clientFiles = useSelector(state => state.clientFiles)
  const [filesToSend, setFilesToSend] = useState<ClientFile[]>([])
  const dispatch = useDispatch();
  const [clientId, setClientId] = useState<number>(0)

  useEffect(() => {
    if (client && clientId!==client.clientId) {
      setClientId(client.clientId)
      getClientFiles(client.clientId)
    }
  }, [client])

  useEffect(() => {
    if (clientFiles && !clientFiles.isClientFilesSaving && !clientFiles.isClientFilesGeting) {
      setFilesToSend(clientFiles.clientFiles.map((item: ClientFile) => {
        return {
          clientFileId: item.clientFileId,
          clientId: item.clientId,
          fileContent: item.fileContentPrefix+','+item.fileContent,
          fileContentPrefix: item.fileContentPrefix,
          fileName: item.fileName,
          date: item.date,
          saved: item.saved
        }
      }))
    }
  }, [clientFiles])

  const onFileDrop = (acceptedFiles: File[]) => {
    fileToBase64(acceptedFiles[0]).then(result => 
      setFilesToSend([...filesToSend, {clientFileId: -1, fileContent: result, fileContentPrefix: result.substring(0, result.indexOf(',')), fileName: acceptedFiles[0].name, clientId: client.clientId, date: new Date(), saved:false},])
    );
  }

  const saveFiles = () => {
    dispatch({type: REQUEST_POST_CLIENT_FILE})
    saveClientFiles(filesToSend);
  }

  const handleDownLoad = (item: ClientFile) => {
    const linkSource = item.fileContent;
    const downloadLink = document.createElement('a');
    const fileName = item.fileName;
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  return (
    <>
      <div className={classes.dropContainer}>
        <DropZone onFileDrop={onFileDrop} defaultText={t('FILE_DIALOG_MESSAGE')} className={classes.border} />
      </div>
      <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
        <span className={classes.topBar}>
          <p>{t('DOCUMENTS')}</p>
        </span>

        {clientFiles.isClientFilesGeting &&
            <LoadingIndicator />
        }
        {!clientFiles.isClientFilesGeting &&
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenFirst])}>
                <p className={classes.tableHeadChildrenText}>{t('NAME')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('DATE')}</p>
              </TableCell>
              <TableCell className={classNames([classes.tableHeadChildren])}>
                <p className={classes.tableHeadChildrenText}>{t('FILE')}</p>
              </TableCell>
              <TableCell className={classNames([classes.tableHeadChildren])}>
                <p className={classes.tableHeadChildrenText}>{t('STATE')}</p>
              </TableCell>
              <TableCell className={classNames([classes.tableHeadChildren])}>
                <p className={classes.tableHeadChildrenText}>{t('DOWNLOAD_FILE')}</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {filesToSend.map((item: ClientFile) => (
              <TableRow className={classes.tableContent} key={item.fileName}>
                <TableCell className={classes.tableContent}>
                  <p className={classes.tableContentText}>{item.fileName}</p>
                </TableCell>
                <TableCell className={classes.tableContent}>
                  <p className={classes.tableContentText}>{format(new Date(item.date), 'dd/MM/yyyy')}</p>
                </TableCell>
                <TableCell className={classes.tableContent}>
                  {item.fileContentPrefix.startsWith('data:image') && 
                    <Tooltip title={t('FILE_PREVIEW')}>
                      <img 
                        src={item.fileContent}
                        style={{ width: '64px', height: '64px'}}
                      />
                    </Tooltip>
                  }
                  {!item.fileContentPrefix.startsWith('data:image') && 
                    <Tooltip title={t('FILE_PREVIEW_NOT')}>
                      <AttachFileIcon />
                    </Tooltip>
                  }
                </TableCell>
                <TableCell>
                  {item.saved?t('SAVED'):t('UNSAVED')}
                </TableCell>
                <TableCell>
                  <DownloadForOfflineIcon onClick={()=>{handleDownLoad(item)}} style={{ cursor: 'pointer' }}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        }
      </TableContainer>
      <Button 
        onClick={()=>{saveFiles();}}
        disabled={clientFiles.isClientFilesSaving}
      >
        {t('SAVE')}
      </Button>

    </>
  )
}

