import { ReduxAction } from '../../types/common'
import { DELETE_NOTE, GET_NOTES, POST_NOTE, PUT_NOTE } from '../actionTypes'

export interface Note {
  noteId: number
  content: string
  date: Date
  portfolioId: number
}

interface NoteState {
  notes: Note[]
}

const INITIAL_STATE: NoteState = {
  notes: [],
}

export const noteState = (state: NoteState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_NOTES:
      return {
        ...state,
        notes: action.payload as Note[],
      }
    case POST_NOTE:
      let tmp = []
      tmp.push(action.payload as Note)
      tmp.push(...state.notes)
      state.notes = tmp;
      return {
        ...state,
      }
    case PUT_NOTE:
      let tmpPut = [...state.notes]
      for (let i = 0; i < tmpPut.length; i++) {
        if (tmpPut[i].noteId === action.payload.noteId) {
          tmpPut[i] = action.payload
        }
        state.notes = tmpPut;
      }
      return {
        ...state,
      }
    case DELETE_NOTE:
      let tmpDelete = [...state.notes]
      for (let i = 0; i < tmpDelete.length; i++) {
        if (tmpDelete[i].noteId === action.payload) {
          tmpDelete.splice(i, 1);
        }
        state.notes = tmpDelete;
      }
      return {
        ...state,
      }
    default:
      return state
  }
}
