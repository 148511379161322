import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from '../../redux/hooks/useSelector'
import React, { useEffect, useRef } from 'react'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import VirtualizedTableBody from './VirtualizedTableBody'

const useStyles = makeStyles(theme => ({
  tableBody: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
}))

interface VirtualRowProps {
  style: React.CSSProperties | undefined
  index: number
}

const LOADING = 1
const LOADED = 2

let itemStatusMap: Record<string, number> = {}

const isItemLoaded = (index: number) => !!itemStatusMap[index]
const loadMoreItems = (startIndex: number, stopIndex: number) => {
  for (let index = startIndex; index <= stopIndex; index++) {
    itemStatusMap[index] = LOADING
  }

  return new Promise<void>(resolve => {
    for (let index = startIndex; index <= stopIndex; index++) {
      itemStatusMap[index] = LOADED
    }
    resolve()
  })
}

// const VirtualizedTableBody = ({
//     list,
//     setIsUpdate
// }: Props) => {

interface Props {
  setIsUpdate: (params: any) => any
}

export const InstrumentTableBody = ({ setIsUpdate }: Props) => {
  const classes = useStyles()
  const securities = useSelector(state => state.securities.securities)
  const { getSecurities } = useInstrumentApi()
  const infiniteLoaderRef = useRef(null)
  const hasMountedRef = useRef(false)
  useEffect(() => {
    getSecurities()
  }, [])

  if (securities && securities.length > 0) {
    return <VirtualizedTableBody setIsUpdate={setIsUpdate} list={securities} />
  }

  return null
}
