import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Button } from '@mui/material'

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '1.5rem',
    borderRadius: '0.75rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '0.65rem',
    padding: '0 2rem',
  },
}))

interface DefaultButtonProps {
  onClick?: () => void
  size?: 'small' | 'medium' | undefined
  className?: string
  disabled?: boolean
  type?: string
  startIcon?: React.ReactNode
}

export const DefaultButton: React.FC<DefaultButtonProps> = ({
  onClick,
  size,
  className,
  disabled,
  children,
  startIcon,
}) => {
  const classes = useStyles()

  return (
    <Button
      className={classNames(classes.wrapper, className)}
      onClick={onClick}
      startIcon={startIcon}
      size={size}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}
