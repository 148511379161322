import { CodeList } from '../types/common'

export const codeListToArrowSelect = (code: string, codeLists?: CodeList[]) => {
  const codeList = codeLists?.find(cl => cl.code === code) || { items: [] }
  return codeList?.items.map((item: any) => ({ label: item.code, value: item.code }))
}

export const codeListCodeItems = (code: string, codeLists?: CodeList[]) => {
  const codeList = codeLists?.find(cl => cl.code === code) || { items: [] }
  return codeList?.items.map((item: any) => item.code)
}

export const codeListCodeAndDescriptionItems = (code: string, codeLists?: CodeList[]) => {
  const codeList = codeLists?.find(cl => cl.code === code) || { items: [] }
  return codeList?.items.map((item: any) => ({ code: item.code, description: item.description }))
}
