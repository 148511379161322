import {
  ADD_ORDER,
  GET_FILTERED_SECURITIES,
  GET_ORDER,
  GET_ORDER_TABLE_ROWS,
  GET_ORDERS,
  GET_ORDERS_BY_STATE_COUNT,
  GET_POSITIONS,
  GET_SECURITIES,
  GET_SECURITY,
  GET_SECURITY_PRICES,
  UPDATE_ORDER,
} from '../actionTypes'
import { Order, OrdersByStateCount, OrderTableRow } from '../reducers/orders'
import { Security, SecurityPrices } from '../reducers/securities'

//TODO separate/rename orders from instruments

export const getSecuritiesAction = (securities: Security[]) => ({ type: GET_SECURITIES, payload: securities })
export const getSecurityPricesAction = (securityPrices: SecurityPrices) => ({
  type: GET_SECURITY_PRICES,
  payload: securityPrices,
})

export const getSecurityAction = (security: Security | undefined) => ({ type: GET_SECURITY, payload: security })
export const getFilteredSecuritiesAction = (securities: Security[]) => ({
  type: GET_FILTERED_SECURITIES,
  payload: securities,
})

export const getPositionsAction = (positions: any, positionId: number) => ({
  type: GET_POSITIONS,
  payload: { positionId, positions },
})

export const getOrderAction = (order: Order) => ({ type: GET_ORDER, payload: order })
export const getOrdersAction = (orders: Order[]) => ({ type: GET_ORDERS, payload: orders })
export const addOrderAction = (order: Order) => ({ type: ADD_ORDER, payload: order })
export const updateOrderAction = (order: Order) => ({ type: UPDATE_ORDER, payload: order })
export const getOrdersByStateCountAction = (ordersByStateCounters: OrdersByStateCount[]) => ({
  type: GET_ORDERS_BY_STATE_COUNT,
  payload: ordersByStateCounters,
})
export const getOrderTableRowsAction = (orderTableRows: OrderTableRow[]) => ({
  type: GET_ORDER_TABLE_ROWS,
  payload: orderTableRows,
})
