import makeStyles from '@mui/styles/makeStyles'
import { ClientDisplay } from '../components/ClientList/ClientDisplay'
import { ClientTableTopBar } from '../components/ClientList/ClientTableTopBar'
import { ClientInformationDialog } from '../components/dialogs/ClientInformationDialog'
import { FileImportDialog } from '../components/dialogs/FileImportDialog'
import { MassOrderInputDialog } from '../components/dialogs/MassOrderInputDialog'
import { useTranslation } from 'react-i18next'
import { NewLayout } from '../components/newLayout/NewLayout'

const useStyles = makeStyles(theme => ({
  clientDisplayTable: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  clientEssentials: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  test: {
    background: 'red',
    width: '200px',
    position: 'absolute',
    top: '20px',
    left: '-50px',
    opacity: '0.95',
    textAlign: 'center',
    lineHeight: '25px',
    fontSize: '16px',
    letterSpacing: '1px',
    color: '#f0f0f0',
    transform: 'rotate(-45deg)',
    paddingRight: '18px',
  },
}))

const ribon = process.env.REACT_APP_ENV_RIBBON

export const ClientList = () => {
  const { t } = useTranslation()

  return (
    <NewLayout pageName={t('CLIENT_OVERVIEW')}>
      <ClientTableTopBar />
      <ClientDisplay />
      <ClientInformationDialog />
      <MassOrderInputDialog />
      <FileImportDialog />
    </NewLayout>
  )
}
