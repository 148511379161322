import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { newThemeObject } from '../../newTheme'

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderRadius: newThemeObject.roundness.basic,
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },
}))

interface CardWrapperProps {
  children: React.ReactNode
  className?: string
}

const CardWrapper: React.FC<CardWrapperProps> = ({ children, className }) => {
  const classes = useStyles()
  return <div className={classNames(classes.wrapper, className)}>{children}</div>
}

export default CardWrapper
