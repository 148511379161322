import { Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React from 'react'
import { newThemeObject } from '../../newTheme'

type ClassNameKey = 'main' | 'basic' | 'secondary'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: newThemeObject.roundness.small,
    minHeight: '100%',
  },
  textDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    translate: '0 -0.2rem',
  },
  middleText: {
    // alignSelf: 'center',
    fontSize: theme.typography.fontSize * 2,
    marginTop: '5px',
    fontWeight: 'bold',
    // paddingBottom: theme.spacing(0),
  },
  bottomText: {
    // position: 'absolute',
    // top: '5px',
    fontSize: theme.typography.fontSize * 1,
    fontWeight: '600',
    opacity: 0.5,
    paddingBottom: '10px',
  },
  main: {
    background: newThemeObject.palette.orderTiles.main.bg,
    color: newThemeObject.palette.orderTiles.main.text,
  },
  secondary: {
    background: newThemeObject.palette.orderTiles.secondary.bg,
    color: newThemeObject.palette.orderTiles.secondary.text,
  },
  basic: {
    background: newThemeObject.palette.orderTiles.basic.bg,
    color: newThemeObject.palette.orderTiles.basic.text,
  },
}))

interface Props {
  middleText: React.ReactNode
  bottomText: React.ReactNode
  className?: string
  onTileClick?: React.MouseEventHandler<HTMLDivElement>
  type?: ClassNameKey
}

export const ActiveOrdersTile: React.FC<Props> = ({
  middleText,
  bottomText,
  className,
  onTileClick,
  type = 'basic',
}) => {
  const classes = useStyles()
  const used = classes[type]
  const formattedBottomText = typeof bottomText === 'string' ? bottomText.toUpperCase() : bottomText
  return (
    <div onClick={onTileClick} className={classNames(used, classes.root)}>
      <div className={classes.textDiv}>
        <Typography className={classes.bottomText}>{formattedBottomText}</Typography>
        <Typography className={classNames(classes.middleText)}>{middleText}</Typography>
      </div>
    </div>
  )
}
