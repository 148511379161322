import { Info } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { ApexOptions } from 'apexcharts'
import classNames from 'classnames'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { newThemeObject } from '../../newTheme'
import CardWrapper from '../common/CardWrapper'
import CashFlowStats from './CashFlowStats'
import FixedIncomeStats from './FixedIncomeStats'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  statsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: newThemeObject.palette.main.primary,
    fontWeight: 600,
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
  noteWrapper: {
    display: 'flex',
    borderRadius: newThemeObject.roundness.basic,
    backgroundColor: newThemeObject.palette.background.lightGray,
    color: newThemeObject.palette.text.gray,
    padding: newThemeObject.padding.smallWrapper,
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  noteText: {
    margin: 0,
  },
  graphWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

interface AreaChartProps {
  graphLineIndex: number
  handleChangePortfolioAmount: (event: React.ChangeEvent<{}>, newValue: number) => void
  optionsGraphLine: ApexOptions
  seriesGraphLine: any
  index?: number
  handleChangeIndex?: (index: number) => void
  portfolio: any | null
  labelTab: any
  labels: any[]
}

const AreaChart: React.FC<AreaChartProps> = ({
  graphLineIndex,
  handleChangePortfolioAmount,
  optionsGraphLine,
  seriesGraphLine,
  index,
  handleChangeIndex,
  portfolio,
  labelTab,
  labels,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  let accountAmount =
    portfolio && portfolio.assetClassPosition && portfolio.assetClassPosition.filter((item: any) => item.type === 'MM').length > 0
      ? portfolio.assetClassPosition.filter((item: any) => item.type === 'MM')[0].amount
      : 0
  return (
    <div className={classes.wrapper}>
      <div className={classes.statsWrapper}>
        <CardWrapper className={classes.cardWrapper}>
          <FixedIncomeStats
            title={labelTab === 0 ? t('CASHFLOW') : labels[labelTab - 1].name}
            HSNumber={getFiltredItem(labels, portfolio, labelTab, accountAmount, 'amountCZK') - ( labelTab === 0 ? accountAmount : 0)}
            ABNumber={labelTab === 0 ? accountAmount : 0}
            TNumber={getFiltredItem(labels, portfolio, labelTab, accountAmount, 'amountCZK')}
            OYNumber={getFiltredItem(labels, portfolio, labelTab, accountAmount, 'expectedAmountCZK')}
            isLabel={labelTab>0}
          />
        </CardWrapper>

        <CardWrapper className={classes.cardWrapper}>
          <CashFlowStats
            thisYear={getFiltredItem(labels, portfolio, labelTab, 0, 'incomeThisYearCZK')}
            nextYear={getFiltredItem(labels, portfolio, labelTab, 0, 'incomeNextYearCZK')}
            fiveYears={getFiltredItem(labels, portfolio, labelTab, 0, 'income5YearCZK')}
            tenYears={getFiltredItem(labels, portfolio, labelTab, 0, 'income10YearCZK')}
          />
        </CardWrapper>
      </div>

      <CardWrapper className={classNames(classes.cardWrapper, classes.graphWrapper)}>
        <p className={classes.title}>{t('CUMULATIVE_CASHFLOW')}</p>
        <ReactApexChart options={optionsGraphLine} series={seriesGraphLine} type="area" height={325} width={550} />
        <div className={classes.noteWrapper}>
          <Info />
          <p className={classes.noteText}>{t('SUMMARY_DISCLAIMER')}</p>
        </div>
      </CardWrapper>
    </div>
  )
}

export default AreaChart

const getFiltredItem = (labels: any[], portfolio: any, labelTab: any, accountAmount: any, itemName: string) => {
  let result = 0
  if (labelTab === 0) {
    result =
      portfolio && portfolio.incomeCashFlow && portfolio.incomeCashFlow[itemName]
        ? portfolio.incomeCashFlow[itemName] + accountAmount
        : 0
  } else {
    if (labels && portfolio.incomeCashFlow.positions) {
      for (let i = 0; i < labels[labelTab - 1].securities.length; i++) {
        for (let j = 0; j < portfolio.incomeCashFlow.positions.length; j++) {
          if (labels[labelTab - 1].securities[i].securityId === portfolio.incomeCashFlow.positions[j].securityId) {
            result += portfolio.incomeCashFlow.positions[j][itemName]
          }
        }
      }
    }
  }
  return result
}
