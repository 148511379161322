import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getClientFilesRequest, postClientFileRequest } from '../api/clientFileApi'
import { getClientFileAction, saveClientFileAction } from '../redux/actions/clientFileActions'
import { ClientFile} from '../redux/reducers/clientFile'
import { REQUEST_GET_CLIENT_FILE } from '../redux/actionTypes'

export const useClientFileApi = () => {
  const dispatch = useDispatch()

  const getClientFiles = useCallback(async (clientId: Number) => {
    dispatch({type: REQUEST_GET_CLIENT_FILE})
    await getClientFilesRequest(clientId)
      .then(response => {
        dispatch(getClientFileAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const saveClientFiles = useCallback(async (clientFiles: ClientFile[]) => {
    await postClientFileRequest(clientFiles)
      .then(response => {
        dispatch(saveClientFileAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getClientFiles,
    saveClientFiles,
  }
}
