import { debug } from 'console'
import { ReduxAction } from '../../types/common'
import { GET_LABELS, POST_LABEL, PUT_LABEL } from '../actionTypes'

export interface Label {
  labelId: number
  name: string
  color: string
  portfolioId: number
  securities: LabelSecurity[]
}

export interface LabelSecurity {
  labelSecurityId: number
  labelId: number
  securityId: number
}

interface LabelState {
  labels: Label[]
}

const INITIAL_STATE: LabelState = {
  labels: [],
}

export const labelState = (state: LabelState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_LABELS:
      return {
        ...state,
        labels: action.payload as Label[],
      }
    // case POST_LABEL:
    //     state.labels.push(action.payload as Label)
    //     return {
    //         ...state,
    //     }
    // case PUT_LABEL:
    //     for ( let i=0; i<state.labels.length; i++) {
    //         if ( state.labels[i].labelId === action.payload.labelId ) {
    //             state.labels[i] = action.payload;
    //         }
    //     }
    //     return {
    //         ...state,
    //     }
    default:
      return state
  }
}
