import React, { useLayoutEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { CANCLE_ORDER_DIALOG_ID, ORDER_DETAIL_DIALOG_ID } from '../../constants/dialogConstants'
import { closeDialog } from '../../redux/actions/dialogActions'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { Order } from '../../redux/reducers/orders'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
}))

interface CancleOrderDialogProps {
  order: Order
}

export const CancleOrderDialog: React.FC<CancleOrderDialogProps> = ({ order }) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const foundDialog = dialogs.find(dialog => dialog.id === CANCLE_ORDER_DIALOG_ID)
  const open = !!foundDialog
  const { t } = useTranslation()
  const { deleteOrder } = useInstrumentApi()

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(CANCLE_ORDER_DIALOG_ID))
    }
  }, [])

  const handleSubmit = () => {
    deleteOrder(order)
    dispatch(closeDialog(CANCLE_ORDER_DIALOG_ID))
    dispatch(closeDialog(ORDER_DETAIL_DIALOG_ID))
  }

  const handleClose = () => {
    dispatch(closeDialog(CANCLE_ORDER_DIALOG_ID))
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
          {t('CANCLE_ORDER_DIALOG_TITLE')}
        </DialogTitle>

        <DialogContent style={{ height: '120px', padding: '40px', display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body1">{t('CANCLE_ORDER_DIALOG_MESSAGE', { name: order.orderIdent })} </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
            {t('STORNO')}
          </Button>
          <Button
            style={{ backgroundColor: '#E2370F' }}
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            {t('CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
