export const CLIENT_INFORMATION_DIALOG_ID = 'CLIENT_INFORMATION_DIALOG_ID'
export const ORDER_INPUT_DIALOG_ID = 'ORDER_INPUT_DIALOG_ID'
export const MASS_ORDER_INPUT_DIALOG_ID = 'MASS_ORDER_INPUT_DIALOG_ID'
export const CLEAR_CONFIRMATION_DIALOG_ID = 'CLEAR_CONFIRMATION_DIALOG_ID'
export const ORDER_EDIT_DIALOG_ID = 'ORDER_EDIT_DIALOG_ID'
export const ORDER_DETAIL_DIALOG_ID = 'ORDER_DETAIL_DIALOG_ID'
export const FILE_IMPORT_DIALOG_ID = 'FILE_IMPORT_DIALOG_ID'
export const ACTION_FAILED_ALERT_ID = 'ACTION_FAILED_ALERT_ID'
export const ACTION_SUCESS_ALERT_ID = 'ACTION_SUCESS_ALERT_ID'
export const INVESTMENT_DIALOG_ID = 'INVESTMENT_DIALOG_ID'
export const INSTRUMENT_INPUT_DIALOG_ID = 'INSTRUMENT_INPUT_DIALOG_ID'

export const NEW_LABEL_DIALOG_ID = 'NEW_LABEL_DIALOG_ID'
export const REMOVE_LABEL_DIALOG_ID = 'REMOVE_LABEL_DIALOG_ID'
export const CHOICE_LABEL_DIALOG_ID = 'CHOICE_LABEL_DIALOG_ID'
export const CANCLE_ORDER_DIALOG_ID = 'CANCLE_ORDER_DIALOG_ID'
export const CANCLE_ORDERS_DIALOG_ID = 'CANCLE_ORDERS_DIALOG_ID'
