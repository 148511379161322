//TODO type rates

export const useExchangeRate =
  () => (currencyFrom: string | null, currencyTo: string | null, value: number, rates: any) => {
    if (currencyFrom === currencyTo) return value
    if (currencyTo === 'CZK') {
      const exRate = rates.find((r: any) => r.currencyFrom === currencyFrom)
      const exchanged = (value * (exRate?.rate || 0)) / (exRate?.value || 1)
      return exchanged
    } else {
      const exRate = rates.find((r: any) => r.currencyFrom === currencyTo)
      const exchanged = (value / (exRate?.rate || 0)) * (exRate?.value || 1)
      return exchanged
    }
  }
