import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getRolesRequest } from '../api/roleApi'
import { getRolesAction } from '../redux/actions/roleActions'
// import {closeDialog, openDialog} from '../redux/actions/dialogActions'
// import {Role} from '../redux/reducers/role'
// import {ACTION_FAILED_ALERT_ID, CLIENT_INFORMATION_DIALOG_ID} from '../constants/dialogConstants'

export const useRoleApi = () => {
  const dispatch = useDispatch()

  const getRoles = useCallback(async (id: Number | null) => {
    await getRolesRequest(id)
      .then(response => {
        dispatch(getRolesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getRoles,
  }
}
