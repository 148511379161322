import { CLOSE_DIALOG, OPEN_DIALOG } from '../actionTypes'

export const openDialog = (id: string, payload?: any) => ({ type: OPEN_DIALOG, payload: { id, payload } })

export const openDialogMessage = (id: string, message: string, payload?: any) => ({
  type: OPEN_DIALOG,
  message: { message },
  payload: { id, message, payload },
})

export const closeDialog = (id: string) => ({ type: CLOSE_DIALOG, payload: { id } })
