import React from 'react'
import { makeStyles } from '@mui/styles'
import { newThemeObject } from '../../newTheme'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, ${theme.spacing(3)})`,
    gridTemplateRows: `repeat(auto-fill, ${theme.spacing(3)})`,
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignContent: 'center',
    border: '1px solid',
    borderColor: 'rgba(0,0,0,0)',
  },
  wrapperErr: {
    borderColor: newThemeObject.palette.main.error,
  },
  colorWrapper: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  color: {
    position: 'relative',
    width: '90%',
    height: '90%',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  selection: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    transition: 'none',
  },
}))

interface CustomColorPickerProps {
  colors: string[]
  color: string
  setColor: (color: string) => void
  error: boolean
}

const CustomColorPicker: React.FC<CustomColorPickerProps> = ({ colors, color, setColor, error }) => {
  const classes = useStyles()

  return (
    <div className={error ? classNames(classes.wrapper, classes.wrapperErr) : classes.wrapper}>
      {colors.map(clr => (
        <div key={clr} className={classes.colorWrapper}>
          <div style={{ backgroundColor: clr }} className={classes.color} onClick={() => setColor(clr)}>
            {clr === color && <div className={classes.selection} />}
          </div>
        </div>
      ))}
    </div>
  )
}

export default CustomColorPicker
