import { Client, ClientPortfolioType } from '../redux/reducers/clients'
import { Order } from '../redux/reducers/orders'
import { Security } from '../redux/reducers/securities'

export const newClient: Client = {
  clientId: 0,
  uid: 'KLFDJKLJFDKLJFLKDJ',
  titlePre: 'Ing.',
  firstName: 'Jan',
  surname: 'Novák',
  titleAfter: null,
  personalNumber: '1122331234',
  nationality: null,
  birthDate: '01.01.1960 00:00',
  telephoneNumber: '00420123456789',
  email: 'Jan.novak@email2.cz',
  identityCardNumber: 'BDE454564',
  identityCardValidity: '01.01.1960 00:00',
  identityCardIssuer: 'PCR',
  contactAddressEqualsPermanent: false,
  note: 'notenotenotenotenote',
  state: 'VALID',
  investmentHorizonte: null,
  riskProfile: 5,
  esg: '',
  investmentQuestionnaireValidTo: null,
  counterpartyName: null,
  clientCounterpartyId: null,
  portfolios: [],
  permanentAddress: {
    addressId: 18,
    street: 'Ulice',
    town: 'Praha',
    zipCode: '100 01',
    country: 'CR',
    addressType: 'PERMANENT',
  },
  contactAddress: {
    addressId: 19,
    street: 'Ulice2',
    town: 'Brno',
    zipCode: '100 02',
    country: 'CR',
    addressType: 'CONTACT',
  },
  roles: [],
  category: null,
  skillAndExperience: null,
  preference: null,
  lossAbility: null,
  isID: true,
  riskValue: null,
  isRiskValue: true,
  isRiskValueSec: true,
  horizonteValue: null,
  isHorizonteValue: true,
  isHorizonteValueSec: true,
  categorySec: null,
  isCategorySec: true,
  skillAndExperienceSec: null,
  isSkillAndExperienceSec: true,
  preferenceSec: null,
  isPreferenceSec: true,
  lossAbilitySec: null,
  isLossAbilitySec: true,
  orderComment: null,
  shouldHaveKcUser: true,
  activeKcUser: false,
  createdKcUser: false,
  clientExternalId: null,
  shouldResetPassword: false,
}

export const emptyClient: Client = {
  clientId: 0,
  uid: null,
  titlePre: null,
  firstName: null,
  surname: null,
  titleAfter: null,
  personalNumber: null,
  nationality: null,
  birthDate: null,
  telephoneNumber: null,
  email: null,
  identityCardNumber: null,
  identityCardValidity: null,
  identityCardIssuer: null,
  contactAddressEqualsPermanent: false,
  note: null,
  state: 'VALID',
  investmentHorizonte: null,
  riskProfile: null,
  investmentQuestionnaireValidTo: null,
  counterpartyName: null,
  clientCounterpartyId: null,
  portfolios: [],
  permanentAddress: {
    addressId: null,
    street: null,
    town: null,
    zipCode: null,
    country: null,
    addressType: 'PERMANENT',
  },
  contactAddress: null,
  roles: [],
  category: null,
  skillAndExperience: null,
  preference: null,
  lossAbility: null,
  isID: true,
  riskValue: null,
  isRiskValue: true,
  isRiskValueSec: true,
  horizonteValue: null,
  isHorizonteValue: true,
  isHorizonteValueSec: true,
  categorySec: null,
  isCategorySec: true,
  skillAndExperienceSec: null,
  isSkillAndExperienceSec: true,
  preferenceSec: null,
  isPreferenceSec: true,
  lossAbilitySec: null,
  isLossAbilitySec: true,
  orderComment: null,
  shouldHaveKcUser: true,
  createdKcUser: false,
  activeKcUser: false,
  esg: '',
  clientExternalId: null,
  shouldResetPassword: false,
}

export const newOrder: Order = {
  amount: null,
  attachments: null,
  cpty: {
    brokerName: 'JT',
    marketName: '',
    name: '',
  },
  currency: '',
  direction: 'OD_BUY',
  orderId: null,
  orderIdent: null,
  portfolio: {
    currency: '',
    name: '',
    firstIndicatorDate: null,
    lastIndicatorDate: null,
    portfolioId: 2,
  },
  price: 0,
  quantity: null,
  filledQuantity: null,
  securityId: 1,
  state: '',
  type: 'OT_QUANTITY',
  validityDate: new Date(),
  acceptationDate: null,
  validityType: 'OV_GTD',
  marketType: 'OMT_MARKET',
  orderCreationDate: null,
  actions: [],
  omsId: null,
  orderComment: null,
  sendToFix: null,
  fee: null,
  errorDescription: null,
  settleDirectly: false
}

export const spoofSecurity = {
  assetClass: 'BOND',
  currency: 'CZK',
  isin: 'spoofISIN',
  name: 'spoofName',
  securityId: 99999,
  market: 'LSE',
  lastQuotePrice: 15,
  lastPriceIndication: '-2,1',
  lastQuoteDate: 'spoofDate',
}

export const emptySecurity = {
  assetClass: '',
  currency: '',
  isin: '',
  name: '',
  securityId: 0,
  market: '',
  lastQuotePrice: 0,
  lastPriceIndication: '',
  lastQuoteDate: '',
}

export const newSecurity: Security = {
  assetClass: '',
  currency: '',
  isin: '',
  symbol: '',
  ticker: '',
  name: '',
  securityId: 0,
  market: '',
  lastQuotePrice: null,
  lastPriceIndication: 0,
  lastQuoteDate: '',
  instrumentType: '',
  nominal: null,
  riskValue: 0,
  horizonteValue: 0,
  category: '',
  skillAndExperience: '',
  preference: '',
  lossAbility: '',
  webpage: '',
  additionalInformation: '',
  comment: '',
  fileKeyMessageContent: '',
  fileKeyMessageHeader: '',
  fileKeyMessageName: '',
  fileProspectContent: '',
  fileProspectHeader: '',
  fileProspectName: '',
  coupon: 0,
  expectedPrice: 0,
  maturityDate: null,
}

export const emptyPortfolio = {
  positions: [],
  unfinishedOrders: [],
  amount: null,
  currency: null,
  name: null,
  portfolioId: null,
}

export const emptyClientPortfolioType: ClientPortfolioType = {
  name: '',
  portfolioId: null,
  amount: 0,
  currency: '',
  type: '',
  amountHistory: [],
  assetClassPosition: [],
  currencyPosition: [],
  instrumentPosition: [],
  riskPosition: [],
  horizontePosition: [],
  externalId: null,
  accountsBalance: 0,
  riskValue: null,
  horizonteValue: null,
}
