import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import { newThemeObject } from '../../newTheme'

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    height: 60,
    display: 'flex',
    margin: `0px ${theme.spacing(2)}`,
    fontWeight: 600,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
  child: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
}))

const EditedDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: newThemeObject.roundness.small,
  },
}))

interface OnePageDialogProps extends DialogProps {
  title: string
  open: boolean
  buttons: React.ReactNode
  onClose: () => void
}

const OnePageDialog: React.FC<OnePageDialogProps> = ({
  title,
  children,
  open,
  onClose,
  maxWidth = 'sm',
  buttons,
  ...dialogProps
}) => {
  const classes = useStyles()

  return (
    <EditedDialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth} {...dialogProps}>
      <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.child}>{children}</DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </EditedDialog>
  )
}

export default OnePageDialog
