import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles: any = makeStyles(theme => ({
  blue: {
    backgroundColor: theme.palette.primary.main,
  },
  orange: {
    backgroundColor: theme.palette.error.main,
  },
}))

export const useInputDialogColor = () => {
  const classes = useStyles()

  return (orderDirection: string) => {
    if (orderDirection === 'OD_SELL') {
      return classes.orange
    }
    return classes.blue
  }
}
