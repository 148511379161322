import React, { useLayoutEffect, useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { CHOICE_LABEL_DIALOG_ID } from '../../constants/dialogConstants'
import { closeDialog } from '../../redux/actions/dialogActions'
import { useTranslation } from 'react-i18next'
import { useLabelApi } from '../../hooks/useLabelApi'
import { Label } from '../../redux/reducers/label'
import { REQUIERED } from '../../constants/textConstants'
import { TextField, Autocomplete } from '@mui/material'
import OnePageDialog from '../common/OnePageDialog'
import { DefaultButton } from '../common/DefaultButton'

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
}))

interface ChoiceLabelDialogProps {
  securityName: string | null
  securityId: number | null
  portfolioId: number | null
  labels: Label[]
}

export const ChoiceLabelDialog: React.FC<ChoiceLabelDialogProps> = ({
  securityName,
  securityId,
  portfolioId,
  labels,
}) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const foundDialog = dialogs.find(dialog => dialog.id === CHOICE_LABEL_DIALOG_ID)
  const open = !!foundDialog
  const { t } = useTranslation()
  const { assignLabel } = useLabelApi()
  const [securityLabels, setSecurityLabels] = useState([] as Label[])
  const errors = useSelector(state => state.useractions.errors)

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(CHOICE_LABEL_DIALOG_ID))
    }
  }, [])

  const handleSubmit = () => {
    assignLabel(portfolioId || 0, securityId || 0, securityLabels)
    setSecurityLabels([])
    dispatch(closeDialog(CHOICE_LABEL_DIALOG_ID))
  }

  const handleClose = () => {
    setSecurityLabels([])
    dispatch(closeDialog(CHOICE_LABEL_DIALOG_ID))
  }

  const handleChangeAutocomplete = (name: string) => (event: any, values: Label[]) => {
    if (values) {
      setSecurityLabels(values)
    }
  }

  useEffect(() => {
    if (labels && securityId) {
      // init assigned labels tu security
      let tmp = [] as Label[]

      for (let i = 0; i < labels.length; i++) {
        if (labels[i].securities) {
          for (let j = 0; j < labels[i].securities.length; j++) {
            if (labels[i].securities[j].securityId === securityId) {
              tmp.push(labels[i])
            }
          }
        }
      }

      setSecurityLabels(tmp)
    }
  }, [labels, securityId])

  const buttons = () => {
    return (
      <>
        <DefaultButton onClick={handleClose}>{t('CANCEL2')}</DefaultButton>
        <DefaultButton onClick={handleSubmit}>{t('CONFIRM')}</DefaultButton>
      </>
    )
  }

  return (
    <div>
      <OnePageDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        buttons={buttons()}
        maxWidth="sm"
        title={`${t('CHOICE_LABEL_DIALOG_TITLE')} : ${securityName}`}
      >
        {' '}
        <Autocomplete
          multiple
          fullWidth
          id="label"
          options={labels}
          value={securityLabels}
          getOptionLabel={item => item.name}
          onChange={(event, newValue) => handleChangeAutocomplete('label')(event, newValue)}
          renderInput={params => (
            <>
              <TextField
                {...params}
                label={t('LABEL')}
                variant="standard"
                helperText={errors['label'] ? t(REQUIERED) : ''}
                error={errors['label']}
                required
              />
            </>
          )}
        />
      </OnePageDialog>
    </div>
  )
}
