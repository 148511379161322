import { Label } from '../redux/reducers/label'
import { fetchRequest } from '../services/http.service'

const GET_LABEL_URL = '/labels'
const POST_LABEL_URL = '/label'
const PUT_LABEL_URL = '/label'
const PUT_SECURITY_LABEL_URL = '/label'

export const getLabelsRequest = (portfolioId: Number | null) =>
  fetchRequest.get<Label[]>(GET_LABEL_URL + `/${portfolioId ? portfolioId : ''}`)
export const createLabelRequest = (label: Label | null) => fetchRequest.post<Label>(POST_LABEL_URL, label)
export const updateLabelRequest = (label: Label | null) => fetchRequest.put<Label>(PUT_LABEL_URL, label)
export const deleteLabelRequest = (labelId: number | null) =>
  fetchRequest.remove<Label[]>(PUT_LABEL_URL + `/${labelId ? labelId : ''}`)
export const assignLabelRequest = (portfolioId: number, securityId: number, labels: Label[]) =>
  fetchRequest.put<Label[]>(PUT_SECURITY_LABEL_URL + `/${portfolioId}/${securityId}`, labels)
