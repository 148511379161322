import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { newThemeObject } from '../../newTheme'
import { formatNumber } from '../../utils/numbersFormatter'
import CardWrapper from '../common/CardWrapper'
import classNames from 'classnames'
import { AccountPosition, Position } from '../../redux/reducers/portfolio'
import { Label, LabelSecurity } from '../../redux/reducers/label'
import { useTranslation } from 'react-i18next'
import { InstrumentIcon } from '../common/InstrumentIcon'
import OrderButtons from './OrderButtons'
import { Security } from '../../redux/reducers/securities'
import DataTableRowDetails from './DataTableRowDetails'
import { hexToRGBA } from '../../utils/styles'

const useStyles = makeStyles(theme => ({
  title: {
    color: newThemeObject.palette.main.primary,
    margin: 0,
  },
  paper: {
    overflowY: 'auto',
    overflowX: 'auto',
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    minWidth: 1000,
  },
  totalText: {
    backgroundColor: newThemeObject.palette.main.primary,
    margin: 0,
    color: '#fff',
    height: '2rem',
    borderRadius: '1rem',
    padding: '1rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topBar: {
    margin: '0.2rem 2rem',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  clickableRow: {
    cursor: 'pointer',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      borderTop: 'thin solid',
      borderColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  firstClickableRow: {
    cursor: 'pointer',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  lastRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  clickableRowExpanded: {
    background: `linear-gradient(90deg,
          ${hexToRGBA(newThemeObject.palette.main.highlight, 0)} 0%,
          ${hexToRGBA(newThemeObject.palette.main.highlight, 1)} 1%,
          ${hexToRGBA(newThemeObject.palette.main.highlight, 1)} 99%,
          ${hexToRGBA(newThemeObject.palette.main.highlight, 0)} 100%)`,
    '& .MuiTableCell-root': {
      backgroundColor: 'none',
    },
  },
  tableHead: {
    ...newThemeObject.table.tableHead,
  },
  tableHeadChildrenText: {
    ...newThemeObject.table.tableHeadChildrenText,
  },
  tableHeadChildren: {
    ...newThemeObject.table.tableHeadChildren,
  },
  tableHeadChildrenFirst: {
    ...newThemeObject.table.tableHeadChildrenFirst,
  },
  tableHeadChildrenLast: {
    ...newThemeObject.table.tableHeadChildrenLast,
  },
  tableContent: {
    ...newThemeObject.table.tableContent,
  },
  tableContentFirst: {
    ...newThemeObject.table.tableContentFirst,
  },
  columnCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    color: '#000',
  },
  tableContentIcon: {
    padding: '25px 20px',
  },
  text: {
    fontWeight: '400',
    margin: '0',
    fontSize: '0.7rem',
    textWrap: 'wrap',
  },
}))

interface DataTableRowProps {
  selectedPortfolio: number
  portfolios: any[]
  includeSecurity: (securityId: number, label: Label) => boolean
  labelTab: number
  labels: Label[]
  securities: Security[]
  setLabelSecurity: (labelSecurity: any) => void
  clientId: string
}

const DataTable: React.FC<DataTableRowProps> = ({
  selectedPortfolio,
  portfolios,
  includeSecurity,
  labelTab,
  labels,
  securities,
  setLabelSecurity,
  clientId,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<number[]>([])
  const { t } = useTranslation()

  const handleRowClick = (index: number) => () => {
    setExpanded(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index)
      } else {
        return [...prev, index]
      }
    })
  }

  const getValidSecurity = (securityId: number) => {
    for (let i = 0; i < securities.length; i++) {
      if (securities[i].securityId === securityId) return securities[i]
    }
    return null
  }

  const isValidSecurity = (securityId: number) => {
    for (let i = 0; i < securities.length; i++) {
      if (securities[i].securityId === securityId) return true
    }
    return false
  }

  return (
    <>
      {portfolios[selectedPortfolio] &&
        portfolios[selectedPortfolio].assetClassPosition.map((p: any, idx: number) => {
          let tmp: Position[] = []
          let tmpSum = 0;

          if (labelTab > 0) {
            tmp = p.positions.filter((item: Position) =>
              includeSecurity(item.instrument.instrumentId, labels[labelTab - 1])
            )
            for ( let i=0; i<tmp.length; i++) {
              tmpSum +=  tmp[i].portfolioCurrencyAmount;
            }
            
          } else {
            tmp = p.positions
          }

          return tmp && tmp.length > 0 ? (
            <TableContainer component={CardWrapper} className={classes.paper} key={idx}>
              <span className={classes.topBar}>
                <p className={classes.title}>{t(p.type)}</p>
                <p className={classes.totalText}>
                  {formatNumber(labelTab>0?tmpSum:p.amount)} {portfolios[selectedPortfolio].currency}
                </p>
              </span>
              <Table className={classes.table} size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenFirst])} />
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('SHARE')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('ISIN')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('NAME')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('TYPE')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('ORDER_QUANTITY')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('PURCHASE_PRICE')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('LAST_PRICE')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('P_L')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      {p.type === 'BOND' && <p className={classes.tableHeadChildrenText}>{t('AUV')}</p>}
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('AMOUNT')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('CURRENCY')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>
                        {t('AMOUNT_IN')} {portfolios[selectedPortfolio].currency}
                      </p>
                    </TableCell>
                    <TableCell className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenLast])} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tmp.map((position: Position, index: number) => (
                    <React.Fragment key={position.positionId}>
                      <TableRow
                        className={
                          expanded.includes(position.positionId)
                            ? classNames(
                                classes.clickableRowExpanded,
                                index === 0 ? classes.firstClickableRow : classes.clickableRow
                              )
                            : index === 0
                            ? classes.firstClickableRow
                            : classes.clickableRow
                        }
                        onClick={handleRowClick(position.positionId)}
                      >
                        <TableCell className={classNames(classes.tableContent, classes.tableContentFirst)}>
                          <InstrumentIcon securityType={position.instrument.assetClass} />
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{position.percent}%</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{position.instrument.isin}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{position.instrument.name}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{position.instrument.instrumentType}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{position.quantity}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{formatNumber(position.averagePurchasePrice)}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{formatNumber(position.lastPrice)}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p
                            className={classes.text}
                            style={
                              position.profitLossPercentage > 0
                                ? { color: newThemeObject.palette.main.green, fontWeight: 700 }
                                : { color: newThemeObject.palette.main.primary, fontWeight: 700 }
                            }
                          >
                            {formatNumber(position.profitLossPercentage)}%
                          </p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>
                            {position.instrument.instrumentType === 'DLUHOPIS'
                              ? `${formatNumber(position.lastAccrued || 0)}%`
                              : ''}
                          </p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{formatNumber(position.amount)}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{position.instrument.currency}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{formatNumber(position.portfolioCurrencyAmount)}</p>
                        </TableCell>
                        <TableCell>
                          <OrderButtons
                            securities={securities}
                            labels={labels}
                            position={position}
                            setLabelSecurity={setLabelSecurity}
                            isValidSecurity={isValidSecurity}
                            clientId={clientId}
                          />
                        </TableCell>
                      </TableRow>
                      {expanded.includes(position.positionId) && (
                        <DataTableRowDetails position={position} getValidSecurity={getValidSecurity} />
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : p.accountPositions && p.accountPositions.length > 0 && labelTab === 0 ? (
            <TableContainer component={CardWrapper} className={classes.paper} key={idx}>
              <span className={classes.topBar}>
                <p className={classes.title}>{t(p.type)}</p>
                <p className={classes.totalText}>
                  {formatNumber(p.amount)} {portfolios[selectedPortfolio].currency}
                </p>
              </span>
              <Table className={classes.table} size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenFirst])}>
                      <p className={classes.tableHeadChildrenText}>{t('SHARE')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('NAME')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('TYPE')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('AMOUNT')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('CURRENCY')}</p>
                    </TableCell>
                    <TableCell className={classNames(classes.tableHeadChildrenLast, classes.tableHeadChildren)}>
                      <p className={classes.tableHeadChildrenText}>
                        {t('AMOUNT_IN')} {portfolios[selectedPortfolio].currency}
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {p.accountPositions.map((accPosition: AccountPosition, index: number) => (
                    <TableRow key={index} className={index === p.accountPositions.length - 1 ? classes.lastRow : ''}>
                      <TableCell className={classNames(classes.tableContent, classes.tableContentFirst)}>
                        <p className={classes.text}>{accPosition.percent}%</p>
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        <p className={classes.text}>
                          {accPosition.account.number} {accPosition.account.currency}
                        </p>
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        <p className={classes.text}>{t('ACCOUNT')}</p>
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        <p className={classes.text}>{formatNumber(accPosition.amount)}</p>
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        <p className={classes.text}>{accPosition.account.currency}</p>
                      </TableCell>
                      <TableCell className={classNames(classes.tableContent)}>
                        <p className={classes.text}>{formatNumber(accPosition.portfolioCurrencyAmount)}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null
        })}
    </>
  )
}

export default DataTable
