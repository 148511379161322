import React, { ReactNode } from 'react'
import { Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0.5),
    color: '#000',
    fontSize: '0.8rem',
    paddingTop: theme.spacing(2),
    fontWeight: 600,
  },
}))

interface Props {
  contentTitle: string
  actionButtons?: ReactNode
}

export const ContentTitle: React.FC<Props> = ({ contentTitle, actionButtons }) => {
  const classes = useStyles()
  return (
    <Typography color="primary" className={classes.root}>
      {contentTitle}
      {actionButtons}
    </Typography>
  )
}
