import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { newThemeObject } from '../../newTheme'
import { formatNumber } from '../../utils/numbersFormatter'

const useStyles = makeStyles(theme => ({
  title: {
    color: newThemeObject.palette.main.primary,
    fontWeight: 600,
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
  contentWrapper: {
    display: 'grid',
    gridTemplateRows: 'repeat(4, 1fr)',
  },
  lineWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 0',
  },
  bottomBorder: {
    borderBottom: `1px solid ${newThemeObject.palette.main.secondary}`,
  },
  label: {
    justifySelf: 'left',
    color: newThemeObject.palette.main.sectext,
    fontWeight: 500,
    fontSize: '0.8rem',
    margin: 0,
  },
  number: {
    fontWeight: 700,
    fontSize: '0.8rem',
    margin: 0,
  },
}))

interface CashFlowStatsProps {
  thisYear: number
  nextYear: number
  fiveYears: number
  tenYears: number
}

const CashFlowStats: React.FC<CashFlowStatsProps> = ({
  thisYear,
  nextYear,
  fiveYears,
  tenYears,
}: CashFlowStatsProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <p className={classes.title}>{t('CASHFLOW')}</p>
      <div className={classes.contentWrapper}>
        <div className={classNames(classes.lineWrapper, classes.bottomBorder)}>
          <p className={classes.label}>{t('PORTFOLIO_INCOME_CASHFLOW_TY')}</p>
          <p className={classes.number}>{formatNumber(thisYear)} CZK</p>
        </div>
        <div className={classNames(classes.lineWrapper, classes.bottomBorder)}>
          <p className={classes.label}>{t('PORTFOLIO_INCOME_CASHFLOW_NY')}</p>
          <p className={classes.number}>{formatNumber(nextYear)} CZK</p>
        </div>
        <div className={classNames(classes.lineWrapper, classes.bottomBorder)}>
          <p className={classes.label}>{t('PORTFOLIO_INCOME_CASHFLOW_5Y')}</p>
          <p className={classes.number}>{formatNumber(fiveYears)} CZK</p>
        </div>
        <div className={classes.lineWrapper}>
          <p className={classes.label}>{t('PORTFOLIO_INCOME_CASHFLOW_10Y')}</p>
          <p className={classes.number}>{formatNumber(tenYears)} CZK</p>
        </div>
      </div>
    </>
  )
}

export default CashFlowStats
