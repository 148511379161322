import DateFnsUtils from '@date-io/date-fns'
import { Grid, TextField, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@date-io/date-fns'
import React from 'react'
import { useSelector } from '../../redux/hooks/useSelector'
import { Client } from '../../redux/reducers/clients'
import { PhoneNumberInput } from '../common/PhoneNumberInput'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  marginTopOverride: {
    marginTop: '0px',
  },
}))

interface Props {
  client: Client
  onClientAttributeChange: (name: string) => (value: any) => void
  onClientPermanentAddressChange: (name: string) => (value: any) => void
  onClientDateChange: (name: string) => (value: any) => void
}

export const ClientPersonalDataContainer: React.FC<Props> = ({
  client,
  onClientAttributeChange,
  onClientPermanentAddressChange,
  onClientDateChange,
}) => {
  const classes = useStyles()
  const errors = useSelector(state => state.useractions.errors)
  const { t } = useTranslation()

  const onClientInputChangeCreator = (name: string) => (event: any) => {
    onClientAttributeChange(name)(event.target.value)
  }

  const onClientAddressInputChangeCreator = (name: string) => (event: any) => {
    onClientPermanentAddressChange(name)(event.target.value)
  }

  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="nationality"
          label={t('NATIONALITY')}
          variant="standard"
          onChange={onClientInputChangeCreator('nationality')}
          value={client?.nationality}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          variant="standard"
          id="personalNumber"
          label={t('PERSONAL_NUMBER')}
          helperText={errors['personalNumber'] ? t('REQUIRE_ITEM') : ''}
          error={errors['personalNumber']}
          onChange={onClientInputChangeCreator('personalNumber')}
          value={client?.personalNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          title={
            client.createdKcUser ? 'Emailovou adresu není možné měnit na založeném uživateli v KeyCloak aplikaci.' : ''
          }
        >
          <TextField
            variant="standard"
            fullWidth
            required={client.shouldHaveKcUser}
            id="email"
            label={t('EMAIL_ADDRESS')}
            helperText={client.shouldHaveKcUser && errors['email'] ? t('REQUIRE_ITEM') : ''}
            error={client.shouldHaveKcUser && errors['email']}
            onChange={onClientInputChangeCreator('email')}
            value={client?.email}
            disabled={client.createdKcUser}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        <PhoneNumberInput
          fullWidth
          id="telephoneNumber"
          label={t('PHONE_NUMBER')}
          onChange={onClientInputChangeCreator('telephoneNumber')}
          value={client?.telephoneNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          variant="standard"
          id="identityCardNumber"
          label={t('IDENTITY_CARD_NUMBER')}
          helperText={errors['identityCardNumber'] ? t('REQUIRE_ITEM') : ''}
          error={errors['identityCardNumber']}
          onChange={onClientInputChangeCreator('identityCardNumber')}
          value={client?.identityCardNumber}
        />
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            renderInput={props => (
              <TextField
                variant="standard"
                fullWidth={true}
                {...props}
                label={t('VALIDITY_FROM')}
                id="identityCardValidity"
              />
            )}
            inputFormat="dd/MM/yyyy"
            value={client?.identityCardValidity}
            onChange={onClientDateChange('identityCardValidity')}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="standard"
          id="identityCardIssuer"
          label={t('ISSUED_FROM_IN')}
          onChange={onClientInputChangeCreator('identityCardIssuer')}
          value={client?.identityCardIssuer}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          variant="standard"
          id="street"
          label={t('STREET_NUMBER')}
          helperText={errors['street'] ? t('REQUIRE_ITEM') : ''}
          error={errors['street']}
          onChange={onClientAddressInputChangeCreator('street')}
          value={client.permanentAddress?.street}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id="town"
          variant="standard"
          label={t('CITY')}
          helperText={errors['town'] ? t('REQUIRE_ITEM') : ''}
          error={errors['town']}
          onChange={onClientAddressInputChangeCreator('town')}
          value={client.permanentAddress?.town}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          variant="standard"
          id="zipCode"
          label={t('ZIP_CODE')}
          helperText={errors['zipCode'] ? t('REQUIRE_ITEM') : ''}
          error={errors['zipCode']}
          onChange={onClientAddressInputChangeCreator('zipCode')}
          value={client.permanentAddress?.zipCode}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="standard"
          required
          id="country"
          label={t('COUNTRY')}
          helperText={errors['country'] ? t('REQUIRE_ITEM') : ''}
          error={errors['country']}
          onChange={onClientAddressInputChangeCreator('country')}
          value={client.permanentAddress?.country}
        />
      </Grid>
    </>
  )
}
