import makeStyles from '@mui/styles/makeStyles'
import { OrderTable } from './OrderTable'

const useStyles = makeStyles(theme => ({
  orderTable: {
    height: '100%',
    overflow: 'auto',
    marginTop: theme.spacing(2.5),
  },
}))

interface OrderTableProps {
  orderCheckState: boolean[]
  setOrderCheckState: (param: any[]) => any
  orderCheckStateHeader: boolean
  setOrderCheckStateHeader: (param: any) => any
}

export const OrderDisplay = ({
  orderCheckState,
  setOrderCheckState,
  orderCheckStateHeader,
  setOrderCheckStateHeader,
}: OrderTableProps) => {
  const classes = useStyles()

  return (
    <div className={classes.orderTable}>
      <OrderTable
        orderCheckState={orderCheckState}
        setOrderCheckState={setOrderCheckState}
        orderCheckStateHeader={orderCheckStateHeader}
        setOrderCheckStateHeader={setOrderCheckStateHeader}
      />
    </div>
  )
}
