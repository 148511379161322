import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { LeftNavBar } from '../common/LeftNavBar'
import { TopBar } from '../common/TopBar'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  appBar: {
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
  },
  contentContainer: {
    width: `calc(100vw - 40px)`,
    height: '100%',
    float: 'left',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    minHeight: '100vh',
  },
  leftNavbar: {
    width: '40px',
    height: '100%',
    float: 'left',
    borderRightColor: theme.palette.grey[300],
    borderRightStyle: 'solid',
    borderRightWidth: '2px',
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-end',
    justifyContent: 'center',
  },
  appFooter: {
    width: '100%',
  },
  contentTitle: {
    display: 'flex',
  },
  centerContainer: {
    width: '100vw',
  },
}))

export interface LeftNavBarButton {
  icon: React.ReactNode
  link: string
  tooltip: string
}

interface Props {
  appBarContent?: ReactNode
  leftNavBarButtons?: LeftNavBarButton[]
  footer?: React.ReactNode
  contentTitle?: React.ReactNode
  className?: string
}

export const BasicLayout: React.FC<Props> = ({
  appBarContent,
  leftNavBarButtons = [],
  children,
  footer,
  contentTitle,
  className,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.appBar}>
        <TopBar appBarContent={appBarContent} />
      </div>
      <div className={classes.centerContainer}>
        <div className={classes.leftNavbar}>
          <LeftNavBar leftNavBarButtons={leftNavBarButtons} />
        </div>
        <div className={classNames(classes.contentContainer, className)}>
          <div className={classes.contentTitle}>{contentTitle}</div>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      {footer && <div className={classes.appFooter}>{footer}</div>}
    </div>
  )
}
