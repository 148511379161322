import { Avatar } from '@mui/material'
import { useSelector } from '../../redux/hooks/useSelector'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { TransparentIconButton } from '../common/TransparentIconButton'
import { logout } from '../../services/keycloak.service'
import store from '../../redux/store'
import { isClientUser } from '../../services/keycloak.service'
import { Logout } from '@mui/icons-material'
import { formatPhoneNumber } from '../../utils/numbersFormatter'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  avatar: {
    height: '2rem',
    width: '2rem',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '0.6rem',
  },
  text: {
    margin: 0,
  },
}))

const User = () => {
  const classes = useStyles()
  const user = useSelector(state => state.auth.user)
  const client = useSelector(state => state.clients.client)

  let name, symbols, phone

  if (!isClientUser()) {
    console.log(user)
    name = `${user?.firstName} ${user?.lastName}`
    symbols = user?.firstName.charAt(0).toUpperCase() + user?.lastName.charAt(0).toUpperCase()
  } else {
    console.log(client)
    name = `${client?.firstName} ${client?.surname}`
    if (!!client.firstName) {
      symbols = client?.firstName.charAt(0).toUpperCase() + client?.surname.charAt(0).toUpperCase()
    }
    phone = client?.telephoneNumber
  }

  return (
    <span>
      <div className={classes.wrapper}>
        <Avatar className={classes.avatar}>{symbols}</Avatar>
        <span>
          <p className={classNames(classes.text, classes.title)}>{name}</p>
          <p className={classNames(classes.text, classes.subtitle)}>{formatPhoneNumber(phone)}</p>
        </span>
        <TransparentIconButton
          onClick={() => {
            logout(store)
          }}
          icon={<Logout />}
        />
      </div>
    </span>
  )
}

export default User
