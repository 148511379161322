import React, { useLayoutEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { CANCLE_ORDERS_DIALOG_ID } from '../../constants/dialogConstants'
import { closeDialog } from '../../redux/actions/dialogActions'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { OrderRowData } from '../OrderList/OrderTable'

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
}))

interface CancleOrdersDialogProps {
  orders: OrderRowData[]
  toCancle: boolean[]
}

export const CancleOrdersDialog: React.FC<CancleOrdersDialogProps> = ({ orders, toCancle }) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const foundDialog = dialogs.find(dialog => dialog.id === CANCLE_ORDERS_DIALOG_ID)
  const open = !!foundDialog
  const { t } = useTranslation()
  const { deleteOrders } = useInstrumentApi()

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(CANCLE_ORDERS_DIALOG_ID))
    }
  }, [])

  const handleSubmit = () => {
    let tmp: OrderRowData[] = []
    orders.map((order, index) => {
      if (toCancle[index] === true) tmp.push(order)
    })
    deleteOrders(tmp)
    dispatch(closeDialog(CANCLE_ORDERS_DIALOG_ID))
  }

  const handleClose = () => {
    dispatch(closeDialog(CANCLE_ORDERS_DIALOG_ID))
  }

  const getOrdersIdent = () => {
    let tmp = ''
    orders.map((order, index) => {
      if (toCancle[index] === true) tmp = tmp + order.orderIdent + ', '
    })
    if (tmp.includes(',')) return tmp.substring(0, tmp.length - 2)
    else return tmp
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
          {t('CANCLE_ORDERS_DIALOG_TITLE')}
        </DialogTitle>

        <DialogContent style={{ height: '120px', padding: '40px', display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body1">{t('CANCLE_ORDERS_DIALOG_MESSAGE', { name: getOrdersIdent() })} </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
            {t('STORNO')}
          </Button>
          <Button
            style={{ backgroundColor: '#E2370F' }}
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            {t('CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
