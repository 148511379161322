import { InputBase, InputBaseProps } from '@mui/material'
import { styled, SxProps } from '@mui/system'
import React from 'react'
import { newThemeObject } from '../../newTheme'

interface StyledTextInputProps extends InputBaseProps {
  bigger?: boolean
  error?: boolean
  sx?: SxProps
}

const EditedtextInput = styled(({ bigger, error, ...other }: StyledTextInputProps) => <InputBase {...other} />)<{
  bigger?: boolean
}>(({ theme, bigger, error }) => ({
  '& .MuiInputBase-input': {
    borderRadius: bigger ? '0.9rem' : '1rem',
    border: `1px solid ${error ? newThemeObject.palette.main.error : newThemeObject.palette.background.lightGray}`, // Apply border color based on error prop
    position: 'relative',
    backgroundColor: newThemeObject.palette.background.lightGray,
    fontSize: '0.6rem',
    marginTop: '0.2rem',
    marginBottom: '0.2rem',
    padding: bigger ? '1rem 1rem' : newThemeObject.padding.smallWrapper,
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))

const TextInput: React.FC<StyledTextInputProps> = props => {
  console.log(props.error)
  return <EditedtextInput {...props} />
}

export default TextInput
