import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { OrderEditDialog } from '../dialogs/OrderEditDialog'
import { OrderDetailDialog } from '../dialogs/OrderDetailDialog'
import { useTranslation } from 'react-i18next'
import { Client } from '../../redux/reducers/clients'
import { newThemeObject } from '../../newTheme'

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    background: newThemeObject.palette.orderTiles.main.bg,
    color: '#fffff',
    padding: 20,
    borderRadius: newThemeObject.roundness.basic,
  },
  tableContent: {
    border: 'none',
  },
  tableText: {
    color: '#ffffff',
    margin: 0,
    fontWeight: 600,
  },
  tableHeadText: {
    color: '#fff',
    opacity: '0.8',
    fontWeight: 600,
  },
  numAndText: {
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
  },
}))

interface Props {
  client: Client
}

export const IvestmentTable: React.FC<Props> = ({ client }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <TableContainer component={Paper} className={classes.tableWrapper}>
        <Table size="small">
          <TableHead>
            <p className={classes.tableHeadText}>{t('INVESTMENT_RESULT').toUpperCase()}</p>
          </TableHead>
          <TableBody>
            <TableRow className="" key="1">
              <TableCell className={classes.tableContent} component="th" scope="row">
                <span className={classes.numAndText}>
                  <p className={classes.tableText}>1</p>
                  <p className={classes.tableText}>{t('CLIENT_CATEGORY')}</p>
                </span>
              </TableCell>
              <TableCell className={classes.tableContent} component="th" scope="row">
                <p className={classes.tableText}>{t(client.category || '')}</p>
              </TableCell>
            </TableRow>

            <TableRow className="" key="2">
              <TableCell className={classes.tableContent} component="th" scope="row">
                <span className={classes.numAndText}>
                  <p className={classes.tableText}>2</p>
                  <p className={classes.tableText}>{t('KONWLEDGE_AND_EXPERIENCE')}</p>
                </span>
              </TableCell>
              <TableCell className={classes.tableContent} component="th" scope="row">
                <p className={classes.tableText}>{t(client.skillAndExperience || '')}</p>
              </TableCell>
            </TableRow>

            <TableRow className="" key="3">
              <TableCell className={classes.tableContent} component="th" scope="row">
                <span className={classes.numAndText}>
                  <p className={classes.tableText}>3</p>
                  <p className={classes.tableText}>{t('INVESTMENT_HORIZONTE')}</p>
                </span>
              </TableCell>
              <TableCell className={classes.tableContent} component="th" scope="row">
                <p className={classes.tableText}>{t(client.investmentHorizonte || '')}</p>
              </TableCell>
            </TableRow>

            <TableRow className="" key="4">
              <TableCell className={classes.tableContent} component="th" scope="row">
                <span className={classes.numAndText}>
                  <p className={classes.tableText}>4</p>
                  <p className={classes.tableText}>{t('INTESTMENT_TERGET')}</p>
                </span>
              </TableCell>
              <TableCell className={classes.tableContent} component="th" scope="row">
                <p className={classes.tableText}>{t(client.preference || '')}</p>
              </TableCell>
            </TableRow>

            <TableRow className="" key="5">
              <TableCell className={classes.tableContent} component="th" scope="row">
                <span className={classes.numAndText}>
                  <p className={classes.tableText}>5</p>
                  <p className={classes.tableText}>{t('RISK_APPROVE')}</p>
                </span>
              </TableCell>
              <TableCell className={classes.tableContent} component="th" scope="row">
                <p className={classes.tableText}>{t(client.lossAbility || '')}</p>
              </TableCell>
            </TableRow>

            <TableRow className="" key="6">
              <TableCell className={classes.tableContent} component="th" scope="row">
                <span className={classes.numAndText}>
                  <p className={classes.tableText}>6</p>
                  <p className={classes.tableText}>{t('RISK_TOLERANT')}</p>
                </span>
              </TableCell>
              <TableCell className={classes.tableContent} component="th" scope="row">
                <p className={classes.tableText}>{client.riskProfile}</p>
              </TableCell>
            </TableRow>

            <TableRow className="" key="7">
              <TableCell className={classes.tableContent} component="th" scope="row">
                <span className={classes.numAndText}>
                  <p className={classes.tableText}>7</p>
                  <p className={classes.tableText}>{t('ESG')}</p>
                </span>
              </TableCell>
              <TableCell className={classes.tableContent} component="th" scope="row">
                <p className={classes.tableText}>{t(client.esg || '-')}</p>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <OrderEditDialog />
      <OrderDetailDialog />
    </>
  )
}
