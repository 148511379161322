import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, CircularProgress } from '@mui/material'

interface Props {
  alertOpen: boolean
  onOk: () => void
  onCancel: () => void
  alertText: string
  inProgress: boolean
}

export const ClearConfirmationAlert: React.FC<Props> = ({ alertOpen, onOk, onCancel, alertText, inProgress }) => {
  return (
    <div>
      <Dialog open={alertOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Opravdu si přejete {alertText}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel}>
            Storno
          </Button>
          <Button color="primary" autoFocus onClick={onOk} disabled={inProgress}>
            {inProgress ? <CircularProgress size="1.4rem" /> : 'Potvrdit'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
