import './fonts/fonts.css'

import { createTheme } from '@mui/material'
import { newThemeObject } from './newTheme'

export const themeObject = {
  palette: {
    primary: {
      main: newThemeObject.palette.main.primary,
      dark: 'rgb(48, 68, 119)',
      mainWhite: '#fff',
      contrastMainWhite: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#fff',
      darklight: '#E6D815',
      mustsee: '#C6BDE9',
    },
    background: {
      default: newThemeObject.palette.main.background,
    },
    text: {},
    error: {
      main: 'rgb(255, 87, 51)',
    },
    warning: {
      main: 'rgb(255, 195, 0)',
    },
    buy: {
      main: '#4562ab',
    },
  },

  typography: {
    fontSize: 12,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontWeightLight: 300,
    fontFamily:
      'Poppins, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
}

export const muiTheme = createTheme(themeObject)
