import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Button } from '@mui/material'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.contrastText,
  },
}))

interface Props {
  onClick?: () => void
  size?: 'small' | 'medium' | undefined
  className?: string
  content?: any
  disabled?: boolean
  type?: string
  startIcon?: React.ReactNode
}

export const TransparentButton: React.FC<Props> = ({
  onClick,
  size,
  className,
  content,
  disabled,
  children,
  startIcon,
}) => {
  const classes = useStyles()

  return (
    <Button
      className={classNames(classes.root, className)}
      onClick={onClick}
      startIcon={startIcon}
      size={size}
      disabled={disabled}
    >
      {content}
      {children}
    </Button>
  )
}
