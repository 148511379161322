import { ReduxAction } from '../../types/common'
import { IS_FORM_VALID, SET_ERRORS, SHOW_PORTFOLIO_DETAIL } from '../actionTypes'

interface UserActionsState {
  showPortfolioDetail: boolean
  valid: boolean
  errors: Record<string, boolean>
}

const INITIAL_STATE = {
  showPortfolioDetail: false,
  valid: true,
  errors: {},
}

export const useractions = (state: UserActionsState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case SHOW_PORTFOLIO_DETAIL:
      return {
        ...state,
        showPortfolioDetail: action.payload,
      }
    case IS_FORM_VALID:
      return {
        ...state,
        valid: action.payload,
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return state
  }
}
