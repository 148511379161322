import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { newThemeObject } from '../../newTheme'
import { Label } from '../../redux/reducers/label'
import CardWrapper from '../common/CardWrapper'
import { TransparentButton } from '../common/TransparentButton'

import LabelIcon from '@mui/icons-material/Label'
import BasicMenuLabel from '../BasicMenu/BasicMenuLabel'

const useStyles = makeStyles(theme => ({
  title: {
    color: newThemeObject.palette.main.primary,
    fontWeight: 600,
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
  buttonActive: {
    backgroundColor: '#fff',
  },
  wrapper: {
    width: '100%',
    backgroundColor: newThemeObject.palette.highlight.bg,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  buttonWrapper: {
    height: '2rem',
    borderRadius: '1rem',
    padding: 0,
  },
  button: {
    display: 'flex',
    padding: newThemeObject.padding.smallWrapper,
    alignItems: 'center',
    justifyContent: 'center',
    height: '2rem',
    borderRadius: '1rem',
    gap: theme.spacing(0.3),
  },
  buttonText: {
    margin: 0,
    color: 'rgba(0,0,0,0.5)',
    fontSize: '0.6rem',
  },
  buttonTextActive: {
    color: '#000',
  },
  plusSign: {
    display: 'flex',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.6rem',
    width: '1.6rem',
    minWidth: '1.6rem',
    borderRadius: '0.8rem',
    padding: 0,
  },
  plusSignText: {
    margin: 0,
    color: '#000',
    fontSize: '1rem',
  },
  plusSignWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2rem',
    width: '2rem',
    minWidth: '2rem',
    padding: 0,
  },
}))

interface LabelsBarProps {
  currentLabel: number
  setCurrentLabel: React.Dispatch<React.SetStateAction<number>>
  labels: Label[]
  handleMenuClick: (index: number, label: Label | null) => void
  newLabelFunction: () => void
}

const LabelsBar: React.FC<LabelsBarProps> = ({
  currentLabel,
  setCurrentLabel,
  labels,
  handleMenuClick,
  newLabelFunction,
}) => {
  const classes = useStyles()
  const [isHovered, setIsHovered] = useState(-1)
  const { t } = useTranslation()

  return (
    <CardWrapper className={classes.wrapper}>
      <span className={classes.labelWrapper}>
        <TransparentButton
          className={classes.buttonWrapper}
          content={
            <div className={currentLabel === 0 ? classNames(classes.button, classes.buttonActive) : classes.button}>
              <p
                className={
                  currentLabel === 0 ? classNames(classes.buttonText, classes.buttonTextActive) : classes.buttonText
                }
              >
                {t('SUMMARY')}
              </p>
            </div>
          }
          onClick={() => setCurrentLabel(0)}
        />
        {labels.map((label, index) => (
          <TransparentButton
            className={classes.buttonWrapper}
            key={label.labelId}
            content={
              <div
                className={
                  currentLabel === index + 1 ? classNames(classes.button, classes.buttonActive) : classes.button
                }
                onMouseEnter={() => setIsHovered(index + 1)}
                onMouseLeave={() => setIsHovered(-1)}
              >
                <LabelIcon style={{ color: label.color }} />
                <p
                  className={
                    currentLabel === index + 1
                      ? classNames(classes.buttonText, classes.buttonTextActive)
                      : classes.buttonText
                  }
                >
                  {label.name}
                  {isHovered === index + 1 && (
                    <BasicMenuLabel disabled={false} label={label} onMenuItemClick={handleMenuClick} />
                  )}
                </p>
              </div>
            }
            onClick={() => setCurrentLabel(index + 1)}
          />
        ))}
      </span>
      <span className={classes.plusSignWrapper}>
        <TransparentButton
          size="small"
          className={classes.plusSign}
          content={<p className={classes.plusSignText}>+</p>}
          onClick={newLabelFunction}
        />
      </span>
    </CardWrapper>
  )
}

export default LabelsBar
