import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import { makeStyles } from '@mui/styles'
import { Position } from '../../redux/reducers/portfolio'
import { Divider, TableCell, TableRow, Typography } from '@mui/material'
import { newThemeObject } from '../../newTheme'
import { useTranslation } from 'react-i18next'
import { Security } from '../../redux/reducers/securities'
import { TransparentButton } from '../common/TransparentButton'
import { fixAdress } from '../../utils/webpage'
import { hexToRGBA } from '../../utils/styles'
const useStyles = makeStyles(theme => ({
  detailsRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
      background: `linear-gradient(90deg,
            ${hexToRGBA(newThemeObject.palette.main.highlight, 0)} 0%,
            ${hexToRGBA(newThemeObject.palette.main.highlight, 1)} 1%,
            ${hexToRGBA(newThemeObject.palette.main.highlight, 1)} 99%,
            ${hexToRGBA(newThemeObject.palette.main.highlight, 0)} 100%)`,
      height: 0,
    },
  },
  detailsTitle: {
    fontWeight: 600,
  },
  marketInformationTable: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr `,
    gridTemplateRows: '1fr 1px 1fr 1px 1fr',
    columnGap: theme.spacing(6),
  },
  marketInformationTableLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  MITitle: {
    color: newThemeObject.palette.main.sectext,
  },
  MIValue: {
    fontWeight: 600,
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '3fr 4fr 2fr',
    gap: theme.spacing(2),
  },
  comment: {
    color: newThemeObject.palette.main.sectext,
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    maxHeight: '3rem',
    overflowY: 'auto',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
  button: {
    backgroundColor: hexToRGBA(newThemeObject.palette.main.dark, 0.05),
    color: newThemeObject.palette.main.dark,
    height: '2rem',
    borderRadius: '1rem',
    padding: '0rem 1rem',
  },
}))

interface DataTableRowDetailsProps {
  position: Position
  getValidSecurity: (securityId: number) => Security | null
}

const DataTableRowDetails: React.FC<DataTableRowDetailsProps> = ({ position, getValidSecurity }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleDownloadKeyInfo = () => {
    const security = getValidSecurity(position.instrument.instrumentId)
    if (!security) return

    const linkSource = `${security.fileKeyMessageHeader},${security.fileKeyMessageContent}`
    const downloadElement = document.createElement('a')
    const fileName = security.fileKeyMessageName
    downloadElement.href = linkSource
    downloadElement.download = fileName || 'empty_name.pdf'
    downloadElement.click()
  }

  const handleDownloadProspect = () => {
    const security = getValidSecurity(position.instrument.instrumentId)
    if (!security) return

    const linkSource = `${security.fileProspectHeader},${security.fileProspectContent}`
    const downloadElement = document.createElement('a')
    const fileName = security.fileProspectName
    downloadElement.href = linkSource
    downloadElement.download = fileName || 'empty_name.pdf'
    downloadElement.click()
  }

  const security = getValidSecurity(position.instrument.instrumentId)

  return (
    <TableRow className={classes.detailsRow}>
      <TableCell colSpan={14}>
        <div className={classes.wrapper}>
          <span>
            <p className={classes.detailsTitle}>{t('MARKET_INFORMATION').toUpperCase()}</p>
            <div className={classes.marketInformationTable}>
              <div className={classes.marketInformationTableLine}>
                <p className={classes.MITitle}>{t('HORIZONTE')}</p>
                <p className={classes.MIValue}>{security?.horizonteValue}</p>
              </div>
              <div className={classes.marketInformationTableLine}>
                <p className={classes.MITitle}>{t('EXPERIENCE')}</p>
                <p className={classes.MIValue}>{t(security?.skillAndExperience || '')}</p>
              </div>
              <Divider variant="middle" style={{ gridColumn: 'span 2' }} />
              <div className={classes.marketInformationTableLine}>
                <p className={classes.MITitle}>{t('RISK')}</p>
                <p className={classes.MIValue}>{security?.riskValue}</p>
              </div>
              <div className={classes.marketInformationTableLine}>
                <p className={classes.MITitle}>{t('PREFERENCE')}</p>
                <p className={classes.MIValue}>{t(security?.preference || '')}</p>
              </div>
              <Divider variant="middle" style={{ gridColumn: 'span 2' }} />
              <div className={classes.marketInformationTableLine}>
                <p className={classes.MITitle}>{t('CATEGORY')}</p>
                <p className={classes.MIValue}>{t(security?.category || '')}</p>
              </div>

              <div className={classes.marketInformationTableLine}>
                <p className={classes.MITitle}>{t('RISK3')}</p>
                <p className={classes.MIValue}>{t(security?.lossAbility || '')}</p>
              </div>
            </div>
          </span>
          <span>
            <p className={classes.detailsTitle}>{t('SECURITY_COMMENT').toUpperCase()}</p>
            <p className={classes.comment}>{security?.comment}</p>
          </span>
          <span>
            <p className={classes.detailsTitle}>{t('ADDITIONAL_INFORMATION').toUpperCase()}</p>
            <Typography className={classes.comment}>{security?.additionalInformation}</Typography>
          </span>
        </div>
        <div className={classes.buttons}>
          <TransparentButton
            onClick={handleDownloadProspect}
            disabled={!security?.fileProspectHeader && !security?.fileProspectContent}
            startIcon={<DownloadRoundedIcon />}
            className={classes.button}
          >
            {t('DOWNLOAD_PROSPECT')}
          </TransparentButton>

          <TransparentButton
            onClick={handleDownloadKeyInfo}
            disabled={!security?.fileKeyMessageHeader && !security?.fileKeyMessageContent}
            startIcon={<DownloadRoundedIcon />}
            className={classes.button}
          >
            {t('DOWNLOAD_KEY_MESSAGE')}
          </TransparentButton>
          <a href={fixAdress(security?.webpage?.toString())} target="blank">
            <TransparentButton disabled={!security?.webpage} startIcon={<LinkRoundedIcon />} className={classes.button}>
              {t('URL_COMPANY')}
            </TransparentButton>
          </a>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default DataTableRowDetails
