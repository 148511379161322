import { ReduxAction } from '../../types/common'
import { CLOSE_DIALOG, OPEN_DIALOG } from '../actionTypes'

export interface DialogProps {
  id: string
  message?: string
  payload?: any
}

interface DialogsState {
  dialogs: DialogProps[]
}

const INITIAL_STATE = {
  dialogs: [],
}

export const dialogs = (state: DialogsState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case OPEN_DIALOG:
      const foundDialog = state.dialogs.find(dialog => dialog.id === action.payload.id)
      if (foundDialog) {
        return state
      }
      return {
        ...state,
        dialogs: [
          ...state.dialogs,
          { id: action.payload.id, message: action.payload.message, payload: action.payload.payload },
        ],
      }
    case CLOSE_DIALOG:
      return {
        ...state,
        dialogs: state.dialogs.filter(dialog => dialog.id !== action.payload.id),
      }
    default:
      return state
  }
}
