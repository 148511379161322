import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useTranslation } from 'react-i18next'
import { Label } from '../../redux/reducers/label'

interface BasicMenuProps {
  disabled: boolean
  label: Label | null
  onMenuItemClick?: (params: number, label: Label | null) => void
}

export default function BasicMenuLabel(props: BasicMenuProps) {
  const { disabled, onMenuItemClick, label } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = (index: number) => {
    setAnchorEl(null)
    if (onMenuItemClick) onMenuItemClick(index, label)
  }
  const { t } = useTranslation()

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ cursor: 'default', maxWidth: '10px', minWidth: '10px' }}
        disabled={disabled}
      >
        <MoreVertIcon style={disabled ? { color: 'gray', cursor: 'default' } : { cursor: 'pointer' }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose(1)
          }}
        >
          {t('UPDATE')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose(2)
          }}
        >
          {t('DELETE')}
        </MenuItem>
      </Menu>
    </>
  )
}
