import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React from 'react'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  equity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
    fontSize: '0.6rem',
    height: '0.9rem',
    borderRadius: '0.5rem',
    width: '2.4rem',
    fontWeight: '500',
  },
}))

interface Props {
  className?: string
  securityType: string
}

export const InstrumentIcon: React.FC<Props> = ({ className, securityType }) => {
  const classes = useStyles()
  const instrumentIconType = useInstrumentIconType(securityType)
  const { t } = useTranslation()

  return (
    <div title={t(securityType)} className={classNames(classes.equity, className, instrumentIconType)}>
      {securityType === 'HYBRID' ? securityType.substring(0, 3) : securityType}
    </div>
  )
}
