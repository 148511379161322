import { InputBase, Divider, IconButton } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useNoteApi } from '../../hooks/useNoteApi'
import { newThemeObject } from '../../newTheme'
import { useSelector } from '../../redux/hooks/useSelector'
import { Note } from '../../redux/reducers/note'
import CardWrapper from '../common/CardWrapper'
import React from 'react'

import { Delete } from '@mui/icons-material'

const useStyles = makeStyles(theme => ({
  noteWrapper: {
    gap: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr auto',
    maxHeight: theme.spacing(20),
    overflowY: 'auto',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  noteText: {
    margin: 0,
  },
  noteDate: {
    margin: 0,
    color: theme.palette.text.secondary,
  },
  divider: {
    gridColumn: '1 / -1',
  },
}))

interface NotesProps {
  portfolioId: number
}

const Notes = ({ portfolioId }: NotesProps) => {
  const notes = useSelector(state => state.noteState.notes)
  const classes = useStyles()
  const [newNoteContent, setNewNoteContent] = useState('')

  const { getNotes, createNote, deleteNote } = useNoteApi()

  useEffect(() => {
    if (portfolioId) {
      getNotes(portfolioId)
    }
  }, [portfolioId])

  const sendNote = () => {
    if (newNoteContent.trim()) {
      save()
      setNewNoteContent('')
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      sendNote()
    }
  }

  const save = () => {
    let note: Note = {
      noteId: 0,
      date: new Date(),
      content: newNoteContent,
      portfolioId: portfolioId,
    }
    createNote(note)
  }

  const handleDelete = (noteId: number) => {
    deleteNote(noteId)
  }

  return (
    <CardWrapper>
      <div className={classes.noteWrapper}>
        {Array.isArray(notes) &&
          notes.map((note, index) => (
            <React.Fragment key={note.noteId}>
              <p className={classes.noteDate}>
                {`${new Date(note.date).getDate()}.${new Date(note.date).getMonth() + 1}.${new Date(
                  note.date
                ).getFullYear()}`}
              </p>

              <p className={classes.noteDate}>{`${new Date(note.date).getHours()}:${new Date(
                note.date
              ).getMinutes()}`}</p>
              <p className={classes.noteText}>{note.content}</p>
              <IconButton onClick={() => handleDelete(note.noteId)} size="small">
                <Delete />
              </IconButton>
              {index !== notes.length - 1 && <Divider className={classes.divider} />}
            </React.Fragment>
          ))}
      </div>
      <NewNote
        placeholder="Začněte psát svou poznámku"
        value={newNoteContent}
        onChange={e => setNewNoteContent(e.target.value)}
        onKeyPress={handleKeyPress}
      />
    </CardWrapper>
  )
}

export default Notes

const NewNote = styled(InputBase)(({ theme }) => ({
  width: '100%',

  '& .MuiInputBase-input': {
    borderRadius: newThemeObject.roundness.basic,
    position: 'relative',
    backgroundColor: newThemeObject.palette.background.lightGray,
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    fontSize: '0.7rem',
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
  },
}))
