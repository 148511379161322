import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.contrastText,
  },
}))

interface Props {
  onClick?: () => void
  icon?: React.ReactNode
  size?: 'small' | 'medium' | undefined
  className?: string
  disabled?: boolean
}

export const TransparentIconButton: React.FC<Props> = ({ onClick, icon, size, className, disabled = false }) => {
  const classes = useStyles()

  return (
    <IconButton disabled={disabled} className={classNames(classes.root, className)} onClick={onClick} size={size} style={{padding:'0'}}>
      {icon}
    </IconButton>
  )
}
