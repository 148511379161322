import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '5px',
    textAlign: 'center',
    padding: theme.spacing(1),
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  active: {
    border: `2px dashed ${theme.palette.primary.main}`,
  },
}))

interface Props {
  defaultText: string
  className?: string
  onFileDrop?: (acceptedFiles: File[]) => void
}

export const DropZone: React.FC<Props> = ({ defaultText, className, onFileDrop }) => {
  const classes = useStyles()
  const onDrop = useCallback(
    acceptedFiles => {
      onFileDrop && onFileDrop(acceptedFiles)
    },
    [onFileDrop]
  )
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop })

  const rootClasses = classNames(classes.root, { [classes.active]: isDragActive })

  const files = acceptedFiles.map((file: any) => (
    <>
      <p>{file.path}</p>
    </>
  ))

  return (
    <div className={classNames(rootClasses, className)} {...getRootProps()}>
      <input {...getInputProps()} />
      {files.length === 0 && <p>{defaultText}</p>}
      <div>{files}</div>
    </div>
  )
}
