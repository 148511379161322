import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getRatesRequest } from '../api/currencyApi'
import { getRatesAction } from '../redux/actions/currencyActions'
import { Rate } from '../redux/reducers/currency'

export const useCurrencyApi = () => {
  const dispatch = useDispatch()

  const getRates = useCallback(async (currency: string) => {
    await getRatesRequest(currency)
      .then(response => {
        console.log('gotRates', response.data)
        dispatch(getRatesAction(response.data as Rate[]))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getRates,
  }
}
