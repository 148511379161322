import { InputAdornment, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { TransparentIconButton } from '../common/TransparentIconButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  color: {
    color: theme.palette.primary.main,
  },
}))

interface OptionItem {
  label: string
  value: string | number
}

interface Props {
  value: any
  onChange: (value: string | number) => void
  options: OptionItem[]
  label: string
  disabled?: boolean
}

export const ArrowSelect: React.FC<Props> = ({ onChange, value, options, label, disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const onChangeHandler = (direction: -1 | 1) => () => {
    const currentIndex = options.map(o => t(o.value as string)).indexOf(value)
    let nextIndex = currentIndex + direction
    if (nextIndex < 0) {
      nextIndex = options.length - 1
    }
    if (nextIndex > options.length - 1) {
      nextIndex = 0
    }
    onChange(options[nextIndex].value)
  }

  return (
    <TextField
      disabled={disabled}
      label={label}
      fullWidth={true}
      variant="standard"
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <TransparentIconButton
              className={classes.color}
              size="small"
              onClick={onChangeHandler(-1)}
              icon={<ArrowLeftIcon />}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <TransparentIconButton
              className={classes.color}
              size="small"
              onClick={onChangeHandler(1)}
              icon={<ArrowRightIcon />}
            />
          </InputAdornment>
        ),
        readOnly: false,
      }}
    ></TextField>
  )
}
