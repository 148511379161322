import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import classNames from 'classnames'
import { newThemeObject } from '../../newTheme'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: newThemeObject.palette.main.sectext,
    },
  },
}))

interface Props {
  onClick?: () => void
  icon?: React.ReactNode
  size?: 'small' | 'medium' | undefined
  className?: string
  disabled?: boolean
}

export const ColoredIconButton: React.FC<Props> = ({ onClick, icon, size, className, disabled }) => {
  const classes = useStyles()

  return (
    <IconButton
      className={classNames(classes.root, className)}
      onClick={onClick}
      disabled={disabled ? disabled : false}
      size={size}
    >
      {icon}
    </IconButton>
  )
}
