import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getInvestmentsRequest, postInvestmentRequest } from '../api/investmentApi'
import { getInvestmentAction } from '../redux/actions/investmentActions'
import { InvestmentBody } from '../redux/reducers/investment'
import { REQUEST_GET_INVESTMENTS } from '../redux/actionTypes'

export const useInvestmentApi = () => {
  const dispatch = useDispatch()

  const getInvestments = useCallback(async (clientId: Number) => {
    dispatch({type: REQUEST_GET_INVESTMENTS})
    await getInvestmentsRequest(clientId)
      .then(response => {
        dispatch(getInvestmentAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const saveInvestment = useCallback(async (clientId: number, investment: InvestmentBody) => {
    await postInvestmentRequest(clientId, investment)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getInvestments,
    saveInvestment,
  }
}
