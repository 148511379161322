import React from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { TransparentIconButton } from './TransparentIconButton'
import { useSelector } from '../../redux/hooks/useSelector'
import { logout } from '../../services/keycloak.service'
import store from '../../redux/store'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  userLogo: {
    height: '30px',
  },
  connectionStatus: {},
  activeUser: {
    marginRight: theme.spacing(8),
  },
  separator: {
    flexGrow: 1,
  },
  badge: {
    marginRight: theme.spacing(8),
  },
}))

interface Props {
  appBarContent?: React.ReactNode
}

export const TopBar: React.FC<Props> = ({ appBarContent = <div></div> }) => {
  const classes = useStyles()
  const user = useSelector(state => state.auth.user)
  const { t } = useTranslation()

  return (
    <AppBar color="secondary" position="static" className={classes.root}>
      <Toolbar style={{ paddingLeft: 5 }}>
        {appBarContent}
        <div title={t('LOG_OUT')}>
          <TransparentIconButton
            onClick={() => {
              logout(store)
            }}
            icon={<ExitToAppIcon />}
          />
        </div>
        <Typography className={classes.activeUser}>{user?.userName}</Typography>
      </Toolbar>
    </AppBar>
  )
}
