import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  createLabelRequest,
  deleteLabelRequest,
  getLabelsRequest,
  updateLabelRequest,
  assignLabelRequest,
} from '../api/labelApi'
import { getLabelsAction, createLabelAction, updateLabelAction } from '../redux/actions/labelActions'
import { Label } from '../redux/reducers/label'

export const useLabelApi = () => {
  const dispatch = useDispatch()

  const getLabels = useCallback(async (portfolioId: Number | null) => {
    await getLabelsRequest(portfolioId)
      .then(response => {
        dispatch(getLabelsAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const createLabel = useCallback(async (newLabel: Label | null, existLabels: Label[]) => {
    let tmp = [...existLabels]
    await createLabelRequest(newLabel)
      .then(response => {
        tmp.push(response.data as Label)
        dispatch(getLabelsAction(tmp))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const updateLabel = useCallback(async (label: Label | null, existLabels: Label[]) => {
    let tmp = [...existLabels]
    await updateLabelRequest(label)
      .then(response => {
        for (let i = 0; i < tmp.length; i++) {
          if (tmp[i].labelId === response.data.labelId) {
            tmp[i] = response.data
          }
        }
        dispatch(getLabelsAction(tmp))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const deleteLabel = useCallback(async (labelId: number | null) => {
    await deleteLabelRequest(labelId)
      .then(response => {
        dispatch(getLabelsAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const assignLabel = useCallback(async (portfolioId: number, securityId: number, labels: Label[]) => {
    await assignLabelRequest(portfolioId, securityId, labels)
      .then(response => {
        dispatch(getLabelsAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getLabels,
    createLabel,
    updateLabel,
    deleteLabel,
    assignLabel,
  }
}
