import makeStyles from '@mui/styles/makeStyles'

const useStyles: any = makeStyles(theme => ({
  grey: {
    backgroundColor: theme.palette.grey[50],
  },
  white: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export const useZebraStyle = () => {
  const classes = useStyles()

  return (index: number) => {
    if (index % 2 === 0) {
      return classes.grey
    }
    return classes.white
  }
}
