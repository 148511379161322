import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationCS from './translation/locales/cs/translation.json'

import { cs } from 'date-fns/esm/locale'

const resources = {
  cs: {
    translation: translationCS,
  },
}

const availableLanguages = ['cs', 'en']

i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'cs', // set language
    fallbackLng: 'cs',
    whitelist: availableLanguages,

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18next
