import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  clientInformationDialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
  commodityContainer: {
    height: '100px',
    width: '550px',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  addClientContainer: {
    height: '100px',
    width: '550px',
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  massOrderTotals: {
    height: '100px',
    width: '550px',
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  addClientButton: {
    height: '70px',
    width: '70px',
  },
  topContainer: {
    width: '100%',
    height: '50%',
    display: 'flex',
    flexDirection: 'row',
  },
  iconSize: {
    height: '25px',
    width: '45px',
    lineHeight: '25px',
    fontSize: '12px',
  },
  position: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  topLineText: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  boldText: {
    fontWeight: 'bold',
  },
  smallFont: {
    fontSize: '10px',
  },
  smallFontRed: {
    fontSize: '10px',
    color: 'red',
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  topLineSeparator: {
    flexGrow: 1,
  },
  topLineIsin: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    width: '100%',
    height: '50%',
    alignItems: 'flex-end',
  },
  securityInfo: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  percentage: {
    color: theme.palette.success.main,
  },
  selectionContainer: {
    width: '550px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  paperAndClearContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  clientContainer: {
    height: '255px',
    width: '550px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  clientContainerError: {
    height: '280px',
    width: '550px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  clientContainer2: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  clientIcon: {
    height: '40px',
    width: '40px',
  },
  clientCredentialsContainer: {
    height: '55%',
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  clientCredentialsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  clientCredentials: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  portfolioTotalsContainer: {
    height: '100%',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  amountInputContainer: {
    height: '50%',
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  textSeparatorLeft: {
    marginLeft: theme.spacing(1),
  },
  massOrderClientsContainer: {
    height: '60vh',
    maxHeight: '60vh',
    overflow: 'auto',
  },
  clientOrderTotalContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  autoCompleteField: {
    margin: theme.spacing(1),
  },
  clearButton: {
    height: '5px',
    width: '5px',
  },
  actionButtons: {
    width: '10%',
  },
  actionButtonsApex: {
    width: '20%',
  },
  totalsContainer: {
    height: '100%',
    width: '100%',
  },
  totalsLabels: {
    display: 'flex',
    flexDirection: 'row',
    height: '25%',
  },

  totalsLabel: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    width: '50%',
  },
  totalsNumbers: {
    display: 'flex',
    flexDirection: 'row',
    height: '75%',
  },
  totalsNumber: {
    fontSize: '20px',
    marginLeft: theme.spacing(2),
    maxWidth: '47%',
    flexGrow: 1,
    alignContent: 'center',
    overflowWrap: 'break-word',
  },
  dropZoneBorder: {
    border: `1px dashed ${theme.palette.grey[500]}`,
  },
  currencySelection: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    width: '60px',
  },
  portfolioSelection: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    width: '200px',
  },
  isin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexRow90: {
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    flexGrow: 1,
  },
  centerContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftContent: {
    justifyContent: 'left',
    alignItems: 'left',
    paddingLeft: '10px',
    flexGrow: 1,
  },
  fullwidth: {
    width: '100%',
  },
}))
