import {
  ADD_CLIENT,
  DEACTIVATE_CLIENT,
  GET_CLIENT,
  GET_CLIENT_ACCOUNT_TOTAL,
  GET_CLIENT_ROLES,
  GET_CLIENTS,
  UPDATE_CLIENT,
  GET_CLIENTS_BEFORE,
  GET_CLIENTS_AFTER,
} from '../actionTypes'
import { Client, ClientRole } from '../reducers/clients'

export const getClientsAction = (clients: Client[]) => ({ type: GET_CLIENTS, payload: clients })
export const getClientsActionBefore = () => ({ type: GET_CLIENTS_BEFORE })
export const getClientsActionAfter = () => ({ type: GET_CLIENTS_AFTER })

export const getClientAction = (client: Client) => ({ type: GET_CLIENT, payload: client })
export const addClientAction = (client: Client) => ({ type: ADD_CLIENT, payload: client })
export const updateClientAction = (client: Client) => ({ type: UPDATE_CLIENT, payload: client })
export const deactivateClientAction = (client: Client) => ({ type: DEACTIVATE_CLIENT, payload: client })
export const getClientRolesAction = (clientRoles: ClientRole[]) => ({ type: GET_CLIENT_ROLES, payload: clientRoles })
export const getClientAccountTotalAction = (clientAccountTotal: Number) => ({
  type: GET_CLIENT_ACCOUNT_TOTAL,
  payload: clientAccountTotal,
})
