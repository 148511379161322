import React, { useLayoutEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { REMOVE_LABEL_DIALOG_ID } from '../../constants/dialogConstants'
import { closeDialog } from '../../redux/actions/dialogActions'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useLabelApi } from '../../hooks/useLabelApi'
import { Label } from '../../redux/reducers/label'

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
}))

interface LabelDialogProps {
  name: string | null
  labelId: number | null
}

export const RemoveLabelDialog: React.FC<LabelDialogProps> = ({ name, labelId }) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const foundDialog = dialogs.find(dialog => dialog.id === REMOVE_LABEL_DIALOG_ID)
  const open = !!foundDialog
  const { t } = useTranslation()
  const { deleteLabel } = useLabelApi()

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(REMOVE_LABEL_DIALOG_ID))
    }
  }, [])

  const handleSubmit = () => {
    deleteLabel(labelId)
    dispatch(closeDialog(REMOVE_LABEL_DIALOG_ID))
  }

  const handleClose = () => {
    dispatch(closeDialog(REMOVE_LABEL_DIALOG_ID))
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
          {t('REMOVE_LABEL_DIALOG_TITLE')}
        </DialogTitle>

        <DialogContent style={{ height: '120px', padding: '40px', display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body1">{t('REMOVE_LABEL_DIALOG_MESSAGE', { name: name })} </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
            {t('STORNO')}
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="primary" autoFocus>
            {t('CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
