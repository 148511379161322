import React from 'react'
import NumberFormat from 'react-number-format'
import TextField, { TextFieldProps } from '@mui/material/TextField'

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props: NumberFormatCustomProps, ref) {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
      format="00### ### ### ###"
      mask="_"
      allowEmptyFormatting
    />
  )
})

export const PhoneNumberInput: React.FC<TextFieldProps> = props => {
  return (
    <div>
      <TextField
        variant="standard"
        {...props}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
      />
    </div>
  )
}
