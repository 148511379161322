import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Button,
  createFilterOptions,
  Checkbox,
  FormControlLabel,
  Alert,
  CircularProgress,
} from '@mui/material'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../redux/actions/dialogActions'
import { ACTION_FAILED_ALERT_ID, MASS_ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import { ArrowSelect } from '../OrderInputDialog/ArrowSelect'
import { codeListToArrowSelect } from '../../utils/codeListToArrowSelect'
import {
  HORIZONTE_VALUE_MAX,
  ORDER_DIRECTION,
  ORDER_MARKET_TYPE,
  ORDER_MARKET_TYPE_LIMIT,
  ORDER_MARKET_TYPE_MARKET,
  ORDER_STATE_NEW,
  ORDER_TYPE,
  ORDER_TYPE_AMOUNT,
  ORDER_TYPE_QUANTITY,
  ORDER_VALIDITY,
  ORDER_VALIDITY_DATE,
  RISK_VALUE_MAX,
} from '../../constants/codeLists'
import { Order } from '../../redux/reducers/orders'
import { newOrder } from '../../constants/initialStates'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import Autocomplete from '@mui/material/Autocomplete'
import { MassOrderClient } from '../../redux/reducers/clients'
import manS from '../../assets/manS.jpg'
import { Security } from '../../redux/reducers/securities'
import { TransparentIconButton } from '../common/TransparentIconButton'
import ClearIcon from '@mui/icons-material/Clear'
import { ClearConfirmationAlert } from '../massOrderInputDialog/ClearConfirmationAlert'
import NumberFormat from 'react-number-format'
import { NumericInput } from '../common/NumericInput'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@date-io/date-fns'
import { ActionFailedAlert } from './ActionFailedAlert'
import { useCurrencyApi } from '../../hooks/useCurrencyApi'
import { useInputDialogColor } from '../../hooks/useInputDialogColor'
import { useMassOrderCoveredColor } from '../../hooks/useMassOrderCoveredColor'
import { useTranslation } from 'react-i18next'
import { useStyles } from './MassOrderInputDialog.styles'
import { useExchangeRate } from '../../hooks/useExchangeRate'
import { ActionAlert } from './ActionAlert'
import { useZebraStyle } from '../../hooks/useZebraStyle'
import { MessageAlert } from './MessageAlert'
import {
  categorySecValidation,
  lossAbilitySecValidation,
  preferenceSecValidation,
  skillAndExperienceSecValidation,
} from '../../utils/orderValidations'
import { useClientApi } from '../../hooks/useClientApi'
import { APEX } from '../../constants/businessTerritory'

// TODO utils
const clientFilter = createFilterOptions({
  limit: 50, //TODO virtualize component
  matchFrom: 'any',
  stringify: (option: MassOrderClient) =>
    `${option.surname} ${option.firstName} ${option.surname} ${option.telephoneNumber} ${option.personalNumber}`,
})

const securityFilter = createFilterOptions({
  limit: 50, //TODO virtualize component
  matchFrom: 'any',
  stringify: (option: Security) => `${option.name} ${option.isin} ${option.ticker}`,
})

export const MassOrderInputDialog = () => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const orderCoveredColor = useMassOrderCoveredColor()
  const getInputDialogColor = useInputDialogColor()
  const { getRates } = useCurrencyApi()
  const { postMassOrder, getCodeList, codeList } = useInstrumentApi()
  const { getSecurities, getOrdersByStateCount } = useInstrumentApi()
  const { getClients } = useClientApi()

  const foundDialog = dialogs.find(dialog => dialog.id === 'MASS_ORDER_INPUT_DIALOG_ID')
  const open = !!foundDialog
  const clients = useSelector(state => state.clients.clients)
  const [order, setOrder] = useState<Order>(newOrder)
  const [massOrderClients, setMassOrderClients] = useState<MassOrderClient[]>([])
  const [autoClientInputValue, setAutoClientInputValue] = useState('')
  const [autoHack, setAutoHack] = useState<boolean>(true)
  const [massOrderSecurity, setMassOrderSecurity] = useState<Security | null>()
  const [autoSecurityValue, setAutoSecurityValue] = useState('')
  const securities = useSelector(state => state.securities.securities)
  const instrumentIconType = useInstrumentIconType(!massOrderSecurity ? null : massOrderSecurity.assetClass)
  const [securityAlertOpen, setSecurityAlertOpen] = useState(false)
  const [orderAlertOpen, setOrderAlertOpen] = useState(false)
  const [alertMessageOpen, setAlertMessageOpen] = useState(false)
  const [alertMessage2Open, setAlertMessage2Open] = useState(false)
  const [alertMessage3Open, setAlertMessage3Open] = useState(false)
  const rates = useSelector(state => state.currency.rates)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [submitDisabledID, setSubmitDisabledID] = useState(false)
  const exchangeRate = useExchangeRate()
  const getBackgroundColor = useZebraStyle()
  const [clientIDNotSet, setClientIDNotSet] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isSavingFromAlert, setIsSavingFromAlert] = useState(false)
  const user = useSelector(state => state.auth.user)
  const [sendToFix, setFixValue] = useState(true)

  const handleCheckbox = () => {
    setFixValue(!sendToFix)
  }

  useEffect(()=>{
    if ( user ) {
      setFixValue(user.businessTerritoryId===APEX?false:true)
    }
  }, user)

  useEffect(() => {
    getCodeList()
    getSecurities()
    getRates('CZK')
  }, [])

  useEffect(() => {
    if ( !clients || clients.length==0) {
      getClients()
    }
  }, [clients])

  useEffect(() => {
    if (open) {
      setMassOrderSecurity(undefined)
      setMassOrderClients([])
      setOrder(newOrder)
    } else {
      setIsSaving(false)
      setIsSavingFromAlert(false)
    }
  }, [open])

  useEffect(() => {
    if (!massOrderSecurity) {
      setOrder({ ...order, securityId: 0 })
    } else {
      setOrder({ ...order, securityId: massOrderSecurity.securityId, price: massOrderSecurity.lastQuotePrice })

      setMassOrderClients(
        massOrderClients.map((c: MassOrderClient) => {
          return {
            ...c,
            categorySec: massOrderSecurity.category,
            isCategorySec: !categorySecValidation(c.category, massOrderSecurity.category),
            skillAndExperienceSec: massOrderSecurity.skillAndExperience,
            isSkillAndExperienceSec: !skillAndExperienceSecValidation(
              c.skillAndExperience,
              massOrderSecurity.skillAndExperience
            ),
            preferenceSec: massOrderSecurity.preference,
            isPreferenceSec: !preferenceSecValidation(c.preference, massOrderSecurity.preference),
            lossAbilitySec: massOrderSecurity.lossAbility,
            isLossAbilitySec: !lossAbilitySecValidation(c.lossAbility, massOrderSecurity.lossAbility),
            isID:
              !categorySecValidation(c.category, massOrderSecurity.category) ||
              !skillAndExperienceSecValidation(c.skillAndExperience, massOrderSecurity.skillAndExperience) ||
              !preferenceSecValidation(c.preference, massOrderSecurity.preference) ||
              !lossAbilitySecValidation(c.lossAbility, massOrderSecurity.lossAbility),
          }
        })
      )
    }
  }, [massOrderSecurity])

  useEffect(() => {
    submitDisabledSet()
  }, [massOrderClients])

  useEffect(() => {
    submitDisabledSet()
  }, [order.direction])

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(MASS_ORDER_INPUT_DIALOG_ID))
    }
  }, [])

  const handleClose = () => {
    dispatch(closeDialog(MASS_ORDER_INPUT_DIALOG_ID))
  }

  const handleCloseMessage = () => {
    setAlertMessageOpen(false)
    setIsSaving(false)
    setIsSavingFromAlert(false)
  }

  const handleCloseMessage2 = () => {
    setAlertMessage2Open(false)
  }

  const handleCloseMessage3 = () => {
    setAlertMessage3Open(false)
  }

  const handleCloseAlert = () => {
    setIsSaving(false)
    setIsSavingFromAlert(false)
  }

  const handleSave = () => {
    setIsSaving(true)
    if (submitDisabledID) {
      for (let i = 0; i < massOrderClients.length; i++) {
        if (!massOrderClients[i].orderComment && massOrderClients[i].isID) {
          setAlertMessageOpen(true)
          return
        }
      }

      for (let i = 0; i < massOrderClients.length; i++) {
        if (sendToFix && (!massOrderClients[i].clientExternalId || !massOrderClients[i].portfolios[0].externalId)) {
          setAlertMessage3Open(true)
          return
        }
      }

      setOrderAlertOpen(true)
    } else if (clientIDNotSet) {
      setAlertMessage2Open(true)
    } else {
      saveOrder()
    }
  }

  const saveOrder = () => {
    const mappedOrders = massOrderClients.map((client: MassOrderClient) => {
      const newOrder: Order = {
        ...order,
        amount: client.massOrderByAmount,
        quantity: client.massOrderByQuantity,
        portfolio: {
          portfolioId: client.selectedPortfolio.portfolioId,
          currency: client.selectedPortfolio.currency,
          name: null,
          firstIndicatorDate: null,
          lastIndicatorDate: null,
        },
        currency: client.massOrderCurrency,
        state: ORDER_STATE_NEW,
        validityDate: order.validityDate,
        cpty: {
          ...order.cpty,
          marketName: massOrderSecurity?.market || '',
          name: `${order.cpty.brokerName} - ${massOrderSecurity?.market}`,
        },
        orderComment: client.orderComment,
        sendToFix: sendToFix,
        settleDirectly: user && user.businessTerritoryId===APEX?true:false
      }
      return newOrder
    })
    postMassOrder(mappedOrders)
  }

  const handleChangeOrder = (name: string) => (value: string | number) => {
    setOrder({ ...order, [name]: value })
  }

  const handleDateChange = (date: Date | null) => {
    setOrder({ ...order, validityDate: date })
  }

  const changeLimitPrice = (event: any) => {
    setOrder({ ...order, price: event.target.value })
  }

  const onClientInputChange = (client: MassOrderClient) => (event: any) => {
    if (!massOrderSecurity) return
    let quantity = 0
    let amount = 0
    if (order.type === ORDER_TYPE_QUANTITY) {
      if (client.massOrderCurrency === massOrderSecurity?.currency) {
        quantity = Number(event.target.value)
        amount = Math.round(((Number(quantity) || 0) * (order.price || 0) + Number.EPSILON) * 100) / 100
      } else {
        quantity = Number(event.target.value)
        amount = exchangeRate(
          massOrderSecurity?.currency,
          client.massOrderCurrency,
          Math.round(((Number(quantity) || 0) * (order.price || 0) + Number.EPSILON) * 100) / 100,
          rates
        )
      }
    }
    if (order.type === ORDER_TYPE_AMOUNT) {
      if (client.massOrderCurrency === massOrderSecurity?.currency) {
        amount = Number(event.target.value)
        quantity = Math.round(((Number(amount) || 0) / (order?.price || 0) + Number.EPSILON) * 1000000) / 1000000
      } else {
        amount = Number(event.target.value)
        quantity =
          Math.round(
            ((Number(exchangeRate(client.massOrderCurrency, massOrderSecurity?.currency, amount, rates)) || 0) /
              (order?.price || 0) +
              Number.EPSILON) *
              1000000
          ) / 1000000
      }
    }
    if (order.direction === 'OD_SELL') {
      let riskValueCalc: number = 0
      let horizonteValueCalc: number = 0
      let isRiskValue: boolean = true
      let isHorizontValue: boolean = true

      if (amount != null) {
        let amountPortfolioCurr: number = exchangeRate(
          client.selectedPortfolio.currency,
          client.massOrderCurrency,
          client.selectedPortfolio.amount || 0,
          rates
        )
        let riskValueSec = massOrderSecurity.riskValue ? massOrderSecurity.riskValue : RISK_VALUE_MAX
        let horizonteValueSec = massOrderSecurity.horizonteValue
          ? massOrderSecurity.horizonteValue
          : HORIZONTE_VALUE_MAX

        if (client.selectedPortfolio.riskValue != null) {
          // risk value = ( ( objem portfolia * risk portfolioa - objem pokynu * risk security ) / ( objem portfolia - objem pokynu) )
          riskValueCalc =
            (amountPortfolioCurr * client.selectedPortfolio.riskValue - amount * riskValueSec) /
            (amountPortfolioCurr - amount)
          isRiskValue = client.riskProfile === null || riskValueCalc < client.riskProfile
        }

        if (client.selectedPortfolio.horizonteValue != null) {
          // risk value = ( ( objem portfolia * risk portfolioa - objem pokynu * risk security ) / ( objem portfolia - objem pokynu ) )
          horizonteValueCalc =
            (amountPortfolioCurr * client.selectedPortfolio.horizonteValue - amount * horizonteValueSec) /
            (amountPortfolioCurr - amount)
          isHorizontValue =
            client.investmentHorizonte === null ||
            horizonteValueCalc < (client.investmentHorizonte as unknown as number)
        }
      }

      setMassOrderClients(
        massOrderClients.map((c: MassOrderClient) => {
          if (c.clientId === client.clientId) {
            return {
              ...c,
              massOrderByAmount: amount,
              massOrderByQuantity: quantity,
              coverPercentage: 100,
              isCovered: true,
              riskValue: riskValueCalc,
              horizonteValue: horizonteValueCalc,
              isID: !true,
              isRiskValue: true,
              isHorizonteValue: true,
              isRiskValueSec: true,
              isHorizonteValueSec: true,
            }
          }
          return c
        })
      )
    } else {
      let coverPercentageCalc: number = 0
      let riskValueCalc: number = 0
      let horizonteValueCalc: number = 0
      let currencyBalance: number = exchangeRate(
        client.selectedPortfolio.currency,
        client.massOrderCurrency,
        client.selectedPortfolio.accountsBalance || 0,
        rates
      )
      let isRiskValue: boolean = true
      let isRiskValueSec: boolean = true
      let isHorizontValue: boolean = true
      let isHorizontValueSec: boolean = true

      if (amount != null) {
        // kryti = ( 1 - ( objem pokynu - zustatek ) / objem pokynu ) * 100
        coverPercentageCalc = (1 - (amount - currencyBalance) / amount) * 100
        let amountPortfolioCurr: number = exchangeRate(
          client.selectedPortfolio.currency,
          client.massOrderCurrency,
          client.selectedPortfolio.amount || 0,
          rates
        )
        let riskValueSec = massOrderSecurity.riskValue ? massOrderSecurity.riskValue : RISK_VALUE_MAX
        let horizonteValueSec = massOrderSecurity.horizonteValue
          ? massOrderSecurity.horizonteValue
          : HORIZONTE_VALUE_MAX

        if (client.selectedPortfolio.riskValue != null) {
          // risk value = ( ( objem pokynu * risk security + objem portfolia * risk portfolioa ) / ( objem pokynu + objem portfolia ) )
          riskValueCalc =
            (amount * riskValueSec + amountPortfolioCurr * client.selectedPortfolio.riskValue) /
            (amount + amountPortfolioCurr)
          isRiskValue = client.riskProfile === null || riskValueCalc <= client.riskProfile
          isRiskValueSec = client.riskProfile === null || riskValueSec <= client.riskProfile
        }

        if (client.selectedPortfolio.horizonteValue != null) {
          // risk value = ( ( objem pokynu * risk security + objem portfolia * risk portfolioa ) / ( objem pokynu + objem portfolia ) )
          horizonteValueCalc =
            (amount * horizonteValueSec + amountPortfolioCurr * client.selectedPortfolio.horizonteValue) /
            (amount + amountPortfolioCurr)
          // isHorizontValue = client.investmentHorizonte === null || horizonteValueCalc < ( client.investmentHorizonte as unknown as number );
          // isHorizontValueSec = client.investmentHorizonte === null || horizonteValueCalc < horizonteValueSec;

          isHorizontValue =
            client.investmentHorizonte === null ||
            horizonteValueCalc <= (client.investmentHorizonte as unknown as number)
          isHorizontValueSec =
            client.investmentHorizonte === null ||
            horizonteValueSec <= (client.investmentHorizonte as unknown as number)
        }
      }

      setMassOrderClients(
        massOrderClients.map((c: MassOrderClient) => {
          if (c.clientId === client.clientId) {
            return {
              ...c,
              massOrderByAmount: amount,
              massOrderByQuantity: quantity,
              coverPercentage: coverPercentageCalc,
              isCovered: coverPercentageCalc >= 100,
              riskValue: riskValueCalc,
              horizonteValue: horizonteValueCalc,
              isID: !(
                isRiskValue &&
                isHorizontValue &&
                c.isCategorySec &&
                c.isSkillAndExperienceSec &&
                c.isPreferenceSec &&
                c.isLossAbilitySec
              ),
              isRiskValue: isRiskValue,
              isHorizonteValue: isHorizontValue,
              isRiskValueSec: isRiskValueSec,
              isHorizonteValueSec: isHorizontValueSec,
            }
          }
          return c
        })
      )
    }
  }

  const cover = (coverPercentage: number) => {
    if (order.direction === 'OD_SELL') {
      return 100
    } else if (coverPercentage < 100 && coverPercentage > 0) {
      return coverPercentage
    } else if (coverPercentage >= 100) {
      return 100
    } else if (coverPercentage < 0) {
      return 0
    }
  }

  const submitDisabledSet = () => {
    setSubmitDisabledID(false)
    if (order.direction === 'OD_SELL') {
      setSubmitDisabled(false)
    } else if (massOrderClients.length === 0) {
      return true
    } else {
      massOrderClients.forEach((c: MassOrderClient) => {
        switch (c.isCovered) {
          case false:
            setSubmitDisabled(true)
            break
          default:
            setSubmitDisabled(false)
        }
      })
    }
    massOrderClients.forEach((c: MassOrderClient) => {
      switch (order.direction !== 'OD_BUY' || !c.isID) {
        case false:
          setSubmitDisabledID(true)
          break
      }

      if (sendToFix && (!c.clientExternalId || !c.portfolios[0].externalId)) {
        setSubmitDisabledID(true)
      }

      if (
        c.riskProfile === null ||
        c.riskProfile === undefined ||
        c.investmentHorizonte === null ||
        c.investmentHorizonte === undefined ||
        c.category === null ||
        c.category === undefined ||
        c.skillAndExperience === null ||
        c.skillAndExperience === undefined ||
        c.preference === null ||
        c.preference === undefined ||
        c.lossAbility === null ||
        c.lossAbility === undefined
      ) {
        setClientIDNotSet(true)
      } else {
        setClientIDNotSet(false)
      }
    })
  }

  const onPortfolioChange = (client: MassOrderClient) => (event: any) => {
    setMassOrderClients(
      massOrderClients.map((c: MassOrderClient) => {
        const portfolio = client.portfolios.find(a => a.name === event.target.value)
        if (portfolio) {
          if (c.clientId === client.clientId) {
            return { ...c, selectedPortfolio: portfolio }
          }
        }
        return c
      })
    )
  }

  const onClientCurrencyChange = (client: MassOrderClient) => (event: any) => {
    if (!massOrderSecurity) return
    setMassOrderClients(
      massOrderClients.map((c: MassOrderClient) => {
        if (c.clientId === client.clientId) {
          return {
            ...c,
            massOrderCurrency: event.target.value,
            massOrderByAmount: c.exchangedFromTo
              ? exchangeRate(massOrderSecurity.currency, event.target.value, c.massOrderByAmount || 0, rates)
              : exchangeRate(c.massOrderCurrency, event.target.value, c.massOrderByAmount || 0, rates),
            exchangedFromTo: !c.exchangedFromTo,
            categorySec: massOrderSecurity.category,
            skillAndExperienceSec: massOrderSecurity.skillAndExperience,
            preferenceSec: massOrderSecurity.preference,
            lossAbilitySec: massOrderSecurity.lossAbility,
          }
        }
        return c
      })
    )
  }

  const onClientCommentChange = (client: MassOrderClient) => (event: any) => {
    setMassOrderClients(
      massOrderClients.map((c: MassOrderClient) => {
        if (c.clientId === client.clientId) {
          return {
            ...c,
            orderComment: event.target.value,
          }
        }
        return c
      })
    )
  }

  const onClientClearClick = (client: MassOrderClient) => () => {
    setMassOrderClients(massOrderClients.filter(c => c.clientId !== client.clientId))
  }

  const onSecurityClearClick = () => {
    setSecurityAlertOpen(true)
  }

  const clearSecurity = () => {
    setMassOrderSecurity(undefined)
    setSecurityAlertOpen(false)
    setMassOrderClients(
      massOrderClients.map((c: MassOrderClient) => {
        return {
          ...c,
          massOrderCurrency: null,
          massOrderByAmount: 0,
          massOrderByQuantity: 0,
          categorySec: null,
          skillAndExperienceSec: null,
          preferenceSec: null,
          lossAbilitySec: null,
          riskValue: null,
          horizonteValue: null,
          isCategorySec: true,
          isSkillAndExperienceSec: true,
          isPreferenceSec: true,
          isLossAbilitySec: true,
          isRiskValue: true,
          isHorizonteValue: true,
          isRiskValueSec: true,
          isHorizonteValueSec: true,
          orderComment: null,
        }
      })
    )
    setOrder({ ...order, price: 0, securityId: null })
  }

  const clearSecurityCancel = () => {
    setSecurityAlertOpen(false)
  }

  const orderAlertOk = () => {
    setIsSavingFromAlert(true)
    setOrderAlertOpen(false)
    saveOrder()
  }
  const orderAlertCancel = () => {
    setOrderAlertOpen(false)
    setIsSavingFromAlert(false)
  }

  const totalQuantity = () => {
    let quantity = 0
    massOrderClients.map((c: MassOrderClient) => {
      quantity = quantity + (c.massOrderByQuantity || 0)
    })
    return quantity
  }

  const totalAmount = () => {
    if (!massOrderSecurity) return
    let amount = 0
    massOrderClients.map((c: MassOrderClient) => {
      amount = amount + exchangeRate(c.massOrderCurrency, massOrderSecurity?.currency, c.massOrderByAmount || 0, rates)
    })
    return amount
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <div className={`${classes.clientInformationDialogHeader} ${getInputDialogColor(order.direction)}`}>
          <Typography variant="h5">{t('NEW_MASS_ORDER')}</Typography>
        </div>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className={classes.paperAndClearContainer}>
                <Paper className={classes.commodityContainer}>
                  {!massOrderSecurity ? (
                    <Autocomplete
                      getOptionLabel={(a: Security) => a.name}
                      className={classes.autoCompleteField}
                      onBlur={() => setAutoSecurityValue('')}
                      disableClearable
                      disableListWrap
                      options={securities}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          className={`${classes.flexColumn} ${classes.fullwidth}`}
                          key={option.securityId + option.currency}
                        >
                          <span className={classes.boldText}>{option.name}</span>
                          <div className={`${classes.flexRow} ${classes.fullwidth}`}>
                            <span>{option.isin}</span>
                            <span className={classes.topLineSeparator} />
                            <span>{option.ticker}</span>
                          </div>
                        </li>
                      )}
                      filterOptions={securityFilter}
                      inputValue={autoSecurityValue}
                      id="controlled-demo"
                      onInputChange={(event: any) => {
                        if (!event?.target?.value) {
                          setAutoSecurityValue('')
                          return
                        }
                        setAutoSecurityValue(event.target.value)
                      }}
                      onChange={(event: any, newValue: Security | null) => {
                        if (!newValue) return
                        setAutoSecurityValue('')
                        setMassOrderSecurity(newValue)
                        if (massOrderClients && massOrderClients.length > 0) {
                          setMassOrderClients(
                            massOrderClients.map((c: MassOrderClient) => {
                              return { ...c, massOrderCurrency: newValue.currency }
                            })
                          )
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} label={t('SELECT_INSTRUMENT')} variant="standard" margin="normal" />
                      )}
                    />
                  ) : (
                    <>
                      <div className={classes.topContainer}>
                        <InstrumentIcon
                          className={`${classes.iconSize} ${instrumentIconType} ${classes.position}`}
                          securityType={massOrderSecurity.assetClass}
                        />
                        <span className={`${classes.topLineText} ${classes.boldText} ${classes.textColor}`}>
                          {massOrderSecurity.name}
                        </span>
                        <div className={classes.topLineSeparator} />
                        <div className={classes.isin}>
                          <span className={` ${classes.boldText} ${classes.textColor}`}>{massOrderSecurity.isin}</span>
                          <span className={` ${classes.textColor}`}>{t(massOrderSecurity.instrumentType)}</span>
                        </div>
                      </div>
                      <div className={classes.bottomContainer}>
                        <div className={classes.flexColumn}>
                          <span className={`${classes.boldText} ${classes.textSeparatorLeft} ${classes.textColor}`}>
                            <NumberFormat
                              value={massOrderSecurity.lastQuotePrice}
                              displayType="text"
                              thousandSeparator=" "
                              decimalScale={8}
                              decimalSeparator=","
                            />{' '}
                            {massOrderSecurity.currency}
                          </span>
                          <span className={`${classes.securityInfo} ${classes.textSeparatorLeft}`}>
                            {massOrderSecurity.lastQuoteDate}
                          </span>
                        </div>
                        <span className={`${classes.percentage} ${classes.textSeparatorLeft}`}>
                          {massOrderSecurity.lastPriceIndication}
                        </span>
                        <div className={classes.topLineSeparator} />
                        <span className={classes.securityInfo}>
                          {massOrderSecurity.symbol}: {massOrderSecurity.market}
                        </span>
                      </div>
                    </>
                  )}
                </Paper>
                {massOrderSecurity && (
                  <TransparentIconButton
                    className={classes.clearButton}
                    icon={<ClearIcon fontSize="small" color="primary" />}
                    onClick={onSecurityClearClick}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.addClientContainer}>
                {/*DOUBLE RENDER NECCESSARY TO BYPASS INTERNAL ISSUE WITH AUTOCOMPLETE NOT TRIGGERING ONCHANGE WHEN NEW SELECTION MATCHES PREVIOUS ONE*/}
                <Autocomplete
                  disabled={!massOrderSecurity}
                  className={classes.autoCompleteField}
                  onBlur={() => setAutoClientInputValue('')}
                  disableClearable
                  fullWidth
                  options={clients}
                  getOptionLabel={(option: MassOrderClient) =>
                    `${option.surname} ${option.firstName}, ${option.personalNumber}`
                  }
                  filterOptions={clientFilter}
                  inputValue={autoClientInputValue}
                  id="controlled-demo"
                  onInputChange={(event: any) => {
                    if (!event?.target?.value) {
                      setAutoClientInputValue('')
                      return
                    }
                    setAutoClientInputValue(event.target.value)
                  }}
                  onChange={(event: any, newValue: MassOrderClient | null) => {
                    if (!newValue) {
                      return
                    }
                    setAutoHack(!autoHack)
                    setAutoClientInputValue('')
                    if (!massOrderClients.find(e => e.clientId === newValue.clientId) && massOrderSecurity) {
                      const isBroken =
                        !categorySecValidation(newValue.category, massOrderSecurity.category) ||
                        !skillAndExperienceSecValidation(
                          newValue.skillAndExperience,
                          massOrderSecurity.skillAndExperience
                        ) ||
                        !preferenceSecValidation(newValue.preference, massOrderSecurity.preference) ||
                        !lossAbilitySecValidation(newValue.lossAbility, massOrderSecurity.lossAbility)
                      setMassOrderClients([
                        ...massOrderClients,
                        {
                          ...newValue,
                          massOrderCurrency: massOrderSecurity.currency,
                          exchangedFromTo: true,
                          selectedPortfolio: newValue.portfolios[0],
                          categorySec: massOrderSecurity.category,
                          isCategorySec: !categorySecValidation(newValue.category, massOrderSecurity.category),
                          skillAndExperienceSec: massOrderSecurity.skillAndExperience,
                          isSkillAndExperienceSec: !skillAndExperienceSecValidation(
                            newValue.skillAndExperience,
                            massOrderSecurity.skillAndExperience
                          ),
                          preferenceSec: massOrderSecurity.preference,
                          isPreferenceSec: !preferenceSecValidation(newValue.preference, massOrderSecurity.preference),
                          lossAbilitySec: massOrderSecurity.lossAbility,
                          isLossAbilitySec: !lossAbilitySecValidation(
                            newValue.lossAbility,
                            massOrderSecurity.lossAbility
                          ),
                          isID: isBroken,
                        },
                      ])
                    }
                  }}
                  renderInput={params => (
                    <TextField {...params} label={t('SELECT_CLIENT')} variant="standard" margin="normal" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} className={`${classes.flexColumn} ${classes.fullwidth}`} key={option.clientId}>
                      <span className={classes.boldText}>
                        {option.firstName} {option.surname}
                      </span>
                      <div className={`${classes.flexRow} ${classes.fullwidth}`}>
                        <span>
                          <NumberFormat
                            value={Math.round(((option.portfolios && option.portfolios.length>0 && option.portfolios[0].amount ? option.portfolios[0].amount : 0) + Number.EPSILON) * 100) / 100}
                            displayType="text"
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator=" "
                          />{' '}
                          {option.portfolios[0].currency}
                        </span>
                        <span className={classes.topLineSeparator} />
                        <span>
                          <NumberFormat value={option.telephoneNumber} displayType={'text'} format="### ### ### ###" />
                        </span>
                      </div>
                    </li>
                  )}
                />
                {/* {!autoHack && (
                                    <Autocomplete
                                        disabled={!massOrderSecurity}
                                        className={classes.autoCompleteField}
                                        onBlur={() => setAutoClientInputValue('')}
                                        disableClearable
                                        fullWidth
                                        options={clients}
                                        getOptionLabel={(option: MassOrderClient) =>
                                            `${option.surname} ${option.firstName}, ${option.personalNumber}`
                                        }
                                        filterOptions={clientFilter}
                                        inputValue={autoClientInputValue}
                                        id="controlled-demo"
                                        onInputChange={(event: any) => {
                                            if (!event?.target?.value) {
                                                setAutoClientInputValue('')
                                                return
                                            }
                                            setAutoClientInputValue(event.target.value)
                                        }}
                                        onChange={(event: any, newValue: MassOrderClient | null) => {
                                            if (!newValue) {
                                                return
                                            }
                                            setAutoHack(!autoHack)
                                            setAutoClientInputValue('')
                                            if (!massOrderClients.find(e => e.clientId === newValue.clientId) && massOrderSecurity) {
                                                setMassOrderClients([
                                                    ...massOrderClients,
                                                    {
                                                        ...newValue,
                                                        massOrderCurrency: massOrderSecurity.currency,
                                                        exchangedFromTo: true,
                                                        selectedPortfolio: newValue.portfolios[0],
                                                        categorySec:massOrderSecurity.category,
                                                        isCategorySec:!categorySecValidation(newValue.category, massOrderSecurity.category),
                                                        skillAndExperienceSec:massOrderSecurity.skillAndExperience,
                                                        isSkillAndExperienceSec:!skillAndExperienceSecValidation(newValue.skillAndExperience, massOrderSecurity.skillAndExperience),
                                                        preferenceSec:massOrderSecurity.preference,
                                                        isPreferenceSec:!preferenceSecValidation(newValue.preference, massOrderSecurity.preference),
                                                        lossAbilitySec:massOrderSecurity.lossAbility,                                                    
                                                        isLossAbilitySec:!lossAbilitySecValidation(newValue.lossAbility, massOrderSecurity.lossAbility),
                                                    },
                                                ])
                                            }
                                        }}
                                        renderInput={params => <TextField {...params} label="Výběr klienta"
                                                                          variant="standard"
                                                                          margin="normal"/>}
                                        renderOption={(props, option) => (
                                            <li {...props} className={`${classes.flexColumn} ${classes.fullwidth}`}
                                                key={option.clientId}>
                        <span className={classes.boldText}>
                          {option.firstName} {option.surname}
                        </span>
                                                <div className={`${classes.flexRow} ${classes.fullwidth}`}>
                          <span>
                            <NumberFormat
                                value={Math.round(((option.portfolios[0].amount || 0) + Number.EPSILON) * 100) / 100}
                                displayType="text"
                                fixedDecimalScale
                                decimalScale={2}
                                decimalSeparator=","
                                thousandSeparator=" "
                            />{' '}
                              {option.portfolios[0].currency}
                          </span>
                                                    <span className={classes.topLineSeparator}/>
                                                    <span>
                            <NumberFormat value={option.telephoneNumber} displayType={'text'} format="### ### ### ###"/>
                          </span>
                                                </div>
                                            </li>
                                        )}
                                    />
                                )} */}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.massOrderTotals}>
                <div className={classes.totalsContainer}>
                  <div className={classes.totalsLabels}>
                    <div className={`${classes.textColor} ${classes.totalsLabel}`}>{t('TOTAL_QUANTITY')}: </div>
                    <div className={`${classes.textColor} ${classes.totalsLabel}`}>{t('TOTAL_AMOUNT')}:</div>
                  </div>
                  <div className={classes.totalsNumbers}>
                    <div className={`${classes.totalsNumber} ${classes.boldText} ${classes.textColor}`}>
                      <NumberFormat
                        value={totalQuantity()}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={4}
                        decimalSeparator=","
                      />
                    </div>
                    <div className={`${classes.totalsNumber} ${classes.boldText} ${classes.textColor}`}>
                      {!massOrderSecurity ? (
                        0
                      ) : (
                        <NumberFormat
                          value={totalAmount()}
                          displayType="text"
                          thousandSeparator=" "
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          suffix={`${!massOrderSecurity ? ' ' : ` ${massOrderSecurity.currency}`}`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Paper>
              <Paper className={classes.selectionContainer}>
                <ArrowSelect
                  value={t(order.marketType)}
                  label={t('MARKET_TYPE')}
                  options={codeListToArrowSelect(ORDER_MARKET_TYPE, codeList)}
                  onChange={handleChangeOrder('marketType')}
                />
                {order.marketType === ORDER_MARKET_TYPE_MARKET && (
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="standard"
                    fullWidth={true}
                    value={!massOrderSecurity ? 0 : massOrderSecurity?.lastQuotePrice}
                    label={t('PRICE')}
                  />
                )}
                {order.marketType === ORDER_MARKET_TYPE_LIMIT && (
                  <NumericInput
                    fullWidth={true}
                    value={order.price || ''}
                    onChange={changeLimitPrice}
                    label={t('PRICE')}
                  />
                )}
                <ArrowSelect
                  value={t(order.direction)}
                  label={t('ORDER_DIRECTION2')}
                  options={codeListToArrowSelect(ORDER_DIRECTION, codeList)}
                  onChange={handleChangeOrder('direction')}
                />
                <ArrowSelect
                  value={t(order.type)}
                  label={t('ORDER_TYPE')}
                  options={codeListToArrowSelect(ORDER_TYPE, codeList)}
                  onChange={handleChangeOrder('type')}
                />
                <ArrowSelect
                  value={t(order.validityType)}
                  label={t('ORDER_VALIDITY')}
                  options={codeListToArrowSelect(ORDER_VALIDITY, codeList)}
                  onChange={handleChangeOrder('validityType')}
                />
                {order.validityType === ORDER_VALIDITY_DATE && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={props => (
                        <TextField fullWidth={true} {...props} variant="standard" label={t('DATE')} />
                      )}
                      inputFormat="dd/MM/yyyy"
                      value={order.validityDate || new Date()}
                      onChange={handleDateChange}
                    />
                  </LocalizationProvider>
                )}
                {/*}   <ArrowSelect value={textFieldValue} label="Protistrana" options={textFieldValues} /> {*/}
              </Paper>
            </Grid>
            <Grid item xs={6} className={classes.massOrderClientsContainer}>
              {massOrderClients.map((client: MassOrderClient, index) => (
                <div key={index} className={classes.paperAndClearContainer}>
                  <Paper
                    variant="outlined"
                    className={`${
                      !client.clientExternalId || !client.portfolios[0].externalId
                        ? classes.clientContainerError
                        : classes.clientContainer
                    } ${orderCoveredColor(
                      client.coverPercentage ? client.coverPercentage >= 100 && !client.isID : false
                    )}`}
                  >
                    <div className={classes.clientContainer2}>
                      <div>
                        <div className={classes.clientCredentialsContainer}>
                          <div className={classes.clientCredentialsRow}>
                            <img className={classes.clientIcon} src={manS} width={20} />
                            <div className={classes.clientCredentials}>
                              <span className={classes.boldText}>{client.surname}</span>
                              <span className={classes.smallFont}>{client.firstName}</span>
                              <NumberFormat
                                className={classes.smallFont}
                                value={client.telephoneNumber}
                                displayType={'text'}
                                format="##### ### ### ###"
                              />
                            </div>
                          </div>
                          <div className={classes.clientCredentialsRow}>
                            <TextField
                              fullWidth
                              select
                              variant="standard"
                              label={t('PORTFOLIO')}
                              value={client.selectedPortfolio ? client.selectedPortfolio.name : ''}
                              onChange={onPortfolioChange(client)}
                            >
                              {client.portfolios.map(a => (
                                <MenuItem key={a.name} value={a.name}>
                                  {a.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>

                          <div className={classes.clientCredentialsRow}>
                            <div className={classes.amountInputContainer}>
                              {order.type === ORDER_TYPE_AMOUNT && (
                                <NumericInput
                                  fullWidth={true}
                                  value={client.massOrderByAmount || ''}
                                  onChange={onClientInputChange(client)}
                                  label={t('DEAL_AMOUNT')}
                                />
                              )}
                              {order.type === ORDER_TYPE_QUANTITY && (
                                <NumericInput
                                  fullWidth={true}
                                  value={client.massOrderByQuantity || ''}
                                  onChange={onClientInputChange(client)}
                                  label={t('QUANTITY')}
                                />
                              )}
                            </div>

                            <div className={classes.currencySelection}>
                              <TextField
                                fullWidth
                                id="standard-select-currency"
                                select
                                variant="standard"
                                label={t('CURRENCY')}
                                value={client.massOrderCurrency}
                                onChange={onClientCurrencyChange(client)}
                              >
                                <MenuItem key={client.portfolios[0].currency} value={client.portfolios[0].currency}>
                                  {client.portfolios[0].currency}
                                </MenuItem>
                                <MenuItem key={massOrderSecurity?.currency} value={massOrderSecurity?.currency}>
                                  {massOrderSecurity?.currency}
                                </MenuItem>
                              </TextField>
                            </div>
                          </div>

                          <div>
                            <TextField
                              className={classes.fullwidth}
                              label={t('VIOLATION_COMMENT')}
                              disabled={
                                !client.isID ||
                                !(client.massOrderByQuantity ? client.massOrderByQuantity > 0 : false) ||
                                order.direction !== 'OD_BUY'
                              }
                              value={client.orderComment || ''}
                              onChange={onClientCommentChange(client)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={classes.clientOrderTotalContainer}>
                        <div className={classes.flexRow90}>
                          <div className={classes.clientOrderTotalContainer}>
                            {order.type === ORDER_TYPE_AMOUNT && (
                              <>
                                <div>{t('QUANTITY')}</div>
                                <div className={classes.boldText}>
                                  <NumberFormat
                                    value={client.massOrderByQuantity || 0}
                                    displayType="text"
                                    thousandSeparator=" "
                                    fixedDecimalScale
                                    decimalScale={4}
                                    decimalSeparator=","
                                  />
                                </div>
                              </>
                            )}
                            {order.type === ORDER_TYPE_QUANTITY && (
                              <>
                                <div>{t('ORDER_AMOUNT')}</div>
                                <div className={classes.boldText}>
                                  <NumberFormat
                                    value={client.massOrderByAmount}
                                    displayType="text"
                                    thousandSeparator=" "
                                    fixedDecimalScale
                                    decimalScale={2}
                                    decimalSeparator=","
                                  />{' '}
                                  {client.massOrderCurrency || massOrderSecurity?.currency}
                                </div>
                              </>
                            )}
                            <div className={`${classes.smallFont}`}>{t('REQUIRED_COVER')}</div>
                            <div className={`${classes.smallFont}`}>{t('N_A')}</div>
                          </div>
                          <div className={`${classes.portfolioTotalsContainer} ${classes.centerContent}`}>
                            <div className={classes.centerContent}>
                              <div>{t('COVER')}</div>
                              <div className={classes.boldText}>
                                <NumberFormat
                                  value={cover(client.coverPercentage ? client.coverPercentage : 0)}
                                  displayType="text"
                                  thousandSeparator=" "
                                  fixedDecimalScale
                                  decimalScale={0}
                                  decimalSeparator=","
                                />
                                %
                              </div>
                            </div>
                            <span className={`${classes.smallFont}`}>
                              {t('BALANCE')}
                              <NumberFormat
                                value={client.selectedPortfolio.accountsBalance}
                                displayType="text"
                                thousandSeparator=" "
                                fixedDecimalScale
                                decimalScale={2}
                                decimalSeparator=","
                              />{' '}
                              {client.selectedPortfolio.currency}
                            </span>
                          </div>
                        </div>
                        <div className={classes.flexRow90}>
                          <div className={`${classes.leftContent} ${classes.flexColumn}`}>
                            <div className={`${classes.smallFont}`}>&nbsp;</div>
                            <div className={`${!client.isHorizonteValue ? classes.smallFontRed : classes.smallFont}`}>
                              {t('HORIZONT_VALUE')}:
                            </div>
                            <div className={`${!client.isRiskValue ? classes.smallFontRed : classes.smallFont}`}>
                              {t('RISK')}:
                            </div>
                            <div className={`${!client.isCategorySec ? classes.smallFontRed : classes.smallFont}`}>
                              {t('CATEGORY')}:
                            </div>
                            <div
                              className={`${
                                !client.isSkillAndExperienceSec ? classes.smallFontRed : classes.smallFont
                              }`}
                            >
                              {t('EXPERIENCE')}:
                            </div>
                            <div className={`${!client.isPreferenceSec ? classes.smallFontRed : classes.smallFont}`}>
                              {t('PREFERENCE')}:
                            </div>
                            <div className={`${!client.isLossAbilitySec ? classes.smallFontRed : classes.smallFont}`}>
                              {t('RISK2')}:
                            </div>
                          </div>

                          <div className={`${classes.leftContent} ${classes.centerContent}`}>
                            <div className={`${classes.smallFont}`}>{t('CLIENT')}</div>
                            <div className={`${classes.smallFont}`}>{client.investmentHorizonte}</div>
                            <div className={`${classes.smallFont}`}>{client.riskProfile}</div>
                            <div className={`${classes.smallFont}`}>{t(client.category || '')}</div>
                            <div className={`${classes.smallFont}`}>{t(client.skillAndExperience || '')}</div>
                            <div className={`${classes.smallFont}`}>{t(client.preference || '')}</div>
                            <div className={`${classes.smallFont}`}>{t(client.lossAbility || '')}</div>
                          </div>

                          <div className={`${classes.leftContent} ${classes.centerContent}`}>
                            <div className={`${classes.smallFont}`}>{t('SECURITY')}</div>
                            <div
                              className={`${!client.isHorizonteValueSec ? classes.smallFontRed : classes.smallFont}`}
                            >
                              {massOrderSecurity?.horizonteValue}
                            </div>
                            <div className={`${!client.isRiskValueSec ? classes.smallFontRed : classes.smallFont}`}>
                              {massOrderSecurity?.riskValue}
                            </div>
                            <div className={`${!client.isCategorySec ? classes.smallFontRed : classes.smallFont}`}>
                              {t(massOrderSecurity?.category || '')}
                            </div>
                            <div
                              className={`${
                                !client.isSkillAndExperienceSec ? classes.smallFontRed : classes.smallFont
                              }`}
                            >
                              {t(massOrderSecurity?.skillAndExperience || '')}
                            </div>
                            <div className={`${!client.isPreferenceSec ? classes.smallFontRed : classes.smallFont}`}>
                              {t(massOrderSecurity?.preference || '')}
                            </div>
                            <div className={`${!client.isLossAbilitySec ? classes.smallFontRed : classes.smallFont}`}>
                              {t(massOrderSecurity?.lossAbility || '')}
                            </div>
                          </div>

                          <div className={`${classes.leftContent} ${classes.centerContent}`}>
                            <div className={`${classes.smallFont}`}>{t('PORTFOLIO')}</div>
                            <div className={`${!client.isHorizonteValue ? classes.smallFontRed : classes.smallFont}`}>
                              <NumberFormat
                                value={client.horizonteValue || 0}
                                displayType="text"
                                thousandSeparator=" "
                                fixedDecimalScale
                                decimalScale={2}
                                decimalSeparator=","
                              />
                            </div>
                            <div className={`${!client.isRiskValue ? classes.smallFontRed : classes.smallFont}`}>
                              <NumberFormat
                                value={client.riskValue || 0}
                                displayType="text"
                                thousandSeparator=" "
                                fixedDecimalScale
                                decimalScale={2}
                                decimalSeparator=","
                              />
                            </div>
                            <div className={`${!client.isCategorySec ? classes.smallFontRed : classes.smallFont}`}>
                              {t(client.categorySec || '')}
                            </div>
                            <div
                              className={`${
                                !client.isSkillAndExperienceSec ? classes.smallFontRed : classes.smallFont
                              }`}
                            >
                              {t(client.skillAndExperienceSec || '')}
                            </div>
                            <div className={`${!client.isPreferenceSec ? classes.smallFontRed : classes.smallFont}`}>
                              {t(client.preferenceSec || '')}
                            </div>
                            <div className={`${!client.isLossAbilitySec ? classes.smallFontRed : classes.smallFont}`}>
                              {t(client.lossAbilitySec || '')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {sendToFix && (!client.clientExternalId || !client.portfolios[0].externalId) && (
                      <Alert severity="error">
                        {t('FIX_ERROR_MESSAGE') + ' '}
                        {!client.clientExternalId
                          ? !client.portfolios[0].externalId
                            ? t('FIX_ERROR_MESSAGE_ID1')
                            : t('FIX_ERROR_MESSAGE_ID2')
                          : !client.portfolios[0].externalId
                          ? t('FIX_ERROR_MESSAGE_ID3')
                          : ''}{' '}
                      </Alert>
                    )}
                  </Paper>
                  <TransparentIconButton
                    className={classes.clearButton}
                    icon={<ClearIcon fontSize="small" color="primary" />}
                    onClick={onClientClearClick(client)}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox id="fixValue01" checked={sendToFix} onChange={handleCheckbox} />}
                label={t('FIX_SEND_ORDER')}
                disabled={user && user.businessTerritoryId===APEX}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            {t('CANCEL')}
          </Button>
          <Button
            className={`${user && user.businessTerritoryId!==APEX?classes.actionButtons:classes.actionButtonsApex} ${getInputDialogColor(order.direction)}`}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size="1.4rem" /> : order.direction === 'OD_BUY' ? ( user && user.businessTerritoryId===APEX ? t('BUY_AND_SETTLE') : t('BUY') ) : ( user && user.businessTerritoryId===APEX ? ('SELL_AND_SETTLE') : ('SELL') )}
          </Button>
        </DialogActions>
      </Dialog>
      <ClearConfirmationAlert
        alertOpen={securityAlertOpen}
        onOk={clearSecurity}
        onCancel={clearSecurityCancel}
        alertText={t('REMOVE_INSTRUMENT')}
        inProgress={false}
      />
      <ActionFailedAlert handleClose={handleCloseAlert}/>
      <ActionAlert />
      <MessageAlert alertOpen={alertMessageOpen} alertText={t('MASS_ORDER_ALERT_MESSAGE1')} onOk={handleCloseMessage} />
      <MessageAlert
        alertOpen={alertMessage2Open}
        alertText={t('MASS_ORDER_ALERT_MESSAGE2')}
        onOk={handleCloseMessage2}
      />
      <MessageAlert
        alertOpen={alertMessage3Open}
        alertText={t('MASS_ORDER_ALERT_MESSAGE3')}
        onOk={handleCloseMessage3}
      />

      <ClearConfirmationAlert
        alertOpen={orderAlertOpen}
        onOk={orderAlertOk}
        onCancel={orderAlertCancel}
        alertText={t('INVESTMENT_VIOLATION_MESSAGE')}
        inProgress={isSavingFromAlert}
      />
    </div>
  )
}
