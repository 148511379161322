import { ReduxAction } from '../../types/common'
import { GET_CURRENCIES, GET_RATES } from '../actionTypes'

export interface Rate {
  date: string
  currencyFrom: string
  currencyTo: string
  rate: number
  value: number
}

export interface Currency {
  currencyId: number
  code: string
  name: string
  amount: number
}

interface CurrencyState {
  rates: Rate[]
  currencies: Currency[]
}

const INITIAL_STATE = {
  rates: [],
  currencies: [],
}

export const currency = (state: CurrencyState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_RATES:
      return {
        ...state,
        rates: action.payload as Rate[],
      }
    case GET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload as Currency[],
      }
    default:
      return state
  }
}
