import { ReduxAction } from '../../types/common'
import { ADD_ORDER, GET_PORTFOLIO } from '../actionTypes'
import { Order } from './orders'

export enum PortfolioType {
  Stock = 'Akcie',
  Bond = 'Dluhopisy',
  FinancialMarket = 'Finanční trhy',
  Other = 'Ostatní',
}

export interface PortfolioAsset {
  name: string
  ISIN: string
  class: PortfolioType
  instrumentType: string
  currency: string
  instrumentCurrencyTotal: number
  portfolioCurrencyTotal: number
  amount: number | undefined
}

export interface Account {
  bankCode: string
  currency: string
  accountId: number
  number: string
  amount: number
  portfolioCurrencyAmount: number
}

export interface Instrument {
  assetClass: string
  currency: string
  instrumentId: number
  instrumentType: string
  isin: string | null
  name: string
}

export interface Position {
  amount: number
  portfolioCurrencyAmount: number
  percent: number
  dateFrom: string
  dateTo: string
  instrument: Instrument
  positionId: number
  quantity: number
  averagePurchasePrice: number
  profitLoss: number
  profitLossPercentage: number
  lastPrice: number
  lastAccrued?: number
}

export interface PositionDetail {
  amount: number
  avgPurchasePrice: number
  buyAmount: number
  isin: string
  date: string
  quantity: number
  lastPrice: number
  positionHistory: []
  buyHistoryPrice: []
  sellHistoryPrice: []
  profitLossPercentage: number
  sellAmount: number
  tradedAmount: number
  riskValue: number | null
  horizonteValue: number | null
}

export interface AccountPosition {
  amount: number
  percent: number
  dateFrom: string
  dateTo: string
  account: Account
  accountPositionId: number
  portfolioCurrencyAmount: number
}

export interface Portfolio {
  positions: Position[]
  unfinishedOrders: Order[]
  amount: number | null
  currency: string | null
  name: string | null
  portfolioId: number | null
  type: string
  instrumentPosition: Array<any> | null
  externalId: string | null
  riskValue: number | null
  horizonteValue: number | null
  categoryValue: number | null
  skillAndExperienceValue: number | null
  preferenceValue: number | null
  lossAbilityValue: number | null
  esgValue: number | null
}

interface PortfolioState {
  portfolio: Portfolio
}

const INITIAL_STATE: PortfolioState = {
  portfolio: {
    positions: [],
    unfinishedOrders: [],
    amount: null,
    currency: null,
    name: null,
    portfolioId: null,
    type: '',
    instrumentPosition: null,
    externalId: null,
    riskValue: null,
    horizonteValue: null,
    categoryValue: null,
    skillAndExperienceValue: null,
    preferenceValue: null,
    lossAbilityValue: null,
    esgValue: null,
  },
}

export const detailPortfolioState = (state: PortfolioState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_PORTFOLIO:
      return {
        ...state,
        portfolio: action.payload as Portfolio,
      }
    case ADD_ORDER:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          unfinishedOrders: [...state.portfolio.unfinishedOrders, action.payload],
        },
      }
    default:
      return state
  }
}
