import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  deactivateClientRequest,
  getClientRequest,
  getClientRolesRequest,
  getClientsByParamRequest,
  getClientsRequest,
  postClientRequest,
  updateClientRequest,
} from '../api/clientAPI'
import { ACTION_FAILED_ALERT_ID, CLIENT_INFORMATION_DIALOG_ID } from '../constants/dialogConstants'
import {
  addClientAction,
  deactivateClientAction,
  getClientAction,
  getClientRolesAction,
  getClientsAction,
  getClientsActionAfter,
  getClientsActionBefore,
  updateClientAction,
} from '../redux/actions/clientActions'
import { closeDialog, openDialog } from '../redux/actions/dialogActions'
import { Client } from '../redux/reducers/clients'

export const useClientApi = () => {
  const dispatch = useDispatch()

  const getClients = useCallback(async () => {
    dispatch(getClientsActionBefore())

    await getClientsRequest()
      .then(response => {
        dispatch(getClientsAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
    dispatch(getClientsActionAfter())
  }, [])

  const postClient = useCallback(async (client: Client) => {
    await postClientRequest(client)
      .then(response => {
        dispatch(addClientAction(response.data))
        dispatch(closeDialog(CLIENT_INFORMATION_DIALOG_ID))
      })
      .catch(error => {
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const updateClient = useCallback(async (client: Client) => {
    await updateClientRequest(client)
      .then(response => {
        dispatch(updateClientAction(response.data))
        dispatch(closeDialog(CLIENT_INFORMATION_DIALOG_ID))
      })
      .catch(error => {
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const deactivateClient = useCallback(async (client: Client) => {
    await deactivateClientRequest(client).then(response => {
      dispatch(deactivateClientAction(response.data))
    })
  }, [])

  const getClient = useCallback(async (clientId: number) => {
    dispatch(getClientsActionBefore())

    await getClientRequest(clientId)
      .then(response => {
        dispatch(getClientAction(response.data as Client))
      })
      .catch(error => {
        console.error(error)
      })

    dispatch(getClientsActionAfter())
  }, [])

  const getClientsByParam =
    useCallback(async (param: string) => {
      await getClientsByParamRequest(param)
        .then(response => {
          dispatch(getClientsAction(response.data as Client[]))
        })
        .catch(error => {
          console.error(error)
        })
    }, []) || []

  const getClientRoles = useCallback(async () => {
    await getClientRolesRequest()
      .then(response => {
        dispatch(getClientRolesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getClients,
    getClient,
    postClient,
    updateClient,
    deactivateClient,
    getClientsByParam,
    getClientRoles,
  }
}
