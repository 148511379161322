import { Note } from '../redux/reducers/note'
import { fetchRequest } from '../services/http.service'

const URL = '/note'

export const getNotesRequest = (portfolioId: Number | null) =>
  fetchRequest.get<Note[]>(URL + `/${portfolioId ? portfolioId : ''}`)
export const createNoteRequest = (note: Note | null) => fetchRequest.post<Note>(URL, note)
export const updateNoteRequest = (note: Note | null) => fetchRequest.put<Note>(URL, note)
export const deleteNoteRequest = (noteId: number | null) =>
  fetchRequest.remove<Note[]>(URL + `/${noteId ? noteId : ''}`)
