import {
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  TextField,
  Typography,
  Button,
  Select,
  InputLabel,
  SelectChangeEvent,
  MenuItem,
  Grid,
  Autocomplete,
  CircularProgress,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../redux/actions/dialogActions'
import { INSTRUMENT_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import {
  ASSET_CLASS,
  CLIENT_CATEGORY,
  CLIENT_EXPERIENCE,
  CLIENT_LOSS_ABILITY,
  CLIENT_PREFERENCE,
  INSTRUMENT_TYPE,
} from '../../constants/codeLists'
import { Security } from '../../redux/reducers/securities'
import { NumericInput } from '../common/NumericInput'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { newSecurity } from '../../constants/initialStates'
import { CodeList } from '../../types/common'
import { Currency } from '../../redux/reducers/currency'
import { Market } from '../../redux/reducers/market'
import { setErrors } from '../../redux/actions/userActions'
import { REQUIERED } from '../../constants/textConstants'
import { DropZone } from '../common/DropZone'
import { fileToBase64 } from '../../utils/fileToBase64'
import { LoadingIndicator } from '../common/LoadingIndicator'

import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@date-io/date-fns'

const useStyles = makeStyles(theme => ({
  editDialog: {},
  clientInformationDialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionButtons: {
    width: '50%',
  },
  border: {
    border: `2px dashed ${theme.palette.grey[500]}`,
  },
}))

const requiredAttributes = [
  'assetClass',
  'currency',
  'isin',
  'name',
  'instrumentType',
  'nominal',
  'market',
  'riskValue',
  'horizonteValue',
  'category',
  'skillAndExperience',
  'preference',
  'lossAbility',
  'lastQuotePrice',
]

interface InstrumentInputProps {
  isUpdate: boolean
}

// export const NewLabelDialog: React.FC<LabelDialogProps> = ({
//   portfolioId, labels, color, name, labelId, newDialog
// }) => {

export const InstrumentInputDialog: React.FC<InstrumentInputProps> = ({ isUpdate }) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const { getCodeList, codeList, updateSecurity, postSecurity, getCurrencies, getMarkets, getInstrumentsByParam } =
    useInstrumentApi()

  const filters = useSelector(state => state.filters)
  const foundDialog = dialogs.find(dialog => dialog.id === INSTRUMENT_INPUT_DIALOG_ID)
  const open = !!foundDialog
  const currencies: Currency[] = useSelector(state => state.currency.currencies)
  const markets: Market[] = useSelector(state => state.market.markets)
  const instrumentToUpdate: Security | undefined = useSelector(state => state.securities.security)
  const errors = useSelector(state => state.useractions.errors)
  const [security, setSecurity] = useState<Security>(
    instrumentToUpdate !== undefined ? instrumentToUpdate : newSecurity
  )
  const [instrumentTypes, setInstrumentTypes] = useState<CodeList | undefined>(undefined)
  const [assetClasses, setAssetClasses] = useState<CodeList | undefined>(undefined)
  const [categories, setCategories] = useState<CodeList | undefined>(undefined)
  const [experiences, setExperiences] = useState<CodeList | undefined>(undefined)
  const [preferences, setPreferences] = useState<CodeList | undefined>(undefined)
  const [lossAbilities, setLossAbilities] = useState<CodeList | undefined>(undefined)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  const isValid = () => {
    let valid = true
    let errorKeys = []
    const errors: {
      [key: string]: boolean
    } = {}
    for (const [key, value] of Object.entries(security)) {
      if (!requiredAttributes.includes(key)) continue

      if (key === 'lastQuotePrice') {
        if (instrumentToUpdate === undefined && (!value || (value && !String(value).trim()))) {
          valid = false
          errors[key] = true
          errorKeys.push(key)
        } else {
          continue
        }
      }

      if (!value || (value && !String(value).trim())) {
        valid = false
        errors[key] = true
        errorKeys.push(key)
      }
    }

    dispatch(setErrors(errors))
    return valid
  }

  const handleClose = () => {
    dispatch(closeDialog(INSTRUMENT_INPUT_DIALOG_ID))
    dispatch(setErrors({}))
    setSaveLoading(false)
  }

  useEffect(() => {
    setSecurity(instrumentToUpdate !== undefined ? instrumentToUpdate : newSecurity)
    setSaveLoading(false)
  }, [instrumentToUpdate])

  const handleSave = () => {
    if (isValid()) {
      setSaveLoading(true)
      var promise
      if (instrumentToUpdate === undefined) {
        promise = postSecurity(security)
      } else {
        promise = updateSecurity(security).then(handleClose)
      }
      promise
        .then(() =>
          getInstrumentsByParam(
            filters.searchedInstrumentName.length >= 3 ? filters.searchedInstrumentName : '',
            filters.searchedAll
          )
        )
        .then(handleClose)
    }
  }

  const handleChangeAutocomplete = (name: string) => (event: any, value: any) => {
    setSecurity({ ...security, [name]: value })
    if (value.trim()) {
      dispatch(setErrors({ ...errors, [name]: undefined }))
    }
  }

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecurity({ ...security, [name]: event.target.value })
    if (event.target.value.trim()) {
      dispatch(setErrors({ ...errors, [name]: undefined }))
    }
  }

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setSecurity({ ...security, [name]: date })
  }

  useEffect(() => {
    getCodeList()
    getCurrencies()
    getMarkets()
  }, [])

  useEffect(() => {
    if (Array.isArray(codeList)) {
      setInstrumentTypes(codeList.find(e => e.code === INSTRUMENT_TYPE))
      setAssetClasses(codeList.find(e => e.code === ASSET_CLASS))
      setCategories(codeList.find(e => e.code === CLIENT_CATEGORY))
      setExperiences(codeList.find(e => e.code === CLIENT_EXPERIENCE))
      setPreferences(codeList.find(e => e.code === CLIENT_PREFERENCE))
      setLossAbilities(codeList.find(e => e.code === CLIENT_LOSS_ABILITY))
    }
  }, [codeList])

  const onFile1Drop = (acceptedFiles: File[]) => {
    fileToBase64(acceptedFiles[0]).then(result =>
      setSecurity({
        ...security,
        fileKeyMessageContent: result.split(',')[1],
        fileKeyMessageHeader: result.split(',')[0],
        fileKeyMessageName: acceptedFiles[0].name,
      })
    )
  }

  const onFile2Drop = (acceptedFiles: File[]) => {
    fileToBase64(acceptedFiles[0]).then(result =>
      setSecurity({
        ...security,
        fileProspectContent: result.split(',')[1],
        fileProspectHeader: result.split(',')[0],
        fileProspectName: acceptedFiles[0].name,
      })
    )
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.editDialog}
        maxWidth="md"
      >
        <div className={classes.clientInformationDialogHeader}>
          <Typography variant="h5">{isUpdate ? t('UPDATE_SECURITY') : t('NEW_SECURITY')}</Typography>
        </div>
        <DialogContent>
          <div className={classes.dialogContentContainer}>
            {isUpdate && !instrumentToUpdate ? (
              <LoadingIndicator />
            ) : (
              <Grid container spacing={2}>
                {instrumentToUpdate !== undefined && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="standard"
                      id="securityId"
                      label={t('SECURITY_ID')}
                      value={security.securityId}
                      disabled={true}
                    />
                  </Grid>
                )}

                <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    id="instrument-type"
                    label={t('INSTRUMENT_TYPE')}
                    value={security.instrumentType}
                    onChange={handleChange('instrumentType')}
                    helperText={errors['instrumentType'] ? t(REQUIERED) : ''}
                    error={errors['instrumentType']}
                    required
                  >
                    {instrumentTypes?.items.map(item => (
                      <MenuItem value={item.code}>{item.description}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    id="asset-class"
                    label={t('ASSET_CLASS')}
                    value={security.assetClass}
                    onChange={handleChange('assetClass')}
                    helperText={errors['assetClass'] ? t(REQUIERED) : ''}
                    error={errors['assetClass']}
                    required
                  >
                    {assetClasses?.items.map(item => (
                      <MenuItem value={item.code}>{item.description}</MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="name"
                    label={t('NAME')}
                    value={security.name}
                    onChange={handleChange('name')}
                    helperText={errors['name'] ? t(REQUIERED) : ''}
                    error={errors['name']}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="currency"
                    options={currencies?.map(item => item.code)}
                    value={security.currency}
                    getOptionLabel={item => item}
                    onChange={(event, newValue) => handleChangeAutocomplete('currency')(event, newValue)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t('CURRENCY')}
                        variant="standard"
                        helperText={errors['currency'] ? t(REQUIERED) : ''}
                        error={errors['currency']}
                        required
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="isin"
                    label={t('ISIN')}
                    value={security.isin}
                    onChange={handleChange('isin')}
                    helperText={errors['isin'] ? t(REQUIERED) : ''}
                    error={errors['isin']}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    id="category"
                    label={t('CATEGORY')}
                    value={!!security.category ? security.category : ''}
                    onChange={handleChange('category')}
                    helperText={errors['category'] ? t(REQUIERED) : ''}
                    error={errors['category']}
                    required
                  >
                    {categories?.items.map(item => (
                      <MenuItem value={item.code}>{t(item.code)}</MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="symbol"
                    label={t('SYMBOL')}
                    value={!!security.symbol ? security.symbol : ''}
                    onChange={handleChange('symbol')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumericInput
                    fullWidth
                    variant="standard"
                    id="nominal"
                    label={t('NOMINAL')}
                    value={!!security.nominal ? security.nominal : ''}
                    onChange={handleChange('nominal')}
                    helperText={errors['nominal'] ? t(REQUIERED) : ''}
                    error={errors['nominal']}
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="ticker"
                    label={t('TICKER')}
                    value={!!security.ticker ? security.ticker : ''}
                    onChange={handleChange('ticker')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="market"
                    options={markets?.map(item => item.name)}
                    value={security.market}
                    getOptionLabel={item => item}
                    onChange={(event, newValue) => handleChangeAutocomplete('market')(event, newValue)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t('OMT_MARKET')}
                        variant="standard"
                        helperText={errors['market'] ? t(REQUIERED) : ''}
                        error={errors['market']}
                        required
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <NumericInput
                    fullWidth
                    variant="standard"
                    id="risk"
                    label={t('RISK')}
                    value={security.riskValue}
                    onChange={handleChange('riskValue')}
                    helperText={errors['riskValue'] ? t(REQUIERED) : ''}
                    error={errors['riskValue']}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    id="experience"
                    label={t('EXPERIENCE')}
                    value={!!security.skillAndExperience ? security.skillAndExperience : ''}
                    onChange={handleChange('skillAndExperience')}
                    helperText={errors['skillAndExperience'] ? t(REQUIERED) : ''}
                    error={errors['skillAndExperience']}
                    required
                  >
                    {experiences?.items.map(item => (
                      <MenuItem value={item.code}>{t(item.code)}</MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <NumericInput
                    fullWidth
                    variant="standard"
                    id="horizont"
                    label={t('HORIZONTE')}
                    value={security.horizonteValue}
                    onChange={handleChange('horizonteValue')}
                    helperText={errors['horizonteValue'] ? t(REQUIERED) : ''}
                    error={errors['horizonteValue']}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    id="preference"
                    label={t('PREFERENCE')}
                    value={!!security.preference ? security.preference : ''}
                    onChange={handleChange('preference')}
                    helperText={errors['preference'] ? t(REQUIERED) : ''}
                    error={errors['preference']}
                    required
                  >
                    {preferences?.items.map(item => (
                      <MenuItem value={item.code}>{t(item.code)}</MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    id="lossAbility"
                    label={t('LOSS_ABILITY')}
                    value={!!security.lossAbility ? security.lossAbility : ''}
                    onChange={handleChange('lossAbility')}
                    helperText={errors['lossAbility'] ? t(REQUIERED) : ''}
                    error={errors['lossAbility']}
                    required
                  >
                    {lossAbilities?.items.map(item => (
                      <MenuItem value={item.code}>{t(item.code)}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="webpage"
                    label={t('WEB_PAGE')}
                    value={!!security.webpage ? security.webpage : ''}
                    onChange={handleChange('webpage')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumericInput
                    fullWidth
                    variant="standard"
                    id="lastQuotePrice"
                    label={t('PRICE')}
                    value={!!security.lastQuotePrice ? security.lastQuotePrice : ''}
                    onChange={handleChange('lastQuotePrice')}
                    disabled={!!instrumentToUpdate}
                    helperText={errors['lastQuotePrice'] ? t(REQUIERED) : ''}
                    error={errors['lastQuotePrice']}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="comment"
                    label={t('SECURITY_COMMENT')}
                    value={!!security.comment ? security.comment : ''}
                    onChange={handleChange('comment')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="additionalInformation"
                    label={t('ADDITIONAL_INFORMATION')}
                    value={!!security.additionalInformation ? security.additionalInformation : ''}
                    onChange={handleChange('additionalInformation')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body1">{t('FILE_KEY_MESSAGE')}</Typography>
                  <DropZone
                    onFileDrop={onFile1Drop}
                    defaultText={
                      security && security.fileKeyMessageName ? security.fileKeyMessageName : t('LOAD_FILE_KEY_MESSAGE')
                    }
                    className={classes.border}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body1">{t('FILE_PROSPECT')}</Typography>
                  <DropZone
                    onFileDrop={onFile2Drop}
                    defaultText={
                      security && security.fileProspectName ? security.fileProspectName : t('LOAD_FILE_PROSPECT')
                    }
                    className={classes.border}
                  />
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={6}>
                  <NumericInput
                    fullWidth
                    variant="standard"
                    id="coupon"
                    label={t('COUPON')}
                    value={!!security.coupon ? security.coupon : ''}
                    onChange={handleChange('coupon')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <NumericInput
                    fullWidth
                    variant="standard"
                    id="expectedPrice"
                    label={t('EXPECTED_PRICE')}
                    value={!!security.expectedPrice ? security.expectedPrice : ''}
                    onChange={handleChange('expectedPrice')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      renderInput={props => (
                        <TextField
                          variant="standard"
                          fullWidth={true}
                          {...props}
                          label={t('MATURITY_DATE')}
                          id="maturityDate"
                        />
                      )}
                      inputFormat="dd/MM/yyyy"
                      value={security?.maturityDate}
                      onChange={handleChangeDate('maturityDate')}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            {t('CANCEL')}
          </Button>
          <Button
            className={`${classes.actionButtons}`}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={saveLoading}
          >
            {saveLoading ? <CircularProgress size="1.4rem" /> : t('SAVE')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
