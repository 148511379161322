import { Grid, Paper, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Client } from '../../redux/reducers/clients'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  contactAdressContainer: {
    margin: theme.spacing(1),
  },
  contactAdressLabel: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  },
  contactAdress: {
    padding: theme.spacing(1),
  },
}))

interface Props {
  client: Client
  onClientContactAddressChange: (name: string) => (value: any) => void
}

export const ContactAdressInput: React.FC<Props> = ({ client, onClientContactAddressChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const onClientInputChangeCreator = (name: string) => (event: any) => {
    onClientContactAddressChange(name)(event.target.value)
  }

  return (
    <div className={classes.contactAdressContainer}>
      <Typography variant="h6" className={classes.contactAdressLabel}>
        {t('CONTACT_ADDRESS')}
      </Typography>
      <Paper variant="outlined" className={classes.contactAdress}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              fullWidth
              id="standard-basic"
              label={t('STREET_NUMBER')}
              onChange={onClientInputChangeCreator('street')}
              value={client.contactAddress?.street}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              fullWidth
              id="standard-basic"
              label={t('CITY')}
              onChange={onClientInputChangeCreator('town')}
              value={client.contactAddress?.town}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              fullWidth
              id="standard-basic"
              label={t('ZIP_CODE')}
              onChange={onClientInputChangeCreator('zipCode')}
              value={client.contactAddress?.zipCode}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              fullWidth
              id="standard-basic"
              label={t('COUNTRY')}
              onChange={onClientInputChangeCreator('country')}
              value={client.contactAddress?.country}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
