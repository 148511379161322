import { ReduxAction } from '../../types/common'
import { GET_CLIENT_FILE, POST_CLIENT_FILE, REQUEST_GET_CLIENT_FILE, REQUEST_POST_CLIENT_FILE, SET_ERRORS } from '../actionTypes'

export interface ClientFile {
  clientFileId: number,
  clientId: number
  fileContent: string,
  fileContentPrefix: string,
  fileName: string
  date: Date,
  saved: boolean
}

interface clientFileState {
  clientFiles: ClientFile[],
  isClientFilesSaving: boolean,
  isClientFilesGeting: boolean
}

const INITIAL_STATE = {
  clientFiles: [],
  isClientFilesSaving: false,
  isClientFilesGeting: false
}

export const clientFiles = (state: clientFileState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_CLIENT_FILE:
      return {
        ...state,
        clientFiles: action.payload,
        isClientFilesSaving: false,
        isClientFilesGeting: false
      }
    case REQUEST_GET_CLIENT_FILE:
      return {
        ...state,
        clientFiles: state.clientFiles,
        isClientFilesSaving: false,
        isClientFilesGeting: true
      }
    case POST_CLIENT_FILE:
      return {
        ...state,
        clientFiles: action.payload,
        isClientFilesSaving: false,
        isClientFilesGeting: false
      }
    case REQUEST_POST_CLIENT_FILE:
      return {
        ...state,
        clientFiles: state.clientFiles,
        isClientFilesSaving: true,
        isClientFilesGeting: false
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        isClientFilesSaving: false,
        isClientFilesGeting: false
      }
    default:
      return state
  }
}

export interface IqValues {
  IQ_1_1_valid: boolean
  IQ_1_1_A: boolean
  IQ_1_1_B: boolean
  IQ_1_1_C: boolean
  IQ_1_1_D: boolean

  IQ_1_2_valid: boolean
  IQ_1_2_A: boolean
  IQ_1_2_B: boolean
  IQ_1_2_C: boolean
  IQ_1_2_D: boolean

  IQ_1_3_valid: boolean
  IQ_1_3_A: boolean
  IQ_1_3_B: boolean
  IQ_1_3_C: boolean
  IQ_1_3_D: boolean

  IQ_1_4_valid: boolean
  IQ_1_4_A: boolean
  IQ_1_4_B: boolean
  IQ_1_4_C: boolean
  IQ_1_4_D: boolean

  IQ_1_5_valid: boolean
  IQ_1_5_A: boolean
  IQ_1_5_B: boolean
  IQ_1_5_C: boolean
  IQ_1_5_D: boolean

  IQ_1_6_valid: boolean
  IQ_1_6_A: boolean
  IQ_1_6_B: boolean
  IQ_1_6_C: boolean
  IQ_1_6_D: boolean

  IQ_1_7_valid: boolean
  IQ_1_7_A: boolean
  IQ_1_7_B: boolean
  IQ_1_7_C: boolean
  IQ_1_7_D: boolean

  IQ_1_8_valid: boolean
  IQ_1_8_A: boolean
  IQ_1_8_B: boolean
  IQ_1_8_C: boolean
  IQ_1_8_D: boolean

  IQ_1_9_valid: boolean
  IQ_1_9_A: boolean
  IQ_1_9_B: boolean
  IQ_1_9_C: boolean
  IQ_1_9_D: boolean

  IQ_1_10_valid: boolean
  IQ_1_10_A: boolean
  IQ_1_10_B: boolean
  IQ_1_10_C: boolean
  IQ_1_10_D: boolean

  IQ_1_11_valid: boolean
  IQ_1_11_A: boolean
  IQ_1_11_B: boolean
  IQ_1_11_C: boolean
  IQ_1_11_D: boolean

  IQ_1_12_valid: boolean
  IQ_1_12_A: boolean
  IQ_1_12_B: boolean
  IQ_1_12_B_input: string
  IQ_1_12_C: boolean
  IQ_1_12_C_input: string
  IQ_1_12_D: boolean

  IQ_2_1_valid: boolean
  IQ_2_1_A: boolean
  IQ_2_2_valid: boolean
  IQ_2_2_A: boolean
  IQ_2_2_B: boolean
  IQ_2_2_C: boolean
  IQ_2_3_valid: boolean
  IQ_2_3_A: boolean
  IQ_2_3_B: boolean
  IQ_2_3_C: boolean
  IQ_2_4_valid: boolean
  IQ_2_4_A: boolean
  IQ_2_4_B: boolean
  IQ_2_4_C: boolean
  IQ_2_5_valid: boolean
  IQ_2_5_A: boolean
  IQ_2_5_B: boolean
  IQ_2_5_C: boolean
  IQ_2_6_valid: boolean
  IQ_2_6_A: boolean
  IQ_2_6_B: boolean
  IQ_2_6_C: boolean
  IQ_2_7_valid: boolean
  IQ_2_7_A: boolean
  IQ_2_7_B: boolean
  IQ_2_7_C: boolean
  IQ_2_8_valid: boolean
  IQ_2_8_A: boolean
  IQ_2_8_B: boolean
  IQ_2_8_C: boolean
  IQ_2_9_valid: boolean
  IQ_2_9_A: boolean
  IQ_2_9_B: boolean
  IQ_2_9_C: boolean
  IQ_2_10_valid: boolean
  IQ_2_10_A: boolean
  IQ_2_10_B: boolean
  IQ_2_10_C: boolean
  IQ_2_11_valid: boolean
  IQ_2_11_A: boolean
  IQ_2_11_B: boolean
  IQ_2_11_C: boolean

  IQ_2_12_valid: boolean
  IQ_2_12_A: boolean
  IQ_2_12_B: boolean
  IQ_2_12_C: boolean
  IQ_2_13_valid: boolean
  IQ_2_13_A: boolean
  IQ_2_13_B: boolean
  IQ_2_13_C: boolean
  IQ_2_14_valid: boolean
  IQ_2_14_A: boolean
  IQ_2_14_B: boolean
  IQ_2_14_C: boolean
  IQ_2_15_valid: boolean
  IQ_2_15_A: boolean
  IQ_2_15_B: boolean
  IQ_2_15_C: boolean
  IQ_2_16_valid: boolean
  IQ_2_16_A: boolean
  IQ_2_16_B: boolean
  IQ_2_16_C: boolean
  IQ_2_17_valid: boolean
  IQ_2_17_A: boolean
  IQ_2_17_B: boolean
  IQ_2_17_C: boolean
  IQ_2_18_valid: boolean
  IQ_2_18_A: boolean
  IQ_2_18_B: boolean
  IQ_2_18_C: boolean
  IQ_2_19_valid: boolean
  IQ_2_19_A: boolean
  IQ_2_19_B: boolean
  IQ_2_19_C: boolean

  IQ_3_1_valid: boolean
  IQ_3_1_A: boolean
  IQ_3_1_B: boolean
  IQ_3_1_C: boolean
  IQ_3_1_D: boolean

  IQ_3_2_valid: boolean
  IQ_3_2_A: boolean
  IQ_3_2_B: boolean
  IQ_3_2_C: boolean
  IQ_3_2_D: boolean
  IQ_3_2_E: boolean

  IQ_3_3_valid: boolean
  IQ_3_3_A: boolean
  IQ_3_3_B: boolean
  IQ_3_3_C: boolean
  IQ_3_3_D: boolean

  IQ_3_4_valid: boolean
  IQ_3_4_A: boolean
  IQ_3_4_B: boolean
  IQ_3_4_C: boolean
  IQ_3_4_D: boolean
  IQ_3_4_E: boolean
  IQ_3_4_E_input: string

  IQ_3_5_valid: boolean
  IQ_3_5_A: boolean
  IQ_3_5_B: boolean
  IQ_3_5_C: boolean
  IQ_3_5_D: boolean
  IQ_3_5_E: boolean

  IQ_4_1_valid: boolean
  IQ_4_1_A: boolean
  IQ_4_1_B: boolean

  IQ_4_2_valid: boolean
  IQ_4_2_A: boolean
  IQ_4_2_B: boolean
  IQ_4_2_C: boolean
  IQ_4_2_D: boolean

  IQ_4_3_valid: boolean
  IQ_4_3_A: boolean
  IQ_4_3_B: boolean
  IQ_4_3_C: boolean
  IQ_4_3_D: boolean
  IQ_4_3_E: boolean

  IQ_4_4_valid: boolean
  IQ_4_4_A: boolean
  IQ_4_4_B: boolean
  IQ_4_4_C: boolean
  IQ_4_4_D: boolean

  IQ_4_5_valid: boolean
  IQ_4_5_A: boolean
  IQ_4_5_B: boolean

  IQ_6_1_valid: boolean
  IQ_6_1_A_input: string
  IQ_6_1_A: boolean
  IQ_6_1_B: boolean
  IQ_6_1_C: boolean
  IQ_6_1_D: boolean
  IQ_6_1_D_input: string

  IQ_6_2_valid: boolean
  IQ_6_2_A: boolean
  IQ_6_2_B: boolean
  IQ_6_2_C: boolean
  IQ_6_2_D: boolean

  IQ_6_3_valid: boolean
  IQ_6_3_A: boolean
  IQ_6_3_B: boolean
  IQ_6_3_C: boolean
  IQ_6_3_D: boolean
  IQ_6_3_D_input: string

  IQ_6_4_valid: boolean
  IQ_6_4_A: boolean
  IQ_6_4_B: boolean
  IQ_6_4_C: boolean
  IQ_6_4_C_input: string

  IQ_6_5_valid: boolean
  IQ_6_5_A: boolean
  IQ_6_5_A_input1: string
  IQ_6_5_B: boolean
  IQ_6_5_C: boolean
  IQ_6_5_D: boolean

  IQ_6_6_valid: boolean
  IQ_6_6_A: boolean
  IQ_6_6_A_input1: string
  IQ_6_6_A_input2: string
  IQ_6_6_A_input3: string
  IQ_6_6_A_input4: string
  IQ_6_6_A_input5: string
  IQ_6_6_A_input6: string
  IQ_6_6_B: boolean
  IQ_6_6_C: boolean
  IQ_6_6_D: boolean


  IQ_6_7_A_input1: string
  IQ_6_7_A_input2: string
  IQ_6_7_A_input3: string
  IQ_6_7_A_input4: string
}

export interface IqResult {
  category: string
  skills: string
  horizonte: string
  investmentGoal: string
  lossAbility: string
  risk: string
  esg: string
  total: string

  totalPoint: number
  skillsPoint: number
  experiencesPoint: number
  financialGoals: number
  financialGround: number
}
