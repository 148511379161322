import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { DropZone } from '../common/DropZone'
import { Client } from '../../redux/reducers/clients'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  actionButtons: {
    width: '100%',
  },
  border: {
    border: `2px dashed ${theme.palette.grey[500]}`,
  },
}))

interface Props {
  client: Client
  handleClose: () => void
  handleSave: () => void
  displaySection: boolean
}

export const ClientInformationDialogFooter: React.FC<Props> = ({ client, displaySection, handleClose, handleSave }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      {displaySection && (
        <Grid item xs={12}>
          <DropZone defaultText={t('INSERT_ID')} className={classes.border} />
        </Grid>
      )}
      {/*displaySection &&
                <Grid item xs={6}>
                    <DropZone defaultText="Vložte podepsaný investiční dotazník" className={classes.border}/>
    </Grid>*/}
      <Grid item xs={6}>
        <Button
          className={classes.actionButtons}
          size="large"
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          {t('CANCEL')}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button className={classes.actionButtons} size="large" variant="contained" color="primary" onClick={handleSave}>
          {t('DONE')}
        </Button>
      </Grid>
    </Grid>
  )
}
