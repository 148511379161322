import { DefaultTheme } from '@mui/styles/defaultTheme'
import { newThemeObject } from '../newTheme'

export const instrumentStyles = (theme: DefaultTheme) => {
  return {
    iconTypeEQTY: {
      backgroundColor: newThemeObject.palette.instrument.EQTY,
    },
    iconTypeB: {
      backgroundColor: newThemeObject.palette.instrument.TypeB,
    },
    iconTypeBOND: {
      backgroundColor: newThemeObject.palette.instrument.BOND,
    },
    iconTypeE: {
      backgroundColor: newThemeObject.palette.instrument.E,
    },
    iconTypeETF: {
      backgroundColor: newThemeObject.palette.instrument.ETF,
    },
    iconTypeDERIV: {
      backgroundColor: theme.palette.secondary.contrastText,
    },
    iconTypeHYBRID: {
      backgroundColor: theme.palette.secondary.contrastText,
    },
    iconTypeMM: {
      backgroundColor: theme.palette.success.main,
    },
    iconTypeOTHER: {
      backgroundColor: theme.palette.grey[400],
    },
  }
}
