import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({}))

interface Props {
  alertOpen: boolean
  onOk: () => void
  alertText: string
  buttonText?: string
}

export const MessageAlert: React.FC<Props> = ({ alertOpen, onOk, alertText, buttonText }) => {
  return (
    <div>
      <Dialog open={alertOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{alertText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={onOk}>
            {!buttonText && <>Potvrdit</>}
            {buttonText && <>{buttonText}</>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
