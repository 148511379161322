import { Investment, InvestmentBody } from '../redux/reducers/investment'
import { fetchRequest } from '../services/http.service'

export const GET_INVESTMENT_URL = '/iq/'
export const POST_INVESTMENT_URL = '/iq/'

export const getInvestmentsRequest = (clientId: Number) => fetchRequest.get<Investment[]>(GET_INVESTMENT_URL + clientId)

export const postInvestmentRequest = (clientId: number, iqBody: InvestmentBody) =>
  fetchRequest.post(POST_INVESTMENT_URL + clientId, iqBody)
