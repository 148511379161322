import { addMinutes } from 'date-fns/esm'

interface NumberFormatOptions {
  decimalSeparator?: string
  thousandsSeparator?: string
  decimalPlaces?: number
  prefix?: string
  suffix?: string
  space?: boolean
}
/**
 * Basic number forrmater
 * @param number formated number
 * @param {NumberFormatOptions} options options like decimal separator etc.
 * @returns {string}
 */
export const formatNumber = (number: number, options: NumberFormatOptions = {}): string => {
  const {
    decimalSeparator = ',',
    thousandsSeparator = ' ',
    decimalPlaces = 2,
    prefix = '',
    suffix = '',
    space = false,
  } = options

  if (!number) {
    return '0'
  }

  let formattedNumber = number.toFixed(decimalPlaces).toString()

  let [integerPart, decimalPart] = formattedNumber.split('.')

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)

  formattedNumber = integerPart + (decimalPart ? decimalSeparator + decimalPart : '')

  formattedNumber = `${prefix + formattedNumber}${space ? ' ' : ''}${suffix}`

  return formattedNumber
}

interface CountryFormat {
  countryCode: string
  format: RegExp
}

const countryFormats: CountryFormat[] = [
  { countryCode: '420', format: /(\d{3})(\d{3})(\d{3})/ },
  { countryCode: '1', format: /(\d{3})(\d{3})(\d{4})/ },
  { countryCode: '44', format: /(\d{4})(\d{3})(\d{4})/ },
  { countryCode: '421', format: /(\d{3})(\d{3})(\d{3})/ },
]

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return ''

  let cleanedNumber = phoneNumber.replace(/\D/g, '')

  for (const country of countryFormats) {
    if (cleanedNumber.startsWith(country.countryCode)) {
      const numberWithoutCode = cleanedNumber.substring(country.countryCode.length)
      const formattedNumber = numberWithoutCode.replace(country.format, '$1 $2 $3')
      return `+${country.countryCode} ${formattedNumber}`
    }
  }
  if (cleanedNumber.length === 9) {
    return `+420 ${cleanedNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')}`
  }

  if (cleanedNumber.length === 10) {
    return `${cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`
  }
  return cleanedNumber
}
