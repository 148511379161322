import { EventSourcePolyfill } from 'event-source-polyfill'
import { SEE_ORDER } from '../redux/actionTypes'
import { checkKeycloakAuthentication, keycloak } from '../services/keycloak.service'

const baseURL = process.env.REACT_APP_API
let sseOrder: EventSourcePolyfill

const startSseOrder = (clientId: number | null, dateFrom: Date, dateTo: Date) => {
  // console.log('startSseOrder');
  checkKeycloakAuthentication()
  sseOrder = new EventSourcePolyfill(clientId ? `${baseURL}/sse/order?clientId=${clientId}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}` : `${baseURL}/sse/order?&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`, {
    headers: { Authorization: `Bearer ${keycloak.token}` },
  })
}

const onerrorSseOrder = (dispatch: any, clientId: number | null, dateFrom: Date, dateTo: Date) => (e: any) => {
  // console.log('onerrorSseOrder', e);
  try {
    sseOrder.close()
  } catch (err) {
    console.log('close sseSecurityCheck', err)
  }

  initializeSseOrder(dispatch, clientId, dateFrom, dateTo)
}

export const messageSseOrder = (dispatch: any) => (eventResponse: any) => {
  const data = JSON.parse(eventResponse.data)
  dispatch({
    type: SEE_ORDER,
    payload: data != null ? data : null,
  })
}

export const closeSseOrder = () => {
  // console.log('closeSseOrder');
  if (sseOrder) {
    sseOrder.close()
  }
}

export const initializeSseOrder = (dispatch: any, clientId: number | null, dateFrom: Date, dateTo: Date) => {
  // console.log('initializeSseOrder', dateFrom, dateTo);
  try {
    sseOrder.close()
  } catch (err) {}
  startSseOrder(clientId, dateFrom, dateTo)
  sseOrder.addEventListener('message', messageSseOrder(dispatch))
  sseOrder.addEventListener('error', onerrorSseOrder(dispatch, clientId, dateFrom, dateTo))
}
