import { useSelector } from '../redux/hooks/useSelector'
import { OrdersByStateOptions } from '../redux/reducers/filters'
import { OrderTableRow } from '../redux/reducers/orders'
import { RootState } from '../redux/reducers/root'
import {
  ORDER_STATE_CANCELED,
  ORDER_STATE_CONFIRMED,
  ORDER_STATE_ERROR,
  ORDER_STATE_EXPIRED,
  ORDER_STATE_FILLED,
  ORDER_STATE_NEW,
  ORDER_STATE_PARTIAL_CANCELED,
  ORDER_STATE_PARTIAL_EXPIRED,
  ORDER_STATE_PARTIAL_FILLED,
  ORDER_STATE_REJECTED,
} from '../constants/codeLists'
import { useEffect, useState } from 'react'

export const useOrderFilters = () => {
  // const orderTableRows: OrderTableRow[] = useSelector((state: RootState) => state.orders.orderTableRows)
  const orderSse = useSelector(state => state.orders.orderSse)
  const filters = useSelector((state: RootState) => state.filters)

  const [orderTableRows, setOrderTableRows] = useState([] as OrderTableRow[])

  useEffect(() => {
    if (orderSse && orderSse.userOrders && orderSse.userOrders.length > 0) {
      setOrderTableRows([...orderSse.userOrders[0].orders])
    }
  }, [orderSse])

  let filteredTableRows = orderTableRows.filter(
    (order: OrderTableRow) =>
      `${order.clientFirstname}${order.clientSurname}`
        .toLowerCase()
        .includes(filters.searchedClientName.toLowerCase()) &&
      (order.amount || 0) >= filters.searchedAmountUp &&
      (order.amount || 0) <= (filters.searchedAmountDown === '' ? Infinity : filters.searchedAmountDown) &&
      new Date(order.orderCreationDate) > filters.searchedDateFrom &&
      new Date(order.orderCreationDate) < filters.searchedDateTo &&
      `${order.securityName}`.toLowerCase().includes(filters.searchedInstrumentName.toLowerCase()) &&
      `${order.currency}`.toLowerCase().includes(filters.searchedCurrencyName.toLowerCase()) &&
      `${order.orderMarketType}`.toLowerCase().includes(filters.searchedOrderType.toLowerCase())
  )

  switch (filters.sortOrdersBy) {
    case OrdersByStateOptions.New:
      filteredTableRows = filteredTableRows.filter(row => row.state === ORDER_STATE_NEW)
      break
    case OrdersByStateOptions.Open:
      filteredTableRows = filteredTableRows.filter(row => row.state === ORDER_STATE_CONFIRMED)
      break
    case OrdersByStateOptions.Partial:
      filteredTableRows = filteredTableRows.filter(row => row.state === ORDER_STATE_PARTIAL_FILLED)
      break
    case OrdersByStateOptions.Rejected:
      filteredTableRows = filteredTableRows.filter(row => row.state === ORDER_STATE_REJECTED)
      break
    case OrdersByStateOptions.Closed:
      filteredTableRows = filteredTableRows.filter(
        row =>
          row.state === ORDER_STATE_FILLED ||
          row.state === ORDER_STATE_CANCELED ||
          row.state === ORDER_STATE_PARTIAL_EXPIRED ||
          row.state === ORDER_STATE_PARTIAL_CANCELED ||
          row.state === ORDER_STATE_EXPIRED ||
          row.state === ORDER_STATE_ERROR
      )
      break
  }
  return filteredTableRows
}

export const useOrderFiltersDates = () => {
  // const orderTableRows: OrderTableRow[] = useSelector((state: RootState) => state.orders.orderTableRows)
  const orderSse = useSelector(state => state.orders.orderSse)
  const filters = useSelector((state: RootState) => state.filters)

  const [orderTableRows, setOrderTableRows] = useState([] as OrderTableRow[])

  useEffect(() => {
    if (orderSse && orderSse.userOrders && orderSse.userOrders.length > 0) {
      setOrderTableRows([...orderSse.userOrders[0].orders])
    }
  }, [orderSse])

  let filteredTableRows = orderTableRows.filter(
    (order: OrderTableRow) =>
      new Date(order.orderCreationDate) > filters.searchedDateFrom &&
      new Date(order.orderCreationDate) < filters.searchedDateTo
  )

  return filteredTableRows
}
