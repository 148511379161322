import React, { useEffect, useRef, useState } from 'react'
import { AutoSizer, Column, defaultTableRowRenderer, Table } from 'react-virtualized'
import makeStyles from '@mui/styles/makeStyles'
import 'react-virtualized/styles.css'
import { useTranslation } from 'react-i18next'
import {
  CATEGORY,
  CURRENCY,
  DATA_FROM,
  EXPERIENCE,
  HORIZONTE,
  HORIZONT_VALUE,
  ISIN,
  LOSS_ABILITY,
  MARKET,
  MOVEMENT,
  NAME,
  PREFERENCE,
  PRICE,
  RISK,
  RISK_VALUE,
  TICKER,
} from '../../constants/textConstants'
import { ApexOptions } from 'apexcharts'
import { Security, SecurityPrices } from '../../redux/reducers/securities'
import cs from 'apexcharts/dist/locales/cs.json'
import { TableRowProps } from 'react-virtualized/dist/es/Table'
import { Box, Typography } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useSelector } from '../../redux/hooks/useSelector'
// @ts-ignore
import styleCss from './VirtualizedTableBody.css'
import { INSTRUMENT_INPUT_DIALOG_ID, ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { ColoredIconButton } from '../common/ColoredIconButton'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import AddIcon from '@mui/icons-material/Add'
import { instrumentStyles } from '../../utils/instrumentIconStyles'
import { muiTheme } from '../../muiTheme'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Edit } from '@mui/icons-material'

const useStyles = makeStyles(theme => ({
  tableRowBig: {
    display: 'flex',
    flexDirection: 'row',
    height: '150px',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  textColor: {
    // color: theme.palette.primary.dark,
    color: 'black',
  },
  boldText: {
    fontWeight: 'bold',
  },
  separatorLeftSmall: {
    marginLeft: theme.spacing(1.5),
  },
  separator: {
    flexGrow: 1,
  },
  separatorLeft: {
    marginLeft: theme.spacing(1),
  },
  separatorLeftHuge: {
    marginLeft: theme.spacing(10),
  },
  separatorLeftLarge: {
    marginLeft: theme.spacing(5),
  },
  borderColorShare: {
    // borderColor: theme.palette.error.main,
    borderColor: 'red',
  },
  borderColorFcMarket: {
    borderColor: 'white',
    // borderColor: theme.palette.success.main,
  },
  borderColorBond: {
    borderColor: 'white',
    // borderColor: theme.palette.primary.main,
  },
  borderColorOther: {
    borderColor: 'white',
    // borderColor: theme.palette.grey[600],
  },
  textColorGreen: {
    // color: theme.palette.success.main,
    color: 'green',
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '500px',
    minWidth: '200px',
  },
  instrumentTableButton: {
    height: '15px',
    width: '15px',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.2),
  },
  buttonIcon: {
    height: '12px',
    width: '12px',
  },
  iconSize: {
    height: '20px',
    width: '30px',
    lineHeight: '15px',
    fontSize: '8px',
    marginTop: theme.spacing(0.2),
  },
  market: {
    width: '100px',
  },
}))

interface InstrumentTypeRendererProps {
  rowData: Security
}

const InstrumentTypeRenderer = ({ rowData }: InstrumentTypeRendererProps) => {
  return (
    <div
      style={{
        // @ts-ignore
        ...instrumentStyles(muiTheme)[`iconType${rowData.assetClass || 'E'}`],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: muiTheme.palette.secondary.main,
        fontWeight: 'bold',
        height: '20px',
        width: '30px',
        lineHeight: '15px',
        fontSize: '8px',
        marginTop: muiTheme.spacing(0.2),
      }}
    >
      {rowData.assetClass}
    </div>
  )
}

const LastPriceIndicationRenderer = ({ rowData }: InstrumentTypeRendererProps) => {
  // @ts-ignore
  if (!rowData.lastPriceIndication) {
    return null
  }

  // @ts-ignore
  if (rowData.lastPriceIndication > 0) {
    return (
      <div style={{ color: 'green', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <ArrowUpwardIcon
          style={{
            color: muiTheme.palette.success.main,
            fontSize: 14,
            marginTop: '4px',
          }}
        />
        <Typography>{rowData.lastPriceIndication}%</Typography>
      </div>
    )
  }
  // @ts-ignore
  else if (rowData.lastPriceIndication < 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <ArrowDownwardIcon
          style={{
            color: muiTheme.palette.error.main,
            fontSize: 14,
            marginTop: '4px',
          }}
        />
        <Typography>{rowData.lastPriceIndication}%</Typography>
      </div>
    )
  }
  return null
}

interface Props {
  list: Security[]
  setIsUpdate: (params: any) => any
}

const VirtualizedTableBody = ({ list, setIsUpdate }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const tableRef = useRef()
  const classes = useStyles()
  const { getSecurityPrices } = useInstrumentApi()
  const dispatch = useDispatch()
  const { getSecurity } = useInstrumentApi()

  // @ts-ignore
  const securityPrices = useSelector<SecurityPrices>(state => state.securities.securityPrices)

  interface DetailsProps {
    children: string
    index: number
  }

  const Details = ({ children, index }: DetailsProps) => (
    <div style={{ cursor: 'pointer' }} onClick={() => setSelectedIndex(index)}>
      {children}
    </div>
  )

  interface NumberProps {
    index: number
  }

  const _getRowHeight = ({ index }: NumberProps) => (index === selectedIndex ? 400 : 50)
  const rowGetter = ({ index }: NumberProps) => list[index]

  interface RowProps {
    rowIndex: number
    cellData?: any
  }

  const cellRenderer = ({ rowIndex }: RowProps) => {
    if (rowIndex !== selectedIndex) {
      return <Details index={rowIndex}>+</Details>
    } else {
      return <Details index={-1}>-</Details>
    }
  }

  const cellRenderer2 = ({ rowIndex }: RowProps) => {
    return (
      <ColoredIconButton
        className={`${classes.instrumentTableButton} ${classes.separatorLeft}`}
        size="small"
        icon={<Edit fontSize="small" className={classes.buttonIcon} />}
        onClick={() => {
          setIsUpdate(true)
          getSecurity(list[rowIndex].securityId)
          dispatch(openDialog(INSTRUMENT_INPUT_DIALOG_ID))
        }}
      />
    )
  }

  const cellRendererWithTranslation = ({ cellData }: RowProps) => {
    if (cellData == null) {
      return ''
    } else {
      return t(String(cellData))
    }
  }

  useEffect(() => {
    if (list[selectedIndex] && list[selectedIndex].securityId) {
      getSecurityPrices(list[selectedIndex].securityId)
    }
    if (tableRef && tableRef.current) {
      // @ts-ignore
      tableRef.current.recomputeRowHeights()
    }
  }, [selectedIndex])

  useEffect(() => {
    if (securityPrices) {
      setSeriesGraphLine([
        {
          // @ts-ignore
          name: 'Majetek',
          // @ts-ignore
          data: securityPrices.prices.map((a: any) => {
            return [new Date(a.date).getTime(), a.amount]
          }),
        },
      ])
    }
  }, [securityPrices])

  const [optionsGraphLine, setOptionsGraphLine] = React.useState<ApexOptions>({
    chart: {
      height: 350,
      zoom: {
        autoScaleYaxis: false,
      },
      toolbar: {
        show: true,
      },
      locales: [cs], //or multi language like [fa, en]
      defaultLocale: 'cs',
    },
    grid: {
      show: false,
    },
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: '#999',
          label: {
            style: {
              color: '#fff',
              background: '#00E396',
            },
          },
        },
      ],
      xaxis: [
        {
          x: new Date(new Date().getFullYear(), 0, 1).getTime(),
          borderColor: '#999',
          label: {
            style: {
              color: '#fff',
              background: '#775DD0',
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      min: new Date(new Date().getFullYear(), 0, 1).getTime(),
      tickAmount: 6,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val ? val.toLocaleString() : ''
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    markers: {
      size: [0, 7, 7],
      colors: ['blue', 'green', 'red'],
    },
    stroke: {
      show: false,
    },
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
    },
  })
  const [seriesGraphLine, setSeriesGraphLine] = React.useState([
    {
      data: [],
    },
  ])

  function addMonths(date: Date, months: number) {
    let d = date.getDate()
    date.setMonth(date.getMonth() + +months)
    if (date.getDate() != d) {
      date.setDate(0)
    }
    return date
  }

  useEffect(() => {
    setSeriesGraphLine([
      {
        // @ts-ignore
        type: 'area',
        name: 'Tržní cena',
        // @ts-ignore
        data: [],
      },
    ])
  }, [selectedIndex])

  const rowRenderer = (props: TableRowProps) => {
    const { index, style, className, key, rowData } = props
    if (index === selectedIndex) {
      return (
        <div style={{ ...style, display: 'flex', flexDirection: 'column' }} className={className} key={key}>
          {defaultTableRowRenderer({
            ...props,
            style: { width: style.width, height: 400 },
          })}

          <Box sx={{ display: 'flex', flexDirection: 'row', height: 400, alignSelf: 'center' }}>
            <div id={`area-datetime${index}`}>
              <ReactApexChart options={optionsGraphLine} series={seriesGraphLine} type="area" height={400} />
            </div>
          </Box>
        </div>
      )
    }
    return defaultTableRowRenderer({
      // @ts-ignore
      ...props,
      style: { ...style, width: style.width, height: '38px' },
    })
  }

  const _noRowsRenderer = () => {
    return <div className={styleCss.noRows}>No rows</div>
  }

  const _rowClassName = ({ index }: NumberProps) => {
    if (index < 0) {
      return styleCss.headerRow
    } else {
      return index % 2 === 0 ? styleCss.evenRow : styleCss.oddRow
    }
  }

  const { t } = useTranslation()

  return (
    <div style={{ height: '90vh', width: '100%' }}>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            headerHeight={38}
            height={height}
            noRowsRenderer={_noRowsRenderer}
            rowClassName={_rowClassName}
            overscanRowCount={10}
            rowHeight={_getRowHeight}
            rowGetter={rowGetter}
            rowCount={list.length}
            width={width}
            // @ts-ignore
            ref={tableRef}
            rowRenderer={rowRenderer}
            headerClassName={styleCss.headerColumn}
          >
            <Column
              flexGrow={1}
              cellDataGetter={({ rowData }) => rowData}
              cellRenderer={cellRenderer}
              dataKey="securityId"
              disableSort
              width={25}
            />

            <Column
              label="Typ"
              cellDataGetter={({ rowData }) => rowData}
              // @ts-ignore
              cellRenderer={InstrumentTypeRenderer}
              dataKey="assetClass"
              disableSort
              flexGrow={1}
              width={50}
            />
            <Column dataKey="name" disableSort label={t(NAME)} flexGrow={1} width={300} />
            <Column dataKey="isin" disableSort label={t(ISIN)} flexGrow={1} width={150} />
            <Column dataKey="market" disableSort label={t(MARKET)} flexGrow={1} width={75} />
            <Column dataKey="currency" disableSort label={t(CURRENCY)} flexGrow={1} width={75} />
            <Column dataKey="ticker" disableSort label={t(TICKER)} flexGrow={1} width={75} />
            <Column
              dataKey="category"
              disableSort
              label={t(CATEGORY)}
              flexGrow={1}
              cellRenderer={cellRendererWithTranslation}
              width={100}
            />
            <Column
              dataKey="skillAndExperience"
              disableSort
              label={t(EXPERIENCE)}
              flexGrow={1}
              cellRenderer={cellRendererWithTranslation}
              width={100}
            />
            <Column
              dataKey="preference"
              disableSort
              label={t(PREFERENCE)}
              flexGrow={1}
              cellRenderer={cellRendererWithTranslation}
              width={100}
            />
            <Column
              dataKey="lossAbility"
              disableSort
              label={t(LOSS_ABILITY)}
              flexGrow={1}
              cellRenderer={cellRendererWithTranslation}
              width={125}
            />
            <Column dataKey="lastQuotePrice" disableSort label={t(PRICE)} flexGrow={1} width={75} />
            <Column
              label={t(MOVEMENT)}
              cellDataGetter={({ rowData }) => rowData}
              // @ts-ignore
              cellRenderer={LastPriceIndicationRenderer}
              dataKey="lastPriceIndication"
              disableSort
              flexGrow={1}
              width={75}
            />

            <Column dataKey="lastQuoteDate" disableSort label={t(DATA_FROM)} flexGrow={1} width={125} />
            <Column dataKey="riskValue" disableSort label={t(RISK)} flexGrow={1} width={70} />
            <Column dataKey="horizonteValue" disableSort label={t(HORIZONTE)} flexGrow={1} width={60} />

            <Column
              flexGrow={1}
              cellDataGetter={({ rowData }) => rowData}
              cellRenderer={cellRenderer2}
              dataKey="securityId"
              disableSort
              width={50}
            />
          </Table>
        )}
      </AutoSizer>
    </div>
  )
}

export default VirtualizedTableBody
