import { OrderRowData } from '../components/OrderList/OrderTable'
import { Currency } from '../redux/reducers/currency'
import { Market } from '../redux/reducers/market'
import { Order, OrdersByStateCount, OrderTableRow } from '../redux/reducers/orders'
import { Security, SecurityPrices } from '../redux/reducers/securities'
import { fetchRequest } from '../services/http.service'
import { CodeList } from '../types/common'

//TODO split/rename instrumentApi not suitable

export const GET_SECURITY_URL = '/security/'
export const POST_SECURITY_URL = '/security'
export const PUT_SECURITY_URL = '/security'
export const GET_SECURITIES_URL = '/security'
export const GET_SECURITIES_BY_PARAM_URL = '/security/param?param='
export const GET_ORDER_URL = '/order/'
export const GET_ORDERS_URL = '/order'
export const POST_ORDER_URL = '/order/create'
export const GET_CODE_LIST_URL = '/codelist'
export const GET_CURRENCIES_URL = '/currency'
export const GET_MARKETS_URL = '/market'
export const POST_MASS_ORDER_URL = '/order/create/mass'
export const GET_ORDERS_BY_STATE_COUNT_URL = '/order/count'
export const GET_ORDER_TABLE_ROWS_URL = '/order/byparams'
export const UPDATE_ORDER_URL = '/order/update'
export const EXPORT_ORDERS_URL = '/order/export'
export const IMPORT_FILE_URL = '/import'
export const DELETE_ORDER_URL = '/order/delete'
export const DELETE_ORDERS_URL = '/order/delete/list'
export const GET_POSITIONS_URL = '/position?'

export const getSecurityPricesRequest = (securityId: Number) => {
  return fetchRequest.get<SecurityPrices>(GET_SECURITY_URL + `${securityId}/prices`)
}

export const getPositionsRequest = (clientId: String, portfolioId: Number, isin: String) => {
  if (portfolioId) {
    return fetchRequest.get<Security>(
      GET_POSITIONS_URL + `clientId=${clientId}&isin=${isin}&portfolioId=${portfolioId}`
    )
  } else {
    return fetchRequest.get<Security>(GET_POSITIONS_URL + `clientId=${clientId}&isin=${isin}`)
  }
}

export const getSecurityRequest = (securityId: Number) =>
  fetchRequest.get<Security>(GET_SECURITY_URL + `${securityId}`, securityId)
export const postSecurityRequest = (security: Security) => fetchRequest.post<Security>(POST_SECURITY_URL, security)
export const putSecurityRequest = (security: Security) => fetchRequest.put<Security>(PUT_SECURITY_URL, security)
export const getSecuritiesRequest = () => fetchRequest.get<Security[]>(GET_SECURITIES_URL)

export const getCodeListRequest = () => fetchRequest.get<CodeList[]>(GET_CODE_LIST_URL)
export const getCurrenciesRequest = () => fetchRequest.get<Currency[]>(GET_CURRENCIES_URL)
export const getMarketsRequest = () => fetchRequest.get<Market[]>(GET_MARKETS_URL)

export const getOrderRequest = (orderId: Number) => fetchRequest.get<Order>(GET_ORDER_URL + `${orderId}`, orderId)
export const getOrdersRequest = () => fetchRequest.get<Order[]>(GET_ORDERS_URL)
export const postOrderRequest = (order: Order, portfolioId: Number | null) =>
  fetchRequest.post<Order>(POST_ORDER_URL, order, order.portfolio.portfolioId)
export const postMassOrderRequest = (massOrder: Order[]) => fetchRequest.post<Order[]>(POST_MASS_ORDER_URL, massOrder)
export const getOrdersByStateCountRequest = (clientId: number | null) =>
  fetchRequest.get<OrdersByStateCount[]>(
    GET_ORDERS_BY_STATE_COUNT_URL + (clientId == null ? '' : '?clientId=' + clientId)
  )
export const getOrderTableRowsRequest = (
  state: string | null,
  clientName: string | null,
  clientId: number | null,
  securityName: string | null
) => {
  let queryParam = '?'
  if (clientName) {
    if (queryParam.length > 1) {
      queryParam += '&'
    }
    queryParam += 'clientName=' + clientName
  }

  if (clientId) {
    if (queryParam.length > 1) {
      queryParam += '&'
    }
    queryParam += 'clientId=' + clientId
  }

  if (securityName) {
    if (queryParam.length > 1) {
      queryParam += '&'
    }
    queryParam += 'securityName=' + securityName
  }

  return fetchRequest.get<OrderTableRow[]>(GET_ORDER_TABLE_ROWS_URL + queryParam)
}
export const updateOrderRequest = (order: Order) => fetchRequest.put<Order>(UPDATE_ORDER_URL, order)
export const exportOrdersRequest = () => fetchRequest.get<any>(EXPORT_ORDERS_URL)
export const importRequest = (orders: any) => fetchRequest.post<any>(IMPORT_FILE_URL, orders)
export const getInstrumentsByParamRequest = (param: any, all: boolean) =>
  fetchRequest.get<Security[]>(GET_SECURITIES_BY_PARAM_URL + `${param}&all=${all}`)
export const deleteOrderRequest = (order: Order) => fetchRequest.remove(DELETE_ORDER_URL, order)
export const deleteOrdersRequest = (orders: OrderRowData[]) => fetchRequest.remove(DELETE_ORDERS_URL, orders)
