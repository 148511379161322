import { Avatar, Skeleton } from '@mui/material'
import { useSelector } from '../../redux/hooks/useSelector'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { TransparentIconButton } from '../common/TransparentIconButton'
import { logout } from '../../services/keycloak.service'
import store from '../../redux/store'
import { isClientUser } from '../../services/keycloak.service'
import { EditRounded, Logout } from '@mui/icons-material'
import { addQuarters } from 'date-fns/fp'
import { newThemeObject } from '../../newTheme'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import { CLIENT_INFORMATION_DIALOG_ID } from '../../constants/dialogConstants'
import { useTranslation } from 'react-i18next'
import { formatPhoneNumber } from '../../utils/numbersFormatter'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    backgroundColor: newThemeObject.palette.orderTable.searchBar.bg,
    padding: theme.spacing(3),
    borderRadius: newThemeObject.roundness.basic,
    marginBottom: theme.spacing(2),
  },
  avatar: {
    height: '2rem',
    width: '2rem',
  },
  title: {
    color: newThemeObject.palette.main.primary,
    fontSize: '1rem',
    fontWeight: 700,
  },
  subtitle: {
    fontSize: '0.6rem',
  },
  text: {
    margin: 0,
  },
}))

const ClientCard = () => {
  const classes = useStyles()
  const client = useSelector(state => state.clients.client)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clientId } = useParams<{ clientId: string }>()

  let symbols
  console.log(client)
  let name = ''
  if (!!client.firstName) {
    name += client.firstName
  }
  if (!!client.surname) {
    if (name.length > 0) name += ' ' + client.surname
    else name += client.surname
  }
  if (!!client.firstName) {
    symbols = client?.firstName.charAt(0).toUpperCase() + client?.surname.charAt(0).toUpperCase()
  }

  return (
    <span>
      {clientId === client.clientId.toString() && (
        <div className={classes.wrapper}>
          <Avatar className={classes.avatar}>{symbols}</Avatar>
          <span>
            <p className={classNames(classes.text, classes.title)}>{name}</p>
            <p className={classNames(classes.text, classes.subtitle)}>{formatPhoneNumber(client.telephoneNumber)}</p>
          </span>
          <TransparentIconButton
            onClick={() => {
              dispatch(
                openDialog(CLIENT_INFORMATION_DIALOG_ID, {
                  dialogTitle: t('CLIENT_EDIT'),
                  client,
                  type: 'edit',
                })
              )
            }}
            icon={<EditRounded />}
          />
        </div>
      )}
      {clientId !== client.clientId.toString() && (
        <div className={classes.wrapper}>
          <Skeleton variant="circular" width={32} height={32} />
          <span>
            <Skeleton variant="text" width={120} height={22} />
            <Skeleton variant="text" width={80} height={17} />
          </span>
          <TransparentIconButton
            disabled={true}
            onClick={() => {
              dispatch(
                openDialog(CLIENT_INFORMATION_DIALOG_ID, {
                  dialogTitle: t('CLIENT_EDIT'),
                  client,
                  type: 'edit',
                })
              )
            }}
            icon={<EditRounded />}
          />
        </div>
      )}
    </span>
  )
}

export default ClientCard
