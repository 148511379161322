import { KeycloakProfile } from 'keycloak-js'
import { ReduxAction } from '../../types/common'
import { LOG_OUT, SET_USER_INFO } from '../actionTypes'

interface AuthState {
  user: null | KeycloakProfile
  userId: null | string
}

const INITIAL_STATE = {
  user: null,
  userId: null,
  errors: '',
}

export const auth = (state: AuthState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        userId: action.payload.userId,
        user: action.payload.user,
      }
    case LOG_OUT:
      return {
        ...state,
        user: null,
      }

    default:
      return state
  }
}
