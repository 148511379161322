import { useState } from 'react'
import { Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ContentTitle } from '../components/common/ContentTitle'
import { ActionAlert } from '../components/dialogs/ActionAlert'
import { ActionFailedAlert } from '../components/dialogs/ActionFailedAlert'
import { BasicLayout } from '../components/layouts/BasicLayout'
import { InstrumentTableFilters } from '../components/InstrumentList/InstrumentTableFilters'
import { InstrumentTableHeader } from '../components/InstrumentList/InstrumentTableHeader'
import { InstrumentTableBody } from '../components/InstrumentList/InstrumentTableBody'
import { TransparentIconButton } from '../components/common/TransparentIconButton'
import AppsIcon from '@mui/icons-material/Apps'
import { Pages } from '../router/router'
import { useHistory } from 'react-router-dom'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { OrderInputDialog } from '../components/dialogs/OrderInputDialog'
import { InstrumentInputDialog } from '../components/dialogs/InstrumentInputDialog'
import { useTranslation } from 'react-i18next'
import CardWrapper from '../components/common/CardWrapper'
import { NewLayout } from '../components/newLayout/NewLayout'

const useStyles = makeStyles(theme => ({
  orderDisplayPanel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  orderDisplayTable: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(6),
    flexGrow: 1,
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },

  clientEssentials: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  manIcon: {
    marginTop: theme.spacing(1),
  },
  clientPersonalInfoColumn: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
  test: {
    background: 'red',
    width: '200px',
    position: 'absolute',
    top: '20px',
    left: '-50px',
    opacity: '0.95',
    textAlign: 'center',
    lineHeight: '25px',
    fontSize: '16px',
    letterSpacing: '1px',
    color: '#f0f0f0',
    transform: 'rotate(-45deg)',
    paddingRight: '18px',
  },
  table: {
    marginTop: theme.spacing(2),
  },
}))

const ribon = process.env.REACT_APP_ENV_RIBBON

export const InstrumentList = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [isUpdate, setIsUpdate] = useState(false)

  return (
    <NewLayout pageName={t('INSTRUMENT_OVERVIEW')}>
      <InstrumentTableFilters setIsUpdate={setIsUpdate} />
      <CardWrapper className={classes.table}>
        <InstrumentTableBody setIsUpdate={setIsUpdate} />
      </CardWrapper>
      <ActionAlert />
      <ActionFailedAlert handleClose={()=>{}}/>
      <InstrumentInputDialog isUpdate={isUpdate} />
      {/* <OrderInputDialog client={null} portfolio={null}/> */}
    </NewLayout>
  )
}
