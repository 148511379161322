import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../redux/actions/dialogActions'
import { ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { Client } from '../../redux/reducers/clients'
import { ArrowSelect } from '../OrderInputDialog/ArrowSelect'
import { Security } from '../../redux/reducers/securities'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { newOrder } from '../../constants/initialStates'
import {
  HORIZONTE_VALUE_MAX,
  ORDER_DIRECTION,
  ORDER_MARKET_TYPE,
  ORDER_MARKET_TYPE_LIMIT,
  ORDER_MARKET_TYPE_MARKET,
  ORDER_STATE_NEW,
  ORDER_TYPE,
  ORDER_TYPE_AMOUNT,
  ORDER_TYPE_QUANTITY,
  ORDER_VALIDITY,
  ORDER_VALIDITY_DATE,
  RISK_VALUE_MAX,
} from '../../constants/codeLists'
import { codeListToArrowSelect } from '../../utils/codeListToArrowSelect'
import manS from '../../assets/manS.jpg'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import { Order } from '../../redux/reducers/orders'
import { Portfolio } from '../../redux/reducers/portfolio'
import { NumericInput } from '../common/NumericInput'
import NumberFormat from 'react-number-format'
import { useInputDialogColor } from '../../hooks/useInputDialogColor'
import { useTranslation } from 'react-i18next'
import { useOrderCoveredColor } from '../../hooks/useOrderCoveredColor'
import { useExchangeRate } from '../../hooks/useExchangeRate'
import { useStyles } from './OrderInputDialog.styles'
import { useOrderCoveredColorText } from '../../hooks/useOrderCoveredColorText'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@date-io/date-fns'
import { MessageAlert } from './MessageAlert'
import {
  categorySecValidation,
  lossAbilitySecValidation,
  preferenceSecValidation,
  skillAndExperienceSecValidation,
} from '../../utils/orderValidations'
import { useCurrencyApi } from '../../hooks/useCurrencyApi'
import { ClearConfirmationAlert } from '../massOrderInputDialog/ClearConfirmationAlert'

interface Props {
  client: Client
  portfolio: Portfolio
  direction?: string
}

export const OrderInputDialog: React.FC<Props> = ({ client, portfolio, direction }) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const { postOrder, getCodeList, codeList } = useInstrumentApi()
  const foundDialog = dialogs.find(dialog => dialog.id === 'ORDER_INPUT_DIALOG_ID')
  const open = !!foundDialog
  const security: Security | undefined = useSelector(state => state.securities.security)
  const [order, setOrder] = useState<Order>(newOrder)
  const [isCovered, setIsCovered] = useState(true)
  const [covered, setCovered] = useState(0)
  const instrumentIconType = useInstrumentIconType(!security ? null : security?.assetClass)
  // const orderPortfolio: Portfolio = useSelector(state => state.detailPortfolioState.portfolio)
  const getInputDialogColor = useInputDialogColor()
  const rates = useSelector(state => state.currency.rates)
  const { t } = useTranslation()
  const orderCoveredColor = useOrderCoveredColor()
  const orderCoveredColorText = useOrderCoveredColorText()
  const exchangeRate = useExchangeRate()
  const [accountBalance, setAccountBalance] = useState<number>(0)

  const [alertMessageOpen, setAlertMessageOpen] = useState(false)
  const [orderAlertOpen, setOrderAlertOpen] = useState(false)

  const [isID, setIsID] = useState(true)
  const [riskValue, setRiskValue] = useState(0)
  const [isRiskValue, setIsRiskValue] = useState(true)
  const [isRiskValueSec, setIsRiskValueSec] = useState(true)
  const [horizonteValue, setHorizonteValue] = useState(0)
  const [isHorizonteValue, setIsHorizonteValue] = useState(true)
  const [isHorizonteValueSec, setIsHorizonteValueSec] = useState(true)
  const [categorySec, setCategorySec] = useState('')
  const [isCategorySec, setIsCategorySec] = useState(true)
  const [skillAndExperienceSec, setSkillAndExperienceSec] = useState('')
  const [isSkillAndExperienceSec, setIsSkillAndExperienceSec] = useState(true)
  const [preferenceSec, setPreferenceSec] = useState('')
  const [isPreferenceSec, setIsPreferenceSec] = useState(true)
  const [lossAbilitySec, setLossAbilitySec] = useState('')
  const [isLossAbilitySec, setIsLossAbilitySec] = useState(true)

  const [clientIDNotSet, setClientIDNotSet] = useState(false)
  const [alertMessage2Open, setAlertMessage2Open] = useState(false)

  const [isSaving, setIsSaving] = useState(false)

  const { getRates } = useCurrencyApi()

  useEffect(() => {
    getCodeList()
    getRates('CZK')
  }, [])

  useEffect(() => {
    if (security && portfolio) {
      setOrder({
        ...order,
        cpty: { ...order.cpty, marketName: security.market, name: `${order.cpty.brokerName} - ${security.market}` },
        state: ORDER_STATE_NEW,
        validityDate: new Date(),
        currency: security.currency,
        securityId: security.securityId,
        price: security.lastQuotePrice,
        portfolio: {
          ...order.portfolio,
          portfolioId: portfolio.portfolioId,
          currency: portfolio.currency,
          name: portfolio.name,
        },
      })

      setRiskValue(0)
      setIsRiskValue(true)
      setIsRiskValueSec(true)
      setHorizonteValue(0)
      setIsHorizonteValue(true)
      setIsHorizonteValueSec(true)
      setCategorySec(security.category || '')
      setIsCategorySec(!categorySecValidation(client.category, security.category))
      setSkillAndExperienceSec(security.skillAndExperience || '')
      setIsSkillAndExperienceSec(
        !skillAndExperienceSecValidation(client.skillAndExperience, security.skillAndExperience)
      )
      setPreferenceSec(security.preference || '')
      setIsPreferenceSec(!preferenceSecValidation(client.preference, security.preference))
      setLossAbilitySec(security.lossAbility || '')
      setIsLossAbilitySec(!lossAbilitySecValidation(client.lossAbility, security.lossAbility))
      setIsID(
        !(
          !categorySecValidation(client.category, security.category) &&
          !skillAndExperienceSecValidation(client.skillAndExperience, security.skillAndExperience) &&
          !preferenceSecValidation(client.preference, security.preference) &&
          !lossAbilitySecValidation(client.lossAbility, security.lossAbility)
        )
      )

      if (
        client.riskProfile === null ||
        client.riskProfile === undefined ||
        client.investmentHorizonte === null ||
        client.investmentHorizonte === undefined ||
        client.category === null ||
        client.category === undefined ||
        client.skillAndExperience === null ||
        client.skillAndExperience === undefined ||
        client.preference === null ||
        client.preference === undefined ||
        client.lossAbility === null ||
        client.lossAbility === undefined
      ) {
        setClientIDNotSet(true)
      } else {
        setClientIDNotSet(false)
      }
    }
  }, [security])

  useEffect(() => {
    setOrder({
      ...newOrder,
      direction:
        foundDialog && foundDialog.payload && foundDialog.payload.direction ? foundDialog.payload.direction : 'OD_BUY',
      price: security ? security.lastQuotePrice : 0,
    })
    if (portfolio && portfolio.instrumentPosition) {
      for (let i = 0; i < portfolio.instrumentPosition.length; i++) {
        if (portfolio.instrumentPosition[i] && portfolio.instrumentPosition[i].type == 'ACCOUNT') {
          setAccountBalance(portfolio.instrumentPosition[i].amount)
        }
      }
    }
    if (!open) {
      setIsSaving(false)
    }
  }, [open])

  useEffect(() => {
    cover()
  }, [order.amount])

  useEffect(() => {
    cover()
  }, [order.direction])

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(ORDER_INPUT_DIALOG_ID))
    }
  }, [])

  const handleClose = () => {
    dispatch(closeDialog(ORDER_INPUT_DIALOG_ID))
  }

  const handleSave = () => {
    setIsSaving(true)
    if (isID) {
      if (!order.orderComment) {
        setAlertMessageOpen(true)
        return
      }
      setOrderAlertOpen(true)
    } else if (clientIDNotSet) {
      setAlertMessage2Open(true)
    } else {
      saveOrder()
    }
  }

  const saveOrder = () => {
    postOrder(order)
  }

  const orderAlertOk = () => {
    setOrderAlertOpen(false)
    saveOrder()
  }
  const orderAlertCancel = () => {
    setOrderAlertOpen(false)
  }

  const handleChangeArrowSelect = (name: string) => (value: string | number) => {
    setOrder({ ...order, [name]: value })
  }

  const handleChangeOrder = (name: string) => (event: any) => {
    setOrder({ ...order, [name]: event.target.value })
  }

  const handleDateChange = (date: Date | null) => {
    setOrder({ ...order, validityDate: date })
  }

  const handleCloseMessage = () => {
    setAlertMessageOpen(false)
  }

  const handleCloseMessage2 = () => {
    setAlertMessage2Open(false)
  }

  const onClientInputChange = (client: Client) => (event: any) => {
    let quantity = 0
    let amount = 0
    if (order.type === ORDER_TYPE_QUANTITY) {
      quantity = event.target.value
      amount = Math.round(((Number(quantity) || 0) * (order?.price || 0) + Number.EPSILON) * 100) / 100
    }
    if (order.type === ORDER_TYPE_AMOUNT) {
      amount = event.target.value
      quantity = Math.round(((Number(amount) || 0) / (order?.price || 0) + Number.EPSILON) * 1000000) / 1000000
    }

    setOrder({ ...order, amount: amount, quantity: quantity })
  }

  const cover = () => {
    if (!security || !portfolio || !order || !order.currency) return

    setCategorySec(security.category || '')
    setIsCategorySec(!categorySecValidation(client.category, security.category))
    setSkillAndExperienceSec(security.skillAndExperience || '')
    setIsSkillAndExperienceSec(!skillAndExperienceSecValidation(client.skillAndExperience, security.skillAndExperience))
    setPreferenceSec(security.preference || '')
    setIsPreferenceSec(!preferenceSecValidation(client.preference, security.preference))
    setLossAbilitySec(security.lossAbility || '')
    setIsLossAbilitySec(!lossAbilitySecValidation(client.lossAbility, security.lossAbility))

    let riskValueCalc: number = 0
    let horizonteValueCalc: number = 0
    let isRiskCalc: boolean = true
    let isRiskCalcSec: boolean = true
    let isHorizonteCalc: boolean = true
    let isHorizonteCalcSec: boolean = true

    let coverCal: number = 0
    let currencyBalance: number = exchangeRate(portfolio.currency, order.currency, accountBalance, rates)
    let amountPortfolioCurr: number = exchangeRate(portfolio.currency, order.currency, portfolio.amount || 0, rates)

    let riskValueSec = security.riskValue ? security.riskValue : RISK_VALUE_MAX
    let horizonteValueSec = security.horizonteValue ? security.horizonteValue : HORIZONTE_VALUE_MAX

    if (order.amount != null) {
      // ( 1 - ( objem pokynu - zustatek ) / objem pokynu ) * 100
      coverCal = (1 - (order.amount - currencyBalance) / order.amount) * 100
    } else {
      coverCal = 0
    }

    if (order.direction === 'OD_SELL') {
      setIsCovered(true)
      setCovered(100)
      if (order.amount != null) {
        if (portfolio.riskValue != null) {
          // risk value = ( ( objem portfolia * risk portfolioa - objem pokynu * risk security ) / ( objem portfolia - objem pokynu) )
          riskValueCalc =
            (amountPortfolioCurr * portfolio.riskValue - order.amount * riskValueSec) /
            (amountPortfolioCurr - order.amount)
          isRiskCalc = client.riskProfile === null || riskValueCalc < client.riskProfile
        }

        if (portfolio.horizonteValue != null) {
          // risk value = ( ( objem portfolia * risk portfolioa - objem pokynu * risk security ) / ( objem portfolia - objem pokynu ) )
          horizonteValueCalc =
            (amountPortfolioCurr * portfolio.horizonteValue - order.amount * horizonteValueSec) /
            (amountPortfolioCurr - order.amount)
          isHorizonteCalc =
            client.investmentHorizonte === null ||
            horizonteValueCalc < (client.investmentHorizonte as unknown as number)
        }
        setRiskValue(riskValueCalc)
        setHorizonteValue(horizonteValueCalc)
        setIsRiskValue(true)
        setIsRiskValueSec(true)
        setIsHorizonteValue(true)
        setIsHorizonteValueSec(true)
        setIsID(!true)
      }
    } else {
      if (order.amount != null) {
        if (portfolio.riskValue != null) {
          // risk value = ( ( objem pokynu * risk security + objem portfolia * risk portfolioa ) / ( objem pokynu + objem portfolia ) )
          riskValueCalc =
            (order.amount * riskValueSec + amountPortfolioCurr * portfolio.riskValue) /
            (order.amount + amountPortfolioCurr)
          isRiskCalc = client.riskProfile === null || riskValueCalc < client.riskProfile
          isRiskCalcSec = client.riskProfile === null || riskValueCalc < riskValueSec
        }

        if (portfolio.horizonteValue != null) {
          // risk value = ( ( objem pokynu * risk security + objem portfolia * risk portfolioa ) / ( objem pokynu + objem portfolia ) )
          horizonteValueCalc =
            (order.amount * horizonteValueSec + amountPortfolioCurr * portfolio.horizonteValue) /
            (order.amount + amountPortfolioCurr)
          isHorizonteCalc =
            client.investmentHorizonte === null ||
            horizonteValueCalc < (client.investmentHorizonte as unknown as number)
          isHorizonteCalcSec = client.investmentHorizonte === null || horizonteValueCalc < horizonteValueSec
        }
        setRiskValue(riskValueCalc)
        setHorizonteValue(horizonteValueCalc)
        setIsRiskValue(isRiskCalc)
        setIsRiskValueSec(isRiskCalcSec)
        setIsHorizonteValue(isHorizonteCalc)
        setIsHorizonteValueSec(isHorizonteCalcSec)
        setIsID(
          !(
            isHorizonteCalc &&
            isRiskCalc &&
            isCategorySec &&
            isSkillAndExperienceSec &&
            isPreferenceSec &&
            isLossAbilitySec
          )
        )
      }

      if (coverCal < 100) {
        setIsCovered(false)
        setCovered(coverCal)
      } else if (coverCal >= 100) {
        setIsCovered(true)
        setCovered(100)
      } else if (coverCal < 0) {
        setIsCovered(false)
        setCovered(0)
      }
    }
  }

  if (!security || !codeList) return null

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <div className={`${classes.clientInformationDialogHeader} ${getInputDialogColor(order.direction)}`}>
          <Typography variant="h5">{t('NEW_ORDER')}</Typography>
        </div>
        <DialogContent>
          <Paper variant="outlined" className={classes.clientContainer}>
            <div className={classes.clientPersonalInfoContainer}>
              <div className={`${classes.flexRow}`}>
                <img alt="" className={classes.clientIcon} src={manS} width={20} />
                <div className={classes.clientCredentials}>
                  <span>
                    {client.firstName} <span className={classes.boldText}>{client.surname}</span>
                  </span>
                  <span>{portfolio.name}</span>
                </div>
              </div>
              <div className={`${classes.flexColumn} ${classes.flexGrow}`}>
                <div className={classes.flexRow}>
                  <span>{t('PORTFOLIO_AMOUNT')}: </span>
                  <span className={` ${classes.alignSelfToEnd} ${classes.flexGrow} ${classes.textToEnd}`}>
                    <NumberFormat
                      value={portfolio.amount}
                      displayType="text"
                      thousandSeparator=" "
                      decimalScale={2}
                      decimalSeparator=","
                    />{' '}
                    {portfolio.currency}
                  </span>
                </div>
                <div className={classes.flexRow}>
                  <span>{t('ACCOUNT_BALANCE')}: </span>
                  <span
                    className={`${classes.boldText} ${classes.alignSelfToEnd} ${classes.flexGrow} ${
                      classes.textToEnd
                    } ${orderCoveredColorText(isCovered)}`}
                  >
                    <NumberFormat
                      value={accountBalance}
                      displayType="text"
                      thousandSeparator=" "
                      decimalScale={2}
                      decimalSeparator=","
                    />{' '}
                    {portfolio.currency}
                  </span>
                </div>
              </div>
            </div>
          </Paper>
          <Paper variant="outlined" className={classes.commodityContainer}>
            <div className={classes.topContainer}>
              <InstrumentIcon
                className={`${classes.iconSize} ${instrumentIconType} ${classes.position}`}
                securityType={security.assetClass}
              />
              <span className={`${classes.topLineText} ${classes.boldText} ${classes.textColor}`}>{security.name}</span>
              <div className={classes.topLineSeparator} />
              <div className={classes.isin}>
                <span className={` ${classes.boldText} ${classes.textColor}`}>{security.isin}</span>
                <span className={` ${classes.textColor}`}>{t(security.instrumentType)}</span>
              </div>
            </div>
            <div className={classes.bottomContainer}>
              <div className={classes.flexColumn}>
                <span className={`${classes.boldText} ${classes.textSeparatorLeft} ${classes.textColor}`}>
                  <NumberFormat
                    value={security.lastQuotePrice}
                    displayType="text"
                    thousandSeparator=" "
                    decimalScale={8}
                    decimalSeparator=","
                  />{' '}
                  {security.currency}
                </span>
                <span className={`${classes.securityInfo} ${classes.textSeparatorLeft}`}>{security.lastQuoteDate}</span>
              </div>
              <span className={`${classes.percentage} ${classes.textSeparatorLeft}`}>
                {security.lastPriceIndication}
              </span>
              <div className={classes.topLineSeparator} />
              <span className={classes.securityInfo}>{security.market}</span>
            </div>
          </Paper>
          <Paper variant="outlined" className={classes.selectionContainer}>
            <ArrowSelect
              value={t(order.marketType)}
              label={t('MARKET_TYPE')}
              options={codeListToArrowSelect(ORDER_MARKET_TYPE, codeList)}
              onChange={handleChangeArrowSelect('marketType')}
            />
            {order.marketType === ORDER_MARKET_TYPE_MARKET && (
              <NumericInput
                variant="standard"
                label={t('PRICE')}
                fullWidth={true}
                value={order.price || ''}
                onChange={handleChangeOrder('price')}
              />
            )}
            {order.marketType === ORDER_MARKET_TYPE_LIMIT && (
              <NumericInput
                variant="standard"
                fullWidth={true}
                value={order.price || ''}
                onChange={handleChangeOrder('price')}
                label={t('PRICE')}
              />
            )}
            <ArrowSelect
              value={order.currency}
              label={t('ORDER_CURRENCY')}
              options={[
                { label: security.currency, value: security.currency },
                { label: client.portfolios[0].currency, value: client.portfolios[0].currency },
              ]}
              onChange={handleChangeArrowSelect('currency')}
            />
            <ArrowSelect
              value={t(order.direction)}
              label={t('ORDER_DIRECTION2')}
              options={codeListToArrowSelect(ORDER_DIRECTION, codeList)}
              onChange={handleChangeArrowSelect('direction')}
            />
            <ArrowSelect
              value={t(order.type)}
              label={t('ORDER_TYPE')}
              options={codeListToArrowSelect(ORDER_TYPE, codeList)}
              onChange={handleChangeArrowSelect('type')}
            />
            {order.type === ORDER_TYPE_AMOUNT && (
              <NumericInput
                variant="standard"
                fullWidth={true}
                value={order.amount || ''}
                onChange={onClientInputChange(client)}
                label={t('DEAL_AMOUNT')}
              />
            )}
            {order.type === ORDER_TYPE_QUANTITY && (
              <NumericInput
                variant="standard"
                fullWidth={true}
                value={order.quantity || ''}
                onChange={onClientInputChange(client)}
                label={t('QUANTITY')}
              />
            )}
            <ArrowSelect
              value={t(order.validityType)}
              label={t('ORDER_VALIDITY')}
              options={codeListToArrowSelect(ORDER_VALIDITY, codeList)}
              onChange={handleChangeArrowSelect('validityType')}
            />
            {order.validityType === ORDER_VALIDITY_DATE && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={props => <TextField variant="standard" fullWidth={true} {...props} label={t('DATE')} />}
                  inputFormat="dd/MM/yyyy"
                  value={order.validityDate || new Date()}
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            )}
            {/*}   <ArrowSelect value={textFieldValue} label="Protistrana" options={textFieldValues} /> {*/}
          </Paper>
          <Paper
            variant="outlined"
            className={`${orderCoveredColor(isCovered && isID)} ${classes.currencyTotal} ${classes.textWhite}`}
          >
            <div className={`${classes.flexRow}`}>
              {order.type === ORDER_TYPE_AMOUNT && (
                <div className={`${classes.halfWidth}`}>
                  <div>{t('QUANTITY')}</div>
                  <div className={classes.boldText}>
                    <NumberFormat
                      value={order.quantity || 0}
                      displayType="text"
                      thousandSeparator=" "
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                    />
                  </div>
                </div>
              )}
              {order.type === ORDER_TYPE_QUANTITY && (
                <div className={`${classes.halfWidth}`}>
                  <div>{t('AMOUNT')}</div>
                  <div className={classes.boldText}>
                    <NumberFormat
                      value={exchangeRate(security.currency, order.currency, order.amount || 0, rates)}
                      displayType="text"
                      thousandSeparator=" "
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                    />{' '}
                    {order.currency}
                  </div>
                </div>
              )}
              <div className={`${classes.halfWidth}`}>
                <div>{t('COVER')}</div>
                <div className={classes.boldText}>
                  <NumberFormat
                    value={covered || 0}
                    displayType="text"
                    thousandSeparator=" "
                    fixedDecimalScale
                    decimalScale={0}
                    decimalSeparator=","
                  />
                  %
                </div>
              </div>
              {/* <div className={`${classes.halfWidth}`} title={(!isID) ? 'Je porušen investiční dotazník.' : ''}>
                                <div>Plnění I.D.</div>
                                <div className={classes.boldText}>
                                    R &nbsp;
                                    <NumberFormat
                                        value={riskValue}
                                        displayType="text"
                                        thousandSeparator=" "
                                        fixedDecimalScale
                                        decimalScale={2}
                                        decimalSeparator=","
                                    />                                    
                                    &nbsp; H &nbsp;
                                    <NumberFormat
                                        value={horizonteValue}
                                        displayType="text"
                                        thousandSeparator=" "
                                        fixedDecimalScale
                                        decimalScale={2}
                                        decimalSeparator=","
                                    />                                    
                                </div>
                            </div> */}
            </div>
          </Paper>

          <Paper variant="outlined" className={classes.investment}>
            <div className={classes.flexRow90}>
              <div className={`${classes.leftContent} ${classes.flexColumn}`}>
                <div className={`${classes.smallFont}`}>&nbsp;</div>
                <div className={`${!isHorizonteValue ? classes.smallFontRed : classes.smallFont}`}>
                  {t('HORIZONTE')}:
                </div>
                <div className={`${!isRiskValue ? classes.smallFontRed : classes.smallFont}`}>{t('RISK')}:</div>
                <div className={`${!isCategorySec ? classes.smallFontRed : classes.smallFont}`}>{t('CATEGORY')}:</div>
                <div className={`${!isSkillAndExperienceSec ? classes.smallFontRed : classes.smallFont}`}>
                  {t('EXPERIENCE')}:
                </div>
                <div className={`${!isPreferenceSec ? classes.smallFontRed : classes.smallFont}`}>
                  {t('PREFERENCE')}:
                </div>
                <div className={`${!isLossAbilitySec ? classes.smallFontRed : classes.smallFont}`}>{t('RISK2')}:</div>
              </div>

              <div className={`${classes.leftContent} ${classes.centerContent} ${classes.flexColumn}`}>
                <div className={`${classes.smallFont}`}>{t('CLIENT')}</div>
                <div className={`${classes.smallFont}`}>{client.investmentHorizonte}</div>
                <div className={`${classes.smallFont}`}>{client.riskProfile}</div>
                <div className={`${classes.smallFont}`}>{t(client.category || '')}</div>
                <div className={`${classes.smallFont}`}>{t(client.skillAndExperience || '')}</div>
                <div className={`${classes.smallFont}`}>{t(client.preference || '')}</div>
                <div className={`${classes.smallFont}`}>{t(client.lossAbility || '')}</div>
              </div>

              <div className={`${classes.leftContent} ${classes.centerContent} ${classes.flexColumn}`}>
                <div className={`${classes.smallFont}`}>{t('SECURITY')}</div>
                <div className={`${!isHorizonteValue ? classes.smallFontRed : classes.smallFont}`}>
                  {security.horizonteValue}
                </div>
                <div className={`${!isRiskValue ? classes.smallFontRed : classes.smallFont}`}>{security.riskValue}</div>
                <div className={`${!isCategorySec ? classes.smallFontRed : classes.smallFont}`}>
                  {t(security.category || '')}
                </div>
                <div className={`${!isSkillAndExperienceSec ? classes.smallFontRed : classes.smallFont}`}>
                  {t(security.skillAndExperience || '')}
                </div>
                <div className={`${!isPreferenceSec ? classes.smallFontRed : classes.smallFont}`}>
                  {t(security.preference || '')}
                </div>
                <div className={`${!isLossAbilitySec ? classes.smallFontRed : classes.smallFont}`}>
                  {t(security.lossAbility || '')}
                </div>
              </div>

              <div className={`${classes.leftContent} ${classes.centerContent} ${classes.flexColumn}`}>
                <div className={`${classes.smallFont}`}>{t('PORTFOLIO')}</div>
                <div className={`${!isHorizonteValue ? classes.smallFontRed : classes.smallFont}`}>
                  <NumberFormat
                    value={horizonteValue || 0}
                    displayType="text"
                    thousandSeparator=" "
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                  />
                </div>
                <div className={`${!isRiskValue ? classes.smallFontRed : classes.smallFont}`}>
                  <NumberFormat
                    value={riskValue || 0}
                    displayType="text"
                    thousandSeparator=" "
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                  />
                </div>
                <div className={`${!isCategorySec ? classes.smallFontRed : classes.smallFont}`}>
                  {t(categorySec || '')}
                </div>
                <div className={`${!isSkillAndExperienceSec ? classes.smallFontRed : classes.smallFont}`}>
                  {t(skillAndExperienceSec || '')}
                </div>
                <div className={`${!isPreferenceSec ? classes.smallFontRed : classes.smallFont}`}>
                  {t(preferenceSec || '')}
                </div>
                <div className={`${!isLossAbilitySec ? classes.smallFontRed : classes.smallFont}`}>
                  {t(lossAbilitySec || '')}
                </div>
              </div>
            </div>
          </Paper>

          <Paper variant="outlined" className={classes.currencyTotal}>
            <div>
              <TextField
                className={classes.fullwidth}
                label={t('VIOLATION_COMMENT')}
                disabled={!isID}
                value={order.orderComment}
                onChange={handleChangeOrder('orderComment')}
              />
            </div>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Storno
          </Button>
          <Button
            className={`${classes.actionButtons} ${getInputDialogColor(order.direction)}`}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size="1.4rem" /> : order.direction === 'OD_BUY' ? t('BUY') : t('SELL')}
          </Button>
        </DialogActions>
      </Dialog>

      <MessageAlert alertOpen={alertMessageOpen} alertText={t('MASS_ORDER_ALERT_MESSAGE1')} onOk={handleCloseMessage} />
      <MessageAlert
        alertOpen={alertMessage2Open}
        alertText={t('MASS_ORDER_ALERT_MESSAGE2')}
        onOk={handleCloseMessage2}
      />

      <ClearConfirmationAlert
        alertOpen={orderAlertOpen}
        onOk={orderAlertOk}
        onCancel={orderAlertCancel}
        alertText={t('INVESTMENT_VIOLATION_MESSAGE')}
        inProgress={false}
      />
    </div>
  )
}
