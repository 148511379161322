import { useParams } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { newThemeObject } from '../../newTheme'
import { useSelector } from '../../redux/hooks/useSelector'
import { NavBarButton } from './NavBarButton'
import { Badge, Skeleton } from '@mui/material'
import { CreditCardRounded, DashboardRounded, HelpOutline, PublishRounded } from '@mui/icons-material'
import { isClientUser } from '../../services/keycloak.service'
import { Pages } from '../../router/router'
import { useTranslation } from 'react-i18next'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import { Portfolio } from '../../redux/reducers/portfolio'
import { INavBarButton } from '../../interfaces/layout'
import Divider from '../common/Divider'
import { FILE_IMPORT_DIALOG_ID } from '../../constants/dialogConstants'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import LogoWM from '../../assets/logo-text.png'
import { TransparentIconButton } from '../common/TransparentIconButton'
import { OrdersByStateCount } from '../../redux/reducers/orders'

function useButtons() {
  const { t } = useTranslation()
  const { clientId } = useParams<{ clientId: string }>()
  const buttons: INavBarButton[] = []
  const portfButtons: INavBarButton[] = []
  const managerButtons: INavBarButton[] = []
  const { exportOrders } = useInstrumentApi()
  const dispatch = useDispatch()

  const client = useSelector(state => state.clients.client)
  const portfolios = client.portfolios

  const [ordersByStateCount, setOrdersByStateCount] = useState([] as OrdersByStateCount[])
  const orderSse = useSelector(state => state.orders.orderSse)

  useEffect(() => {
    if (orderSse && orderSse.userStates && orderSse.userStates.length > 0) {
      setOrdersByStateCount(orderSse.userStates[0].states)
    }
  }, [orderSse])

  if (!isClientUser()) {
    managerButtons.push({
      icon: <CreditCardRoundedIcon />,
      link: `${Pages.OrderList}`,
      text: t('ORDERS_LIST'),
    })
    managerButtons.push({
      icon: <DescriptionRoundedIcon />,
      link: `${Pages.InstrumentList}`,
      text: t('INSTRUMENTS_LIST'),
    })
    managerButtons.push({
      icon: <PersonRoundedIcon />,
      link: `${Pages.Home}`,
      text: t('CLIENTS'),
    })
    managerButtons.push({
      icon: <PublishRounded />,
      function: () => {
        dispatch(openDialog(FILE_IMPORT_DIALOG_ID))
      },
      text: t('FILE_IMPORT'),
    })
    managerButtons.push({
      icon: (
        <TransparentIconButton
          icon={
            <Badge
              badgeContent={ordersByStateCount.find(count => count.state === 'OS_NEW')?.count || 0}
              color="primary"
            >
              <DescriptionRoundedIcon style={{ fontSize: '30px' }} />
            </Badge>
          }
        />
      ),
      function: exportOrders,
      text: t('ORDERS_EXPORT'),
    })
  }

  if (!clientId) {
    return [buttons, portfButtons, managerButtons]
  }
  if (portfolios.length === 1) {
    if (!isClientUser()) {
      buttons.push({
        icon: <DashboardRounded />,
        link: `${Pages.ClientDetail}/${clientId}`,
        text: t('ASSET_OVERVIEW'),
      })
      buttons.push({
        icon: <CreditCardRounded />,
        link: `${Pages.ClientOrderList}/${clientId}`,
        text: t('ORDERS'),
      })
      buttons.push({
        icon: <HelpOutline />,
        link: `${Pages.InvestmentQuestionnaire}/${clientId}`,
        text: t('INVESTMENT_QUESTIONNAIRE'),
      })
      buttons.push({
        icon: <HelpOutline />,
        link: `${Pages.ImportDocuments}/${clientId}`,
        text: t('IMPORT_DOCUMENTS'),
      })
    } else {
      buttons.push({
        icon: <DashboardRounded />,
        link: `${Pages.ClientDetail}/${clientId}`,
        text: t('ASSET_OVERVIEW'),
      })
      buttons.push({
        icon: <HelpOutline />,
        link: `${Pages.InvestmentQuestionnaire}/${clientId}`,
        text: t('INVESTMENT_QUESTIONNAIRE'),
      })
      buttons.push({
        icon: <HelpOutline />,
        link: `${Pages.ImportDocuments}/${clientId}`,
        text: t('IMPORT_DOCUMENTS'),
      })
    }
  } else {
    if (!isClientUser()) {
      portfolios.map((portfolio: Portfolio, index: number) => {
        portfButtons.push({
          link: `${Pages.ClientDetail}/${clientId}/${index}`,
          text: portfolio.name || (index + 1).toString(),
        })
      })
      buttons.push({
        icon: <CreditCardRounded />,
        link: `${Pages.ClientOrderList}/${clientId}`,
        text: t('ORDERS'),
      })
      buttons.push({
        icon: <HelpOutline />,
        link: `${Pages.InvestmentQuestionnaire}/${clientId}`,
        text: t('INVESTMENT_QUESTIONNAIRE'),
      })
      buttons.push({
        icon: <HelpOutline />,
        link: `${Pages.ImportDocuments}/${clientId}`,
        text: t('IMPORT_DOCUMENTS'),
      })
    } else {
      portfolios.map((portfolio: Portfolio, index: number) => {
        portfButtons.push({
          link: `${Pages.ClientDetail}/${clientId}/${index}`,
          text: portfolio.name || (index + 1).toString(),
        })
      })
      buttons.push({
        icon: <HelpOutline />,
        link: `${Pages.InvestmentQuestionnaire}/${clientId}`,
        text: t('INVESTMENT_QUESTIONNAIRE'),
      })
      buttons.push({
        icon: <HelpOutline />,
        link: `${Pages.ImportDocuments}/${clientId}`,
        text: t('IMPORT_DOCUMENTS'),
      })
    }
  }

  return [buttons, portfButtons, managerButtons]
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 700,
    background: '#fff',
    justifyContent: 'left',
    padding: '10px',
    gap: '5px',
    borderRadius: newThemeObject.roundness.basic,
    margin: 10,
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: '600',
    color: newThemeObject.palette.main.primary,
    margin: 0,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  client: {
    fontWeight: '600',
    fontSize: '0.9rem',
    color: '#000',
    margin: 0,
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textWrap: 'wrap',
    maxWidth: '80%',
  },
  text: {
    fontWeight: '600',
    fontSize: 12,
    margin: 0,
  },
  iconContainer: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '30px',
    color: '#000',
  },
  wannaBeButtonWrapper: {
    display: 'flex',
    width: 250,
    justifyContent: 'left',
    padding: 10,
    paddingBottom: 0,
    borderRadius: newThemeObject.roundness.small,
    alignItems: 'center',
  },
}))

export const NavBar: React.FC = () => {
  const classes = useStyles()
  const client = useSelector(state => state.clients.client)
  const { t } = useTranslation()
  const [buttons, portfButtons, managerButtons] = useButtons()
  const openedClient = useParams<{ clientId: string }>().clientId
  console.log(client, openedClient)
  return (
    <div className={classes.wrapper}>
      {/* <p className={classes.title}>iTrader</p> */}
      <img src={LogoWM} alt="Multi tag" className={classes.title} />
      {!!parseInt(openedClient) && !(client.firstName || client.surname) && (
        <Skeleton className={classes.client} variant="text" height={25} />
      )}
      {managerButtons && managerButtons.length !== 0 && (
        <>
          {managerButtons.map(button => (
            <NavBarButton button={button} />
          ))}
          {buttons && buttons.length !== 0 && <Divider size="sm" />}
        </>
      )}
      {portfButtons.length !== 0 && (
        <>
          <div className={classes.wannaBeButtonWrapper}>
            <span className={classes.iconContainer}>
              <DashboardRounded className={classes.icon} />
            </span>
            <p className={classes.text}>{t('ASSET_OVERVIEW').toUpperCase()}:</p>
          </div>
          {portfButtons?.map(button => (
            <NavBarButton button={button} />
          ))}
        </>
      )}
      {buttons?.map(button => (
        <NavBarButton button={button} />
      ))}
    </div>
  )
}
