import React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { Pages } from '../router'
import { useKeycloak } from '@react-keycloak/web'
import { isClientUser } from '../../services/keycloak.service'

interface PrivateRouteParams extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  protectedRoute: boolean
}

export function PrivateRoute({ component: Component, protectedRoute, ...rest }: PrivateRouteParams) {
  const { keycloak } = useKeycloak()

  return (
    <Route
      {...rest}
      render={props => {
        if (!keycloak?.authenticated) {
          return (
            <Redirect
              to={{
                pathname: Pages.Login,
                state: { from: props.location },
              }}
            />
          )
        } else if (protectedRoute && isClientUser()) {
          return (
            <Redirect
              to={{
                pathname: Pages.AccessForbidden,
                state: { from: props.location },
              }}
            />
          )
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}
