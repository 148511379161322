import React, { useLayoutEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { ACTION_FAILED_ALERT_ID } from '../../constants/dialogConstants'
import { closeDialog } from '../../redux/actions/dialogActions'

const useStyles = makeStyles(theme => ({}))

interface Props {
  handleClose: () => void
}

export const ActionFailedAlert: React.FC<Props> = ({handleClose}) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const foundDialog = dialogs.find(dialog => dialog.id === 'ACTION_FAILED_ALERT_ID')
  const open = !!foundDialog

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(ACTION_FAILED_ALERT_ID))
    }
  }, [])

  const handleCloseInner = () => {
    dispatch(closeDialog(ACTION_FAILED_ALERT_ID))
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseInner}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Akci se nepodařilo provést</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Zkuste prosím znovu, nebo se obraťte na podporu.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseInner} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
