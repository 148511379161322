import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { ActiveOrdersTile } from './ActiveOrdersTile'
import AddIcon from '@mui/icons-material/Add'
import { openDialog } from '../../redux/actions/dialogActions'
import { MASS_ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Pages } from '../../router/router'
import { TransparentButton } from './TransparentButton'
import { sortOrderTable } from '../../redux/actions/filterActions'
import { OrdersByStateOptions } from '../../redux/reducers/filters'
import {
  ORDER_STATE_CANCELED,
  ORDER_STATE_CONFIRMED,
  ORDER_STATE_ERROR,
  ORDER_STATE_EXPIRED,
  ORDER_STATE_FILLED,
  ORDER_STATE_NEW,
  ORDER_STATE_PARTIAL_CANCELED,
  ORDER_STATE_PARTIAL_EXPIRED,
  ORDER_STATE_PARTIAL_FILLED,
  ORDER_STATE_REJECTED,
  ORDER_STATE_SENT_NEW,
} from '../../constants/codeLists'
import Box from '@mui/material/Box'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { OrdersByStateCount } from '../../redux/reducers/orders'
import { useOrderFiltersDates } from '../../hooks/useOrderFilters'
import { useSelector } from '../../redux/hooks/useSelector'

const useStyles = makeStyles(theme => ({
  refusedOrders: {
    color: theme.palette.error.main,
  },
  openOrders: {
    color: theme.palette.warning.main,
  },
  partialOrders: {
    color: theme.palette.success.main,
  },
  newOrder: {
    color: theme.palette.primary.main,
  },
  addMassOrderButton: {
    height: '70px',
    width: '70px',
    color: theme.palette.primary.main,
  },
  tileButton: {
    minWidth: '40%',
    height: '100px',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2.5),
    padding: '0px',
    borderRadius: '11px',
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      minWidth: '100px',
      width: '170px',
    },
  },
  wrapper: {
    overflowX: 'auto',
    display: 'flex',
  },
}))

interface ActiveOrdersProps {
  ordersByStateCount: OrdersByStateCount[]
}

export const ActiveOrdersTiles: React.FC<ActiveOrdersProps> = ({ ordersByStateCount }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { clientId } = useParams<{ clientId: string }>()
  const { t } = useTranslation()
  const orders = useOrderFiltersDates()
  const filters = useSelector(state => state.filters)

  const onStateClick = (countState: OrdersByStateOptions) => () => {
    if (clientId === null || clientId === undefined) history.push(Pages.OrderList)

    dispatch(sortOrderTable(countState))
  }

  return (
    <Box className={classes.wrapper}>
      <TransparentButton
        className={classes.tileButton}
        onClick={onStateClick(OrdersByStateOptions.All)}
        content={
          <ActiveOrdersTile
            // middleText={ordersByStateCount.find(count => count.state === ORDER_STATE_REJECTED)?.count || 0}
            middleText={orders && orders.length > 0 ? orders.length : '?'}
            bottomText={t('ALL_ORDERS')}
            className={classes.refusedOrders}
            type={filters.sortOrdersBy == 6 ? 'main' : 'basic'}
          />
        }
      />
      <TransparentButton
        className={classes.tileButton}
        onClick={onStateClick(OrdersByStateOptions.Rejected)}
        content={
          <ActiveOrdersTile
            // middleText={ordersByStateCount.find(count => count.state === ORDER_STATE_REJECTED)?.count || 0}
            middleText={
              orders && orders.length > 0 ? orders.filter(item => item.state === ORDER_STATE_REJECTED).length : '?'
            }
            bottomText={t('REJECTED_ORDERS')}
            className={classes.refusedOrders}
            type={filters.sortOrdersBy == 4 ? 'main' : 'basic'}
          />
        }
      />
      <TransparentButton
        className={classes.tileButton}
        onClick={onStateClick(OrdersByStateOptions.Open)}
        content={
          <ActiveOrdersTile
            // middleText={
            //     (ordersByStateCount.find(count => count.state === ORDER_STATE_SENT_NEW)?.count || 0) +
            //     (ordersByStateCount.find(count => count.state === ORDER_STATE_CONFIRMED)?.count || 0)
            // }
            middleText={
              orders && orders.length > 0 ? orders.filter(item => item.state === ORDER_STATE_CONFIRMED).length : '?'
            }
            bottomText={t('OPEN_ORDERS')}
            className={classes.openOrders}
            type={filters.sortOrdersBy == 1 ? 'main' : 'basic'}
          />
        }
      />
      <TransparentButton
        className={classes.tileButton}
        onClick={onStateClick(OrdersByStateOptions.Partial)}
        content={
          <ActiveOrdersTile
            // middleText={ordersByStateCount.find(count => count.state === ORDER_STATE_PARTIAL_FILLED)?.count || 0}
            middleText={
              orders && orders.length > 0
                ? orders.filter(item => item.state === ORDER_STATE_PARTIAL_FILLED).length
                : '?'
            }
            bottomText={t('PARTIAL_ORDERS')}
            className={classes.partialOrders}
            type={filters.sortOrdersBy == 2 ? 'main' : 'basic'}
          />
        }
      />
      <TransparentButton
        className={classes.tileButton}
        onClick={onStateClick(OrdersByStateOptions.Closed)}
        content={
          <ActiveOrdersTile
            // middleText={
            //     (ordersByStateCount.find(count => count.state === ORDER_STATE_FILLED)?.count || 0) +
            //     (ordersByStateCount.find(count => count.state === ORDER_STATE_CANCELED)?.count || 0) +
            //     (ordersByStateCount.find(count => count.state === ORDER_STATE_PARTIAL_EXPIRED)?.count || 0) +
            //     (ordersByStateCount.find(count => count.state === ORDER_STATE_PARTIAL_CANCELED)?.count || 0) +
            //     (ordersByStateCount.find(count => count.state === ORDER_STATE_EXPIRED)?.count || 0)
            // }

            type={filters.sortOrdersBy == 5 ? 'main' : 'basic'}
            middleText={
              orders && orders.length > 0
                ? orders.filter(
                    item =>
                      item.state === ORDER_STATE_FILLED ||
                      item.state === ORDER_STATE_CANCELED ||
                      item.state === ORDER_STATE_PARTIAL_EXPIRED ||
                      item.state === ORDER_STATE_PARTIAL_CANCELED ||
                      item.state === ORDER_STATE_EXPIRED ||
                      item.state === ORDER_STATE_ERROR
                  ).length
                : '?'
            }
            bottomText={t('CLOSED_ORDERS')}
            className={classes.partialOrders}
          />
        }
      />
      <TransparentButton
        className={classes.tileButton}
        onClick={onStateClick(OrdersByStateOptions.New)}
        content={
          <ActiveOrdersTile
            // middleText={ordersByStateCount.find(count => count.state === ORDER_STATE_NEW)?.count || 0}
            middleText={
              orders && orders.length > 0 ? orders.filter(item => item.state === ORDER_STATE_NEW).length : '?'
            }
            bottomText={t('UNSEND_ORDERS')}
            className={classes.partialOrders}
            type={filters.sortOrdersBy == 0 ? 'main' : 'basic'}
          />
        }
      />
    </Box>
  )
}
