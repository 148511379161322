import React from 'react'
import { Select, MenuItem, FormControl, InputLabel, InputBase } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeStyles, styled } from '@mui/styles'
import { newThemeObject } from '../../newTheme'
import CardWrapper from '../common/CardWrapper'
import { ApexOptions } from 'apexcharts'
import { Client } from '../../redux/reducers/clients'
import { Portfolio } from '../../redux/reducers/portfolio'
import ReactApexChart from 'react-apexcharts'
import { formatNumber } from '../../utils/numbersFormatter'

const useStyles = makeStyles(theme => ({
  title: {
    color: newThemeObject.palette.main.primary,
    fontWeight: 600,
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  summary: {
    display: 'grid',
    gap: theme.spacing(1),
    gridTemplateColumns: `1fr 1fr 1fr`,
  },
  summaryLabel: {
    color: newThemeObject.palette.main.primary,
    fontWeight: 500,
    margin: 0,
  },
  summaryText: {
    margin: 0,
  },
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '7rem',
    borderRadius: '2rem',
    position: 'relative',
    backgroundColor: newThemeObject.palette.background.gray,
    fontSize: '0.8rem',
    padding: newThemeObject.padding.smallWrapper,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))

interface DonutChartProps {
  graphBarIndex: number
  handleChangeAggType: (event: React.ChangeEvent<{}>, newValue: number) => void
  optionsGraphBar: ApexOptions
  seriesGraphBar: Array<number>
  index?: number
  handleChangeIndex?: (index: number) => void
  client: Client
  portfolio: Portfolio
}

const DonutChart: React.FC<DonutChartProps> = ({
  graphBarIndex,
  handleChangeAggType,
  optionsGraphBar,
  seriesGraphBar,
  handleChangeIndex,
  index,
  client,
  portfolio,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const handleChange = (e: any) => {
    handleChangeAggType(e, e.target.value)
  }
  return (
    <CardWrapper className={classes.wrapper}>
      <p className={classes.title}>{t('ASSET_MANAGAMENT_STRUCTURE')}</p>
      <div>
        <Select label="type" onChange={handleChange} defaultValue={1} input={<BootstrapInput />}>
          <MenuItem value={1}>{t('INSTRUMENT_TYPE')}</MenuItem>
          <MenuItem value={2}>{t('ASSET_CLASS')}</MenuItem>
          <MenuItem value={3}>{t('CURRENCY')}</MenuItem>
          <MenuItem value={4}>{t('RISK_PROFILE')}</MenuItem>
          <MenuItem value={5}>{t('HORIZONTE')}</MenuItem>
          <MenuItem value={6}>{t('SUMMARIZATION')}</MenuItem>
        </Select>
      </div>
      {graphBarIndex !== 6 && (
        <div
          style={{
            width: '100%',
          }}
        >
          <ReactApexChart options={optionsGraphBar} series={seriesGraphBar} type="donut" height={350} />
        </div>
      )}
      {graphBarIndex === 6 && (
        <div className={classes.summary}>
          <p className={classes.summaryLabel}>{t('HORIZONTE')}</p>
          <p className={classes.summaryText}>{client.investmentHorizonte}</p>
          <p className={classes.summaryText}>{formatNumber(portfolio.horizonteValue || 0)}</p>

          <p className={classes.summaryLabel}>{t('RISK')}</p>
          <p className={classes.summaryText}>{client.riskProfile}</p>
          <p className={classes.summaryText}>{formatNumber(portfolio.riskValue || 0)}</p>

          <p className={classes.summaryLabel}>{t('CATEGORY')}</p>
          <p className={classes.summaryText}>{t(client.category || '')}</p>
          <p className={classes.summaryText}>{formatNumber(calProc(portfolio.categoryValue))} %</p>

          <p className={classes.summaryLabel}>{t('EXPERIENCE')}</p>
          <p className={classes.summaryText}>{t(client.skillAndExperience || '')}</p>
          <p className={classes.summaryText}>{formatNumber(calProc(portfolio.skillAndExperienceValue))} %</p>

          <p className={classes.summaryLabel}>{t('PREFERENCE')}</p>
          <p className={classes.summaryText}>{t(client.preference || '')}</p>
          <p className={classes.summaryText}>{formatNumber(calProc(portfolio.preferenceValue))} %</p>

          <p className={classes.summaryLabel}>{t('RISK3')}</p>
          <p className={classes.summaryText}>{t(client.lossAbility || '')}</p>
          <p className={classes.summaryText}>{formatNumber(calProc(portfolio.lossAbilityValue))} %</p>
        </div>
      )}
    </CardWrapper>
  )
}

export default DonutChart

const calProc = (value: number | null) => {
  if (value === null) return 0
  return value > 100 ? 100 : value
}
