import makeStyles from '@mui/styles/makeStyles'

const useStyles: any = makeStyles(theme => ({
  invalid: {
    backgroundColor: theme.palette.error.light,
  },
  valid: {
    backgroundColor: theme.palette.success.light,
  },
}))

export const useOrderCoveredColor = () => {
  const classes = useStyles()

  return (valid: boolean) => {
    if (valid === true) {
      return classes.valid
    }
    return classes.invalid
  }
}
