import { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useSelector } from '../../redux/hooks/useSelector'
import { CANCLE_ORDERS_DIALOG_ID } from '../../constants/dialogConstants'
import { OrderEditDialog } from '../dialogs/OrderEditDialog'
import { OrderDetailDialog } from '../dialogs/OrderDetailDialog'
import { useOrderFilters } from '../../hooks/useOrderFilters'
import { useTranslation } from 'react-i18next'
import { ORDER_STATE_CONFIRMED, ORDER_STATE_NEW, ORDER_STATE_PARTIAL_FILLED } from '../../constants/codeLists'
import { CancleOrdersDialog } from '../dialogs/CancleOrdersDialog'
import classNames from 'classnames'
import OrderTableRow from './OrderTableRow'
import { newThemeObject } from '../../newTheme'

const useStyles = makeStyles(theme => ({
  tableHead: {
    ...newThemeObject.table.tableHead,
  },
  tableHeadChildrenText: {
    ...newThemeObject.table.tableHeadChildrenText,
  },
  tableHeadChildren: {
    ...newThemeObject.table.tableHeadChildren,
  },
  tableHeadChildrenFirst: {
    ...newThemeObject.table.tableHeadChildrenFirst,
  },
  tableHeadChildrenLast: {
    ...newThemeObject.table.tableHeadChildrenLast,
  },
  table: {
    minWidth: 650,
  },
  iconSize: {
    height: '20px',
    width: '40px',
    lineHeight: '18px',
    fontSize: '10px',
  },
  columnCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    color: '#000',
  },
}))

export interface OrderRowData {
  surname: string
  firstName: string
  amount: number
  instrumentType: string
  security: string
  currency: string
  state: string
  sendToFix: boolean
  clientId: number
  securityId: number
  orderId: number
  direction: string
  orderIdent: string
  orderCreationDate: Date
  validityType: string
  validityDate: string
  price: number
  quantity: number
  isin: string
  symbol: string
  ticker: string
  orderMarketType: string
  assetClass: string
}

function createData(
  surname: string,
  firstName: string,
  amount: number,
  instrumentType: string,
  security: string,
  currency: string,
  state: string,
  sendToFix: boolean,
  clientId: number,
  securityId: number,
  orderId: number,
  direction: string,
  orderIdent: string,
  orderCreationDate: Date,
  validityType: string,
  validityDate: string,
  price: number,
  quantity: number,
  isin: string,
  symbol: string,
  ticker: string,
  orderMarketType: string,
  assetClass: string
) {
  return {
    surname,
    firstName,
    amount,
    instrumentType,
    security,
    currency,
    state,
    sendToFix,
    clientId,
    securityId,
    orderId,
    direction,
    orderIdent,
    orderCreationDate,
    validityType,
    validityDate,
    price,
    quantity,
    isin,
    symbol,
    ticker,
    orderMarketType,
    assetClass,
  }
}

interface OrderTableProps {
  orderCheckState: boolean[]
  setOrderCheckState: (param: any[]) => any
  orderCheckStateHeader: boolean
  setOrderCheckStateHeader: (param: any) => any
}

export const OrderTable = ({ orderCheckState, setOrderCheckState }: OrderTableProps) => {
  const classes = useStyles()
  const orderTableRows = useOrderFilters()
  // const orderTableRows: OrderTableRow[] = useSelector((state: RootState) => state.orders.orderTableRows)

  const { t } = useTranslation()

  const rows = orderTableRows.map(row =>
    createData(
      row.clientSurname,
      row.clientFirstname,
      row.amount,
      row.instrumentType,
      row.securityName,
      row.currency,
      row.state,
      row.sendToFix,
      row.clientId,
      row.securityId,
      row.orderId,
      row.direction,
      row.orderIdent,
      row.orderCreationDate,
      row.validityType,
      row.validityDate,
      row.price,
      row.quantity,
      row.isin,
      row.symbol,
      row.ticker,
      row.orderMarketType,
      row.assetClass
    )
  )

  const isEditDisabled = (state: string) => {
    // Editable and cancelable states are new, confirmed and partially filled
    return state !== ORDER_STATE_NEW && state !== ORDER_STATE_CONFIRMED && state !== ORDER_STATE_PARTIAL_FILLED
  }

  const dialogs = useSelector(state => state.dialogs.dialogs)
  const foundDialog = dialogs.find(dialog => dialog.id === CANCLE_ORDERS_DIALOG_ID)

  useEffect(() => {
    if (!foundDialog) {
      let tmp = [...orderCheckState]
      for (let i = 0; i < tmp.length && i < rows.length; i++) {
        if (!isEditDisabled(rows[i].state)) {
          tmp[i] = false
        }
      }
      setOrderCheckState(tmp)
    }
  }, [foundDialog])

  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          padding: '10px',
          borderRadius: newThemeObject.roundness.basic,
        }}
        elevation={0}
      >
        <Table className={`${classes.table}`} size="small" aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenFirst])} />
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('ORDER_NUMBER')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('CRETATION_DATE')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('CLIENT')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('DIRECTION_ORDER_TYPE')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('DATE_FROM')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('PRICE_UNIT')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('AMOUNT_CURRENCY')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('INSTRUMENT')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('ORDER_STATE')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('SEND_TO_FIX')}</p>
              </TableCell>
              <TableCell  className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenLast])}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <OrderTableRow key={index} row={row} isLast={index === rows.length - 1} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <OrderEditDialog />
      <OrderDetailDialog />
      <CancleOrdersDialog orders={rows} toCancle={orderCheckState} />
    </>
  )
}
