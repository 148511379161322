import { FormControlLabel, Switch, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { filterInstrumentName, filterMarketName, searchAllName } from '../../redux/actions/filterActions'
import { useSelector } from '../../redux/hooks/useSelector'
import { ColoredIconButton } from '../common/ColoredIconButton'
import AddIcon from '@mui/icons-material/Add'
import { INSTRUMENT_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { openDialog } from '../../redux/actions/dialogActions'
import { getSecurityAction } from '../../redux/actions/instrumentActions'
import { useTranslation } from 'react-i18next'
import CardWrapper from '../common/CardWrapper'
import { newThemeObject } from '../../newTheme'

const useStyles = makeStyles(theme => ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: newThemeObject.palette.orderTable.searchBar.bg,
    alignItems: 'center',
  },
  instrument: {
    marginLeft: theme.spacing(0.5),
  },
  market: {
    marginLeft: theme.spacing(1),
  },
  searchAll: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    marginLeft: 'auto',
  },
  icon: {
    color: newThemeObject.palette.main.sectext,
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
}))

interface Props {
  setIsUpdate: (params: any) => any
}

export const InstrumentTableFilters: React.FC<Props> = ({ setIsUpdate }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filters = useSelector(state => state.filters)
  const { getInstrumentsByParam } = useInstrumentApi()
  const { t } = useTranslation()

  const onNameSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterInstrumentName(event.target.value))
  }

  const onSearchAllChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch(searchAllName(checked))
  }

  useEffect(() => {
    getInstrumentsByParam(
      filters.searchedInstrumentName.length >= 3 ? filters.searchedInstrumentName : '',
      filters.searchedAll
    )
  }, [filters])

  return (
    <CardWrapper className={classes.filterContainer}>
      <div>
        <TextField
          className={classes.instrument}
          placeholder={t('INSTRUMENT')}
          onChange={onNameSearchFieldChange}
          value={filters.searchedInstrumentName}
          variant="standard"
        />
      </div>
      <div>
        <FormControlLabel
          className={classes.searchAll}
          control={<Switch checked={filters.searchedAll} onChange={onSearchAllChange} />}
          label={t('INSTRUMENT_WITHOUT_TARGET_MARKET')}
        />
      </div>
      <div className={classes.addButton}>
        <ColoredIconButton
          icon={<AddIcon />}
          size="small"
          className={classes.icon}
          onClick={() => {
            dispatch(getSecurityAction(undefined))
            setIsUpdate(false)
            dispatch(
              openDialog(INSTRUMENT_INPUT_DIALOG_ID, {
                dialogTitle: t('NEW_INSTRUMENT'),
                type: 'new',
              })
            )
          }}
        />
      </div>
    </CardWrapper>
  )
}
