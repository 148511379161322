import { ReduxAction } from '../../types/common'
import { GET_MARKETS } from '../actionTypes'

export interface Market {
  marketId: number
  name: string
  description: string
  businessTerritoryId: number
}

interface MarketState {
  markets: Market[]
}

const INITIAL_STATE = {
  markets: [],
}

export const market = (state: MarketState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_MARKETS:
      return {
        ...state,
        markets: action.payload as Market[],
      }
    default:
      return state
  }
}
