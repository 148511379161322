interface Props {
  size?: 'sm' | 'md' | 'lg'
  color?: string
  fullWidth?: boolean
}

const Divider: React.FC<Props> = ({ size, color, fullWidth }) => {
  let height
  switch (size) {
    case 'sm':
      height = 1
      break
    case 'md':
      height = 2
      break
    case 'lg':
      height = 5
      break
    default:
      height = 2
  }
  return (
    <div
      style={{
        marginRight: fullWidth ? 0 : 20,
        marginLeft: fullWidth ? 0 : 20,
        marginTop: 5,
        marginBottom: 5,
        borderRadius: height / 2,
        height: height,
        backgroundColor: color || 'black',
        opacity: 0.2,
      }}
    />
  )
}

export default Divider
