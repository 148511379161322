import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useSelector } from '../../redux/hooks/useSelector'
import { OrderAction } from '../../redux/reducers/orders'
import { ACTION_BROKER_SEND_TO_FILL, ACTION_BROKER_SEND_TO_PARTIAL_FILL } from '../../constants/codeLists'
import NumberFormat from 'react-number-format'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  table: {
    minWidth: '40vw',
  },
  fillTableContainer: {
    minHeight: '60vh',
  },
  fillCell: {},
  actionCell: {
    fontStyle: 'italic',
  },
  filterSwitch: {
    marginLeft: '5px',
    marginTop: '5px',
  },
})

export const FillTable = () => {
  const [showAll, setShowAll] = useState(true)
  const classes = useStyles()
  const order = useSelector(state => state.orders.order)
  const { t } = useTranslation()

  const isFill = (code: string | null) =>
    code === ACTION_BROKER_SEND_TO_PARTIAL_FILL || code === ACTION_BROKER_SEND_TO_FILL

  return (
    <>
      <TableContainer component={Paper} className={classes.fillTableContainer}>
        <Table size="small" stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('FILL')}</TableCell>
              <TableCell align="right">{t('DATE')}</TableCell>
              <TableCell align="right">{t('TYPE')}</TableCell>
              <TableCell align="right">{t('QUANTITY')}</TableCell>
              <TableCell align="right">{t('PRICE')}</TableCell>
              <TableCell align="right">{t('OMT_MARKET')}</TableCell>
              <TableCell align="right">{t('CURRENCY')}</TableCell>
              <TableCell align="right">{t('FEE')}</TableCell>
              <TableCell align="right">{t('AMOUNT')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.actions
              .filter((e: OrderAction) => showAll || isFill(e.code))
              .map((row: OrderAction, i: Number) => (
                <TableRow key={(row.extenalId || 'ID') + '-' + i}>
                  <TableCell component="th" scope="row">
                    {row.extenalId}
                  </TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                  <TableCell align="right" className={isFill(row.code) ? classes.fillCell : classes.actionCell}>
                    {row.code ? t(row.code) : ''}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">
                    {row.price && (
                      <NumberFormat
                        value={row.price}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">{row.market}</TableCell>
                  <TableCell align="right">{row.currency}</TableCell>
                  <TableCell align="right">
                    {row.fee && (
                      <NumberFormat
                        value={row.fee}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {row.amount && (
                      <NumberFormat
                        value={row.amount}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FormControlLabel
        className={classes.filterSwitch}
        control={
          <Switch
            checked={showAll}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setShowAll(checked)}
            name="showAll"
            color="primary"
            size="small"
          />
        }
        label={t('FILL_INCLUDE')}
      />
    </>
  )
}
