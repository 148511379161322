import { AppBar, Button, IconButton, Paper, SelectChangeEvent, TextField, Toolbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { NumericInput } from '../common/NumericInput'
import { OrdersByStateOptions } from '../../redux/reducers/filters'
import {
  sortOrderTable,
  filterClientName,
  filterClientAmountUp,
  filterClientAmountDown,
  filterCurrencyName,
  filterOrderType,
  filterInstrumentName,
  filterDateFrom,
  filterDateTo,
} from '../../redux/actions/filterActions'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../redux/hooks/useSelector'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { openDialog } from '../../redux/actions/dialogActions'
import { CANCLE_ORDERS_DIALOG_ID, MASS_ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import AdapterDateFns from '@date-io/date-fns'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { newThemeObject } from '../../newTheme'
import classNames from 'classnames'
import { MobileDatePicker } from '@mui/lab'
import { AddCircleRounded, AddRounded } from '@mui/icons-material'
import { ColoredIconButton } from '../common/ColoredIconButton'

const useStyles = makeStyles(theme => ({
  tableToolBar: {
    minHeight: 0,
    display: 'flex',
    alignContent: 'space-around',
    width: '100%',
    padding: 0,
    margin: 0,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginRight: theme.spacing(1.5),
      maxWidth: '180px',
    },
  },
  filler: {
    flexGrow: 1,
  },
  icon: {
    color: newThemeObject.palette.main.sectext,
    backgroundColor: '#ffffff',
  },
}))

interface OrderTableProps {
  orderCheckState: boolean[]
  setOrderCheckState: (param: any[]) => any
  orderCheckStateHeader: boolean
  setOrderCheckStateHeader: (param: any) => any
  dateFrom: Date,
  setDateFrom: (param: Date) => any,
  dateTo: Date,
  setDateTo: (param: Date) => any,
}

export const OrderTableTopBar = ({
  orderCheckState,
  setOrderCheckState,
  orderCheckStateHeader,
  setOrderCheckStateHeader,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}: OrderTableProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { clientId } = useParams<{ clientId: string }>()
  const { t } = useTranslation()

  const onNameSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterClientName(event.target.value))
    setOrderCheckState(new Array(5000).fill(false))
  }

  const onInstrumentSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterInstrumentName(event.target.value))
    setOrderCheckState(new Array(5000).fill(false))
  }

  const onAmountUpSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterClientAmountUp(event.target.value))
    setOrderCheckState(new Array(5000).fill(false))
  }

  const onAmountDownSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterClientAmountDown(event.target.value))
    setOrderCheckState(new Array(5000).fill(false))
  }

  const onCurrencySearchChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterCurrencyName(event.target.value))
    setOrderCheckState(new Array(5000).fill(false))
  }

  const onOrderTypeSearchChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterOrderType(event.target.value))
    setOrderCheckState(new Array(5000).fill(false))
  }

  const onDateFromChange = (name: string) => (date: Date | null) => {
    setDateFrom(date || new Date())
    dispatch(filterDateFrom(date || new Date()))
    setOrderCheckState(new Array(5000).fill(false))
  }

  const onDateToChange = (name: string) => (date: Date | null) => {
    setDateTo(date || new Date())
    dispatch(filterDateTo(date || new Date()))
    setOrderCheckState(new Array(5000).fill(false))
  }

  useEffect(() => {
    let date = new Date()
    date.setMonth(date.getMonth() - 1)
    setDateFrom(date)
    dispatch(filterDateFrom(date))

    date = new Date();
    date.setDate(date.getDate() + 1)
    setDateTo(date)
    dispatch(filterDateTo(date))
  }, [])

  return (
    <AppBar position="static" style={{ background: 'rgba(0,0,0,0)' }} elevation={0}>
      <Toolbar variant="dense" className={classes.tableToolBar}>
        {!clientId && (
          <TextField
            variant="standard"
            className={classes.textField}
            placeholder={t('CLIENT')}
            onChange={onNameSearchFieldChange}
          />
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            renderInput={params => <TextField {...params} variant="standard" className={classes.textField} />}
            inputFormat="dd/MM/yyyy"
            value={dateFrom}
            onChange={onDateFromChange('dateFromId')}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            renderInput={params => (
              <TextField {...params} variant="standard" className={classes.textField} placeholder="test" />
            )}
            inputFormat="dd/MM/yyyy"
            value={dateTo}
            onChange={onDateToChange('dateToId')}
          />
        </LocalizationProvider>

        <TextField
          variant="standard"
          className={classes.textField}
          placeholder={t('TYPE')}
          onChange={onOrderTypeSearchChange}
        />
        <NumericInput
          variant="standard"
          className={classes.textField}
          placeholder={t('AMOUNT_FROM')}
          onChange={onAmountUpSearchFieldChange}
        />
        <NumericInput
          variant="standard"
          className={classes.textField}
          placeholder={t('AMOUNT_TO')}
          onChange={onAmountDownSearchFieldChange}
        />
        <TextField
          variant="standard"
          className={classes.textField}
          placeholder={t('INSTRUMENT')}
          onChange={onInstrumentSearchFieldChange}
        />
        <TextField
          variant="standard"
          className={classes.textField}
          placeholder={t('CURRENCY')}
          onChange={onCurrencySearchChange}
        />
        {!clientId && (
          <>
            <span className={classes.filler} />
            <ColoredIconButton
              className={classes.icon}
              onClick={() => {
                dispatch(openDialog(MASS_ORDER_INPUT_DIALOG_ID))
              }}
              size="small"
              icon={<AddRounded />}
            />
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}
