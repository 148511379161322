import React from 'react'
import NumberFormat from 'react-number-format'
import TextField, { TextFieldProps } from '@mui/material/TextField'

interface NumberFormatCustomProps {
  ref: (instance: NumberFormat<any> | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props: NumberFormatCustomProps, ref) {
  return (
    <NumberFormat
      {...props}
      onChange={() => {}}
      onValueChange={values => {
        props.onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      getInputRef={ref}
      thousandSeparator=" "
    />
  )
})

export const NumericInput: React.FC<TextFieldProps> = props => {
  return (
    <TextField
      variant="standard"
      {...props}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
    />
  )
}
