import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { createNotesAction, deleteNotesAction, getNotesAction, updateNotesAction } from '../redux/actions/noteActions'

import { createNoteRequest, deleteNoteRequest, getNotesRequest, updateNoteRequest } from '../api/noteApi'
import { Note } from '../redux/reducers/note'

export const useNoteApi = () => {
  const dispatch = useDispatch()

  const getNotes = useCallback(async (portfolioId: Number | null) => {
    await getNotesRequest(portfolioId)
      .then(response => {
        dispatch(getNotesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const createNote = useCallback(async (newNote: Note) => {
    await createNoteRequest(newNote)
      .then(response => {
        dispatch(createNotesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const updateNote = useCallback(async (existNote: Note) => {
    await updateNoteRequest(existNote)
      .then(response => {
        dispatch(updateNotesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const deleteNote = useCallback(async (noteId: number) => {
    await deleteNoteRequest(noteId)
      .then(response => {
        dispatch(deleteNotesAction(noteId))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getNotes,
    createNote,
    updateNote,
    deleteNote,
  }
}
