import React, { useLayoutEffect, useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { NEW_LABEL_DIALOG_ID } from '../../constants/dialogConstants'
import { closeDialog } from '../../redux/actions/dialogActions'
import { useTranslation } from 'react-i18next'
import { setErrors } from '../../redux/actions/userActions'
import { useLabelApi } from '../../hooks/useLabelApi'
import { Label } from '../../redux/reducers/label'
import { newThemeObject } from '../../newTheme'
import { styled } from '@mui/styles'
import { hexArrayToRGBAArray } from '../../utils/styles'
import TextInput from '../common/TextInput'
import OnePageDialog from '../common/OnePageDialog'
import CustomColorPicker from '../common/CustomColorPicker'
import { DefaultButton } from '../common/DefaultButton'

const EditedDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: newThemeObject.roundness.basic,
  },
}))

const useStyles = makeStyles(theme => ({
  text: {
    color: newThemeObject.palette.main.sectext,
    fontSize: '0.6rem',
    margin: theme.spacing(1),
  },
  errText: {
    color: newThemeObject.palette.main.error,
    fontSize: '0.6rem',
    margin: theme.spacing(1),
  },
  colorPickerWrapper: {
    margin: `0px ${theme.spacing(1)}`,
  },
  colorPickerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(13, 1fr)',
  },
}))

interface LabelDialogProps {
  portfolioId: number
  labels: Label[]
  color: string | null
  name: string | null
  labelId: number | null
  newDialog: boolean | true
}

export const NewLabelDialog: React.FC<LabelDialogProps> = ({
  portfolioId,
  labels,
  color,
  name,
  labelId,
  newDialog,
}) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const foundDialog = dialogs.find(dialog => dialog.id === NEW_LABEL_DIALOG_ID)
  const open = !!foundDialog
  const { t } = useTranslation()
  let errors = useSelector(state => state.useractions.errors)
  const { createLabel, updateLabel, deleteLabel } = useLabelApi()

  const [valueName, setValueName] = useState('')
  const [valueColor, setValueColor] = useState('')

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(NEW_LABEL_DIALOG_ID))
    }
  }, [])

  useEffect(() => {
    if (newDialog) {
      setValueColor('')
      setValueName('')
    } else {
      if (color) {
        setValueColor(color)
      }
      if (name) {
        setValueName(name)
      }
    }
  }, [color, name, newDialog])

  const handleName = (event: any) => {
    setValueName(event.target.value)
  }

  const handleSubmit = () => {
    if (isValid()) {
      save()
      setValueName('')
      setValueColor('')
      handleClose()
    }
    console.log(errors)
  }

  const buttons = () => {
    return (
      <>
        <DefaultButton onClick={handleClose}>{t('CANCEL2')}</DefaultButton>
        <DefaultButton onClick={handleSubmit}>{t('CREATE')}</DefaultButton>
      </>
    )
  }

  const save = () => {
    let label: Label = {
      labelId: newDialog ? 0 : labelId || -1,
      name: valueName,
      color: valueColor,
      portfolioId: portfolioId,
      securities: [],
    }
    if (newDialog) createLabel(label, labels)
    else updateLabel(label, labels)
  }

  const handleClose = () => {
    dispatch(closeDialog(NEW_LABEL_DIALOG_ID))
  }

  const isValid = () => {
    let valid = true
    const errors: {
      [key: string]: boolean
    } = {}

    if (!valueName || valueName === '') {
      valid = false
      errors['name'] = true
    }
    if (!valueColor || valueColor === '') {
      valid = false
      errors['color'] = true
    }

    dispatch(setErrors(errors))
    return valid
  }

  return (
    <div>
      <OnePageDialog
        open={open}
        title={newDialog ? t('NEW_LABEL_DIALOG_TITLE') : t('UPDATE_LABEL_DIALOG_TITLE')}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        buttons={buttons()}
      >
        <TextInput
          required
          value={valueName}
          onChange={handleName}
          placeholder="Název"
          error={errors.name ? true : false}
          fullWidth
          bigger
        />

        <div className={classes.colorPickerWrapper}>
          {errors.color ? (
            <p className={classes.errText}>{t('ERR_LABEL_COLOR')}</p>
          ) : (
            <p className={classes.text}>{t('COLOR')}</p>
          )}
          <CustomColorPicker
            error={errors.color ? true : false}
            colors={newThemeObject.palette.labels.concat(hexArrayToRGBAArray(newThemeObject.palette.labels, 0.4))}
            color={valueColor}
            setColor={setValueColor}
            // row={13}
          />
        </div>
      </OnePageDialog>
    </div>
  )
}
