import { ReduxAction } from '../../types/common'
import {
  GET_FILTERED_SECURITIES,
  GET_POSITIONS,
  GET_SECURITIES,
  GET_SECURITY,
  GET_SECURITY_PRICES,
} from '../actionTypes'

export interface Security {
  assetClass: string
  currency: string
  isin: string
  symbol: string
  ticker: string
  name: string
  securityId: number
  market: string
  lastQuotePrice: number | null
  lastPriceIndication: number
  lastQuoteDate: string
  instrumentType: string
  nominal: number | null
  riskValue: number | null
  horizonteValue: number | null
  category: string | null
  skillAndExperience: string | null
  preference: string | null
  lossAbility: string | null
  webpage: string | null
  comment: string | null
  additionalInformation: string | null
  fileKeyMessageContent: string | null
  fileKeyMessageHeader: string | null
  fileKeyMessageName: string | null
  fileProspectContent: string | null
  fileProspectHeader: string | null
  fileProspectName: string | null
  coupon: number | null
  expectedPrice: number | null
  maturityDate: Date | null
}

export interface Price {
  date: string
  amount: number
}

export interface SecurityPrices {
  instrumentId: number
  name: string
  prices: Price[]
}

interface SecuritiesState {
  securities: Security[]
  security: Security | undefined
  filteredSecurities: Security[]
  positions: {}
  securityPrices: SecurityPrices | undefined
}

const INITIAL_STATE = {
  securities: [],
  security: undefined,
  filteredSecurities: [],
  positions: {},
  securityPrices: undefined,
}

export const securities = (state: SecuritiesState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_POSITIONS:
      return {
        ...state,
        positions: { ...state.positions, [action.payload.positionId]: action.payload.positions },
      }
    case GET_SECURITIES:
      return {
        ...state,
        securities: action.payload as Security[],
      }
    case GET_SECURITY:
      return {
        ...state,
        security: action.payload as Security,
      }
    case GET_FILTERED_SECURITIES:
      return {
        ...state,
        filteredSecurities: action.payload as Security[],
      }
    case GET_SECURITY_PRICES:
      debugger
      return {
        ...state,
        securityPrices: action.payload as SecurityPrices,
      }
    default:
      return state
  }
}
