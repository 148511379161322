export const hexToRGBA = (hex: string, alpha: number) => {
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const capitalizeFirstLetter = (string: string) => {
  if (typeof string != 'string') {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const hexArrayToRGBAArray = (hex: string[], alpha: number) => {
  let result: string[] = []
  hex.forEach(color => {
    result.push(hexToRGBA(color, alpha))
  })
  return result
}
