import {
  CC_PROFESIONAL,
  CC_UNPROFESIONAL,
  CE_BASIC,
  CE_EXPERIENCED,
  CE_INFORMED,
  CLA_NO,
  CLA_YES,
  CP_GROW,
  CP_PROTECTION,
} from '../constants/codeListsItems'

export const categorySecValidation = (clientCategory: string | null, securityCategory: string | null) => {
  if (clientCategory === null || securityCategory === null) return false

  if (clientCategory === CC_UNPROFESIONAL && securityCategory === CC_PROFESIONAL) return true
  else return false
}

export const skillAndExperienceSecValidation = (clientSkill: string | null, securitySkill: string | null) => {
  if (clientSkill === null || securitySkill === null) return false

  if (clientSkill === CE_EXPERIENCED) return false
  else if (clientSkill === CE_INFORMED) {
    if (securitySkill == CE_EXPERIENCED) return true
    else return false
  } else if (clientSkill === CE_BASIC) {
    if (securitySkill === CE_BASIC) return false
    else return true
  } else return false
}

export const preferenceSecValidation = (clientPrefence: string | null, securityPreference: string | null) => {
  if (clientPrefence === null || securityPreference === null) return false

  if (clientPrefence === CP_PROTECTION && securityPreference === CP_GROW) return true
  else return false
}

export const lossAbilitySecValidation = (clientLossAbility: string | null, securityLossAbility: string | null) => {
  if (clientLossAbility === null || securityLossAbility === null) return false

  if (clientLossAbility === CLA_NO && securityLossAbility === CLA_YES) return true
  else return false
}
