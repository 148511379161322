// client category items
export const CC_PROFESIONAL = 'CC_PROFESIONAL'
export const CC_UNPROFESIONAL = 'CC_UNPROFESIONAL'

// client experties items
export const CE_BASIC = 'CE_BASIC'
export const CE_EXPERIENCED = 'CE_EXPERIENCED'
export const CE_INFORMED = 'CE_INFORMED'

// client preference items
export const CP_GROW = 'CP_GROW'
export const CP_PROTECTION = 'CP_PROTECTION'

// client loss ability items
export const CLA_YES = 'CLA_YES'
export const CLA_NO = 'CLA_NO'
