import React from 'react'
import { Box, LinearProgress } from '@mui/material'

interface Props {}

export const LoadingIndicator: React.FC<Props> = () => {
  return (
    <Box sx={{ width: '99%' }}>
      <LinearProgress />
    </Box>
  )
}
