import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavBar } from './NavBar'
import User from './User'
import { isClientUser } from '../../services/keycloak.service'
import { useHistory, useParams } from 'react-router-dom'
import { Pages } from '../../router/router'
import { useCurrentPage } from '../../hooks/useCurrentPage'
import { OrdersByStateCount } from '../../redux/reducers/orders'
import { useSelector } from '../../redux/hooks/useSelector'
import ClientCard from './ClientCard'
import { ClientInformationDialog } from '../dialogs/ClientInformationDialog'
import LogoIF from '../../assets/IMPERIUM-FINANCE.png'
import LogoApex from '../../assets/APEX-CAPITAL.png'
import LogoGarantee from '../../assets/GARANTEE.jpg'
import { APEX, GARANTEE, IMPERIAL_FINANCE } from '../../constants/businessTerritory'

interface Props {
  pageName: string
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(4),
  },
  leftBar: {
    height: '100%',
    width: '300px',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    margin: '10',
  },
  right: {
    flexGrow: 1,
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '300px',
  },
  child: {
    marginLeft: '300px',
    marginTop: 30,
    paddingRight: theme.spacing(3),
  },
  pageName: {
    fontWeight: 500,
    fontSize: '1rem',
    margin: 0,
  },
  rightTop: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  ifLogo: {
    maxHeight: '60px',
  },
}))

export const NewLayout: React.FC<Props> = ({ children, pageName }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const curr = useCurrentPage()
  const [ordersByStateCount, setOrdersByStateCount] = useState([] as OrdersByStateCount[])
  const { clientId } = useParams<{ clientId: string }>()
  const orderSse = useSelector(state => state.orders.orderSse)
  const user = useSelector(state => state.auth.user)


  useEffect(() => {
    if (curr === Pages.Home && orderSse && orderSse.userStates && orderSse.userStates.length > 0) {
      setOrdersByStateCount(orderSse.userStates[0].states)
    }
  })

  const ribon = process.env.REACT_APP_ENV_RIBBON
  return (
    <div className={classes.wrapper}>
      <div className={classes.leftBar}>
        <NavBar />
      </div>
      <div className={classes.right}>
        <div className={classes.topBar}>
          <span>
            {!isClientUser() && clientId && <ClientCard />}
            <p className={classes.pageName}>{t(pageName)}</p>
          </span>
          <span>
            {user && user.businessTerritoryId===IMPERIAL_FINANCE &&
              <img src={LogoIF} alt="Imperium finance logo" className={classes.ifLogo} />
            }
            {user && user.businessTerritoryId===APEX &&
              <img src={LogoApex} alt="Apex capital logo" className={classes.ifLogo} />
            }
            {user && user.businessTerritoryId===GARANTEE &&
              <img src={LogoGarantee} alt="Garantee logo" className={classes.ifLogo} />
            }
            {ribon && (
              <p className={classes.pageName} style={{ color: '#ff0000' }}>
                {ribon}
              </p>
            )}
          </span>
          <User />
        </div>
        <div className={classes.child}>{children}</div>
      </div>
      <ClientInformationDialog />
    </div>
  )
}
